import { Box, Stack, Text } from "grommet"
import React, { useMemo } from "react"
import FMatIcon from "../../../imgs/icon_4mat.svg"
import FllIcon from "../../../imgs/icon_5ll.svg"

interface DiagIconProps {
  title: string
  subtitle?: string
  fullname: string
  part?: string
}

export const DiagIcon: React.FC<DiagIconProps> = ({ title, subtitle, fullname, part }) => {
  const icon = useMemo(() => {
    switch (title) {
      case "4MAT":
      case "LBI360":
        return FMatIcon
      default:
        return FllIcon
    }
  }, [])
  return (
    <Stack>
      <Box width="180px" height="180px" round="10px">
        <img src={icon} width="180px" height="180px" />
      </Box>
      <Box width="180px" height="180px" round="10px" pad="20px">
        <Text size="26px" color="white" weight="bold">
          {title}
        </Text>
        {subtitle && (
          <Text size="26px" color="white" weight="bold" style={{ height: "32px", whiteSpace: "pre-wrap" }}>
            {subtitle}
          </Text>
        )}
        <Text size="18px" color="white">
          -
        </Text>
        <Text size="12px" color="white">
          {fullname}
        </Text>
        {part && (
          <Text size="14px" color="white">
            ({part})
          </Text>
        )}
      </Box>
    </Stack>
  )
}
