import produce from "immer"
import _ from "lodash"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { handleGqlError } from "../../../components/utils"
import {
  PaperStatus,
  useDinoLoadPaperLazyQuery,
  useDinoPaperQuery,
  useDinoProcessResponseMutation,
} from "../../../generated-types"
import { ProcessingPage } from "./ProcessingPage"

const WAIT_MS = 200

type Response = { [value: string]: number | null }
type DinoContextType = {
  page: number
  waiting: boolean
  isLast: boolean
  meta: any[]
  nav: [boolean, boolean] // 네비게이션 가능 여부 - 이전, 다음
  response: Response

  putResponse: (response: Response) => void
  goNext: (waitBeforeNext?: boolean) => void
  goPrev: () => void
  spliceSheets: (contentId: number) => void
}

export interface DinoContextProviderProps {
  paperId: string
  lang: string
}

export const DinoContext = React.createContext<DinoContextType | undefined>(undefined)
DinoContext.displayName = "DinoContext"

export const DinoContextProvider: React.FC<DinoContextProviderProps> = ({ paperId, lang, children }) => {
  const history = useHistory()
  const { data: paperData } = useDinoPaperQuery({
    variables: { paperId },
    context: { "x-dino-lang": lang },
    fetchPolicy: "network-only",
  })
  const [fetchPaperSheet, { data }] = useDinoLoadPaperLazyQuery({
    variables: { paperId, lang },
    fetchPolicy: "network-only",
    onError: (e) => {
      handleGqlError(e)
      history.replace("/my")
    },
  })
  const [submitResponse] = useDinoProcessResponseMutation({ context: { "x-dino-lang": lang } })
  const [pageStat, setPageStat] = useState({ page: 0, nextPage: 0, waiting: false, processing: false })
  const [allResponse, setAllResponse] = useState<Response[]>([])
  const sheets = useMemo(() => {
    const list = []
    if (!data) return list

    data.dinoLoadPaper.content.forEach(({ intro, items }, cid) => {
      if (intro) {
        list.push([`/${cid}/intro`])
      }
      const itemCnt = items.length
      items.forEach((v, i) => {
        const itemNo = i + 1
        list.push([`/${cid}/${itemNo}`, itemNo, itemCnt])
      })
    })
    list.push(["FINISHED"])
    // console.log(list)
    return list
  }, [data])

  useEffect(() => {
    if (pageStat.page !== pageStat.nextPage) {
      const newPageStat = { ...pageStat, page: pageStat.nextPage, waiting: false }

      if (pageStat.waiting) {
        setTimeout(() => {
          setPageStat(newPageStat)
        }, WAIT_MS)
      } else {
        setPageStat(newPageStat)
      }
    }
  }, [pageStat])

  ///// Route /////
  useEffect(() => {
    if (!paperData) {
      return
    }

    // 진단이 완료된 진단지 => 결과 페이지로 이동
    if (paperData.dinoPaper.statusCd === PaperStatus.Done) {
      if (pageStat.processing) {
        setPageStat({ ...pageStat, processing: false })
      }

      history.replace(`/papers/${paperId}/${paperData.dinoPaper.item.id}/result`)
      return
    }

    if (sheets.length === 0) {
      fetchPaperSheet()
      return
    }

    if (sheets[pageStat.page][0] === "FINISHED") {
      // setShowProcess(true)
      if (!pageStat.processing) {
        setPageStat({ ...pageStat, processing: true })

        setTimeout(() => {
          // 모든 문항을 처리한 경우
          const answers = _(allResponse)
            .compact()
            .map((v) => {
              const arr = []
              Object.entries(v).forEach(([value, score]) => {
                arr[score] = value
              })
              return _(arr).compact().join(",").valueOf()
            })
            .value()
          // console.log({ paperId, answers })

          submitResponse({ variables: { paperId, answers } })
        }, 3000)
      }

      return
    }

    history.replace(`/papers/${paperId}/${paperData.dinoPaper.item.id}${sheets[pageStat.page][0]}`)
  }, [paperData, sheets, pageStat, setPageStat, allResponse, fetchPaperSheet, history, paperId, submitResponse])

  const ctx = useMemo<DinoContextType>(() => {
    if (!data || !paperData) return

    const { page } = pageStat
    const isLast = page >= sheets.length - 1

    const goNext = (waitBeforeNext) => {
      if (pageStat.page < sheets.length - 1) {
        setPageStat({ ...pageStat, nextPage: pageStat.page + 1, waiting: waitBeforeNext })
      }
    }
    const goPrev = () => {
      setPageStat({ ...pageStat, nextPage: pageStat.page - 1, waiting: false })
    }

    return {
      ...pageStat,
      isLast,
      meta: sheets[page],
      nav: [page !== 0, !!allResponse[page]],
      response: allResponse[page] || {},
      putResponse: (response) => {
        setAllResponse(
          produce(allResponse, (draft) => {
            draft[page] = response
          })
        )
        // 모든 답지를 채운 경우
        if (Object.values(response).length > 0 && Object.values(response).filter((v) => !v).length === 0) {
          goNext(true)
        }
      },
      goNext,
      goPrev,
      spliceSheets(contentId) {
        const contentLength = sheets.length / data.dinoLoadPaper.content.length
        sheets.splice(contentId * contentLength, contentLength)
      },
    }
  }, [data, paperData, pageStat, allResponse, sheets])

  return <DinoContext.Provider value={ctx}>{pageStat.processing ? <ProcessingPage /> : children}</DinoContext.Provider>
}

export const useDinoContext = () => useContext(DinoContext)
