import { Box, Button, Text } from "grommet"
import { Analytics, Edit, List as ListIcon, Note, StatusGood } from "grommet-icons"
import React from "react"
import { useTranslation } from "react-i18next"

export type ItemType = "diag" | "report"
export type ItemStatus = "READY" | "PROCEEDING" | "DONE"
export type ProgramItemProps = {
  id: string | number
  type: ItemType
  name: string
  status: ItemStatus
  disabled?: boolean
  onAction?: (id: string | number, cmd: string) => void
}

export const ProgramItem: React.FC<ProgramItemProps> = (props) => {
  const { type, name, disabled = false, onAction, status, id } = props
  const { t } = useTranslation()
  const BTN_PRESET = {
    "diag.READY": {
      label: t("program-item.diagnosis"),
      icon: <Edit />,
      cmd: "START",
    },
    "diag.PROCEEDING": {
      label: t("program-item.diagnosis") + " ...",
      icon: <Edit />,
      cmd: "START",
    },
    "diag.DONE": {
      label: t("program-item.view-result"),
      icon: <Note />,
      cmd: "VIEW",
    },
    "report.READY": {
      label: t("program-item.view-result"),
      icon: <Note />,
      cmd: "VIEW",
    },
    "report.RESULT": {
      label: t("program-item.view-result"),
      icon: <Note />,
      cmd: "VIEW",
    },
  }
  const preset = `${type}.${status}`
  const btnProps = BTN_PRESET[preset]

  return (
    <Box fill="horizontal" pad="0.8em" gap="small" border={{ side: "left", size: "0.5em", color: "light-5" }}>
      <Box fill="horizontal" direction="row" gap="small" overflow="visible">
        {type === "diag" ? <ListIcon size="medium" /> : <Analytics size="medium" />}
        <Text weight="bold">{name}</Text>
        {status === "DONE" && <StatusGood color="status-ok" />}
      </Box>
      <Box fill="horizontal" height="2.5em">
        {btnProps && (
          <Button
            {...btnProps}
            primary={true}
            fill
            disabled={disabled}
            gap="small"
            onClick={() => onAction(id, btnProps.cmd)}
          />
        )}
      </Box>
    </Box>
  )
}
