import { Box, Button, Header, Text } from "grommet"
import { Spinning } from "grommet-controls"
import { Home, Logout } from "grommet-icons"
import React from "react"
import { useHistory } from "react-router"
import DinoLogo from "../../imgs/logo_dino.svg"
import { useMemo } from "react"

const isLocalhost = () =>
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)

export type AppBarProps = {
  loading: boolean
  userName?: string
  isSignedIn: boolean
  onClickLanguage?: () => void
  onClickUser?: () => void
  onClickLogin?: () => void
  onClickLogout?: () => void
}

export const AppBar: React.FC<AppBarProps> = ({
  loading,
  userName,
  isSignedIn,
  onClickLanguage,
  onClickLogin,
  onClickLogout,
  onClickUser,
}) => {
  const history = useHistory()
  const Logo = useMemo(() => {
    if (/4mat.co.kr$/.test(window.location.hostname)) {
      return () => (
        <Text size="1.2em" weight="bold">
          4MAT
        </Text>
      )
    } else {
      return () => <img src={DinoLogo} height={25} />
    }
  }, [])
  return (
    <Header
      background="brand"
      fill="horizontal"
      pad={{ top: "12px", bottom: "12px", left: "6px", right: "6px" }}
      // position="sticky"
      elevation="small"
      margin={{ bottom: "0.8em" }}
    >
      <Box fill="horizontal" align="center" direction="row" justify="between">
        <Box pad={{ left: "0.8em" }} flex={false} direction="row" gap="small">
          <Logo />
          {loading && <Spinning kind="pulse" />}
        </Box>
        <Box width="medium" align="end">
          <Box direction="row">
            <Button icon={<Home />} onClick={() => history.push("/")} />
            {/*<Button icon={<User />} onClick={() => history.push("/my")} />*/}
            {isSignedIn ? (
              <>
                {/* <Button icon={<User />} onClick={e => onClickUser()} /> */}
                <Button icon={<Logout />} onClick={(e) => onClickLogout()} />
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Header>
  )
}
