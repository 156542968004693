import React, { useState } from "react"
import { GrommetRoot } from "./GrommetRoot"
import { Box, BoxProps } from "grommet"
import { AppBar } from "./AppBar"
import { ProfilePage } from "../../pages/Profile"
import { useLoginContext } from "../context/LoginContext"
import Copyright from "./Copyright"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"
import { LanguageSelector } from "./LanguageSelector"

type CommonPage = "CHANGE_LANGUAGE" | "PROFILE" | ""

const StatusBarPreset = {
  OK: {
    animation: [
      { type: "slideDown", duration: 500, size: "medium" },
      { type: "fadeIn", duration: 500, size: "large" },
    ],
    background: "#f2928d",
    border: { side: "horizontal", color: "#e05951", size: "1px" },
  } as BoxProps,
  WARN: {
    animation: [
      { type: "slideDown", duration: 500, size: "medium" },
      { type: "fadeIn", duration: 500, size: "large" },
    ],
    background: "#f2928d",
    border: { side: "horizontal", color: "#e05951", size: "1px" },
  } as BoxProps,
  ERROR: {
    animation: [
      { type: "slideDown", duration: 500, size: "medium" },
      { type: "fadeIn", duration: 500, size: "large" },
    ],
    background: "#f2928d",
    border: { side: "horizontal", color: "#e05951", size: "1px" },
  } as BoxProps,
}

export interface MainLayoutProps {
  loading?: boolean
}

export const MainLayout: React.FC<MainLayoutProps> = ({ loading = false, children }) => {
  const { isLogined, logout } = useLoginContext()
  // const { message, loading: ctxLoading } = useUIContext()
  const [commonPage, setCommonPage] = useState<CommonPage>("")
  const history = useHistory()

  const closeCommonPage = () => setCommonPage("")
  const { t } = useTranslation()
  let render = children

  switch (commonPage) {
    case "PROFILE":
      render = <ProfilePage onDone={closeCommonPage} />
      break
  }

  return (
    <GrommetRoot>
      <Box fill align="center" direction="column" justify="start" style={{ minHeight: "86vh" }}>
        <AppBar
          loading={loading}
          // loading={ctxLoading || loading}
          isSignedIn={isLogined()}
          onClickUser={() => setCommonPage("PROFILE")}
          onClickLanguage={() => setCommonPage("CHANGE_LANGUAGE")}
          onClickLogout={() => {
            logout()
            history.push("/")
          }}
        />
        <Box responsive={false} flex={false} width="medium" align="center" background="white" pad="0.9em" gap="small">
          {render}
        </Box>

        {/* {message && (
          <Layer
            animation={false}
            responsive={false}
            modal={false}
            position="top"
            full="horizontal"
            plain
            margin={{ top: "2.8em" }}
          >
            <Box
              pad={{ top: "0.3em", bottom: "0.3em", left: "1em", right: "1em" }}
              align="center"
              {...StatusBarPreset[message.style]}
            >
              {message.content.map(({ code, message }, i) => (
                <Text key={i} size="0.9em" color="white">
                  {code ? t(`messages.${code}`) : message}
                </Text>
              ))}
            </Box>
          </Layer>
        )} */}
        <Box style={{ marginTop: "auto" }} pad="20px" align="center">
          <LanguageSelector />
          <Copyright />
        </Box>
      </Box>
    </GrommetRoot>
  )
}
