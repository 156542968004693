import { Box, Button, CheckBox, Form, FormField, Text } from "grommet"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MainLayout } from "../../components/layout/MainLayout"
import { useHistory, useParams } from "react-router-dom"
import { useEntryCheckQuery, useJoinByCodeMutation } from "../../generated-types"
import { handleGqlError } from "../../components/utils"
import { useLoginContext } from "../../components/context/LoginContext"
import { PageHeader } from "../dino/components/PageHeader"
import { EmailField } from "./EmailField"

// TODO TextInput 으로 변경 필요

const InputProfile = () => {
  const { code } = useParams<{ code: string }>()
  const history = useHistory()
  const { login, isLogined } = useLoginContext()
  const { t } = useTranslation()
  const { data, loading } = useEntryCheckQuery({
    variables: { code },
    onError: (e) => {
      handleGqlError(e)
      history.push("/login")
    },
  })
  const [agreeEmail, setAgreeEmail] = useState(() => true)

  const [joinMutation] = useJoinByCodeMutation({ fetchPolicy: "no-cache" })

  useEffect(() => {
    if (isLogined()) {
      history.push("/my")
    }
  }, [])

  const goNext = useCallback(
    (input) => {
      const { isAgreeEmail, ...profile } = input
      joinMutation({ variables: { code, profile, isAgreeEmail } })
        .then((r) => {
          const { token, username, exp } = r.data.joinByCode
          login(username, token, exp)
          history.replace("/my")
        })
        .catch(handleGqlError)
    },
    [code]
  )

  return (
    <MainLayout loading={loading}>
      <Box width="medium" align="center" pad="medium">
        <PageHeader
          texts={[
            "STEP 2.",
            data?.entryCheck?.session?.ecodeFields ? t("login.header.enter-profile") : t("login.header.confirm-info"),
            `${t("label.sessName")}: ${data?.entryCheck?.session?.title}`,
          ]}
        />
        {data && (
          <>
            <Box justify="center" width="medium" pad="small">
              <Form onSubmit={(e) => goNext(e.value)}>
                <Box margin={{ top: "medium", bottom: "large" }}>
                  {data?.entryCheck?.session?.ecodeFields.map((def, i) => {
                    switch (def.label) {
                      case "accessCode":
                        return (
                          <FormField
                            key={i}
                            name={def.field}
                            style={{ display: "none", borderColor: "#000" }}
                            value={code}
                          />
                        )
                      case "email":
                        return (
                          <Box key={i}>
                            <EmailField {...def} />
                            <CheckBox
                              name="isAgreeEmail"
                              checked={agreeEmail}
                              onChange={(e) => setAgreeEmail(e.target.checked)}
                              label={
                                <Text color="#999999" size="14px" wordBreak="keep-all">
                                  {t("label.agree-email")}
                                </Text>
                              }
                            />
                          </Box>
                        )
                      default:
                        return (
                          <FormField
                            key={i}
                            placeholder={t(`label.${def.label}`)}
                            name={def.field}
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        )
                    }
                  })}
                </Box>
                <Box direction="row" justify="stretch" gap="small">
                  <Button
                    type="button"
                    label={t("button.back")}
                    fill="horizontal"
                    onClick={() => history.push("/login")}
                    color="#d6544d"
                  />
                  <Button type="submit" label={t("button.continue")} primary fill="horizontal" color="#d6544d" />
                </Box>
              </Form>
            </Box>
          </>
        )}
      </Box>
    </MainLayout>
  )
}

export default InputProfile
