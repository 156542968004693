import React from "react"
import { Stack, Box, Text } from "grommet"
import { Spinning } from "grommet-controls"

export type QuestionLabelProps = {
  question: string
  height?: string
  waiting: boolean
}
const HEIGHT = "90px"
export const QuestionLabel: React.FC<QuestionLabelProps> = ({ question, height = HEIGHT, waiting }) => {
  return (
    <Stack fill>
      <Box
        fill="horizontal"
        justify="center"
        align="center"
        height={height}
        background="linear-gradient(102.77deg, #865ED6 -9.18%, #18BAB9 209.09%)"
        round="medium"
        elevation="medium"
        pad="0.8em"
      >
        <Text color="white" style={{ fontWeight: "bold", wordBreak: "keep-all" }} textAlign="center" size="16px">
          {question && question.replace("%?", "_____")}
        </Text>
      </Box>
      {waiting && (
        <Box
          fill="horizontal"
          justify="center"
          align="center"
          height={height}
          round="medium"
          style={{ backgroundColor: "rgba(255,255,255,.8)" }}
        >
          <Spinning />
        </Box>
      )}
    </Stack>
  )
}
