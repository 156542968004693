import React from "react"
import { useApolloClient } from "@apollo/client"
import { ItemPartsFragment, ItemPartsFragmentDoc } from "../../../generated-types"
import { useParams } from "react-router"
import { useDinoContext } from "../components/DinoContext"
import { Box } from "grommet"
import { BigRoundAnswerButton } from "../components/BigRoundAnswerButton"
import { NavigationControl } from "../components/NavigationControl"
import { useTranslation } from "react-i18next"

export const OnePick = () => {
  const { pgmId, contentId, itemId } = useParams<any>()
  const { t } = useTranslation()
  const client = useApolloClient()
  const dino = useDinoContext()
  const questionData = client.readFragment<ItemPartsFragment>({
    fragment: ItemPartsFragmentDoc,
    id: `DinoContentItem:${pgmId}:${contentId}:${itemId}`,
  })
  return (
    <>
      <Box fill="horizontal" gap="small">
        {questionData?.values?.map((v, i) => (
          <BigRoundAnswerButton
            key={i}
            value={v.key}
            text={t(v.text)}
            onClick={() => {
              dino.putResponse({ [v.key]: 1 })
            }}
          />
        ))}
      </Box>
      <NavigationControl />
    </>
  )
}
