import React from "react"
import "./style.css"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import Router from "./pages/_Router"
import { ApolloProvider } from "@apollo/client"
import "./i18n"
import { createClient } from "./graphql"
import { LanguageContextProvider } from "./components/context/LanguageContext"
import { LoginContextProvider } from "./components/context/LoginContext"
import { UIContextProvider } from "./components/context/UIContext"
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts"
import { LoadingPage } from "./pages/LoadingPage"

// observable pattern 확인해본다
const handler = () => {}

const client = createClient()

ReactDOM.render(
  <React.Suspense fallback={<LoadingPage />}>
    <LanguageContextProvider>
      <ApolloProvider client={client}>
        <LoginContextProvider>
          <UIContextProvider handler={handler}>
            <Router />
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />
          </UIContextProvider>
        </LoginContextProvider>
      </ApolloProvider>
    </LanguageContextProvider>
  </React.Suspense>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
