import { Box, Text } from "grommet"
import React, { useEffect } from "react"
import { ItemStatus, ItemType, ProgramItem } from "../components/ProgramItem"
import { MainLayout } from "../components/layout/MainLayout"
import { useMyPapersQuery } from "../generated-types"
import { useHistory } from "react-router"
import { useLoginContext } from "../components/context/LoginContext"
import { ToastsStore } from "react-toasts"

export type ItemProps = {
  id: string
  type: ItemType
  name: string
  status: ItemStatus
}

export type MyPageProps = {
  items: ItemProps[]
  onAction: (id: string, cmd: string) => void
}

export const MyPage: React.FC<MyPageProps> = ({ items, onAction }) => {
  return (
    <MainLayout>
      <Box fill="horizontal" margin={{ top: "medium", bottom: "large" }} pad="small" gap="small">
        {items.length > 0 ? (
          items.map((v, i) => (
            <ProgramItem key={i} id={v.id} name={v.name} type={v.type} status={v.status} onAction={onAction} />
          ))
        ) : (
          <Text textAlign="center">No Data</Text>
        )}
      </Box>
    </MainLayout>
  )
}

export const MyPageController: React.FC = (props) => {
  const { loading, data, error } = useMyPapersQuery()
  const { isLogined } = useLoginContext()
  const history = useHistory()
  // if (loading || error) return null
  useEffect(() => {
    if (!isLogined()) {
      ToastsStore.error("인증 정보가 없습니다")
      history.replace("/")
    }
  }, [])

  const { allPaper } = data || {}
  const onAction = (id, cmd) => {
    switch (cmd) {
      case "START":
        history.push(`/paper/${id}/diag`)
        return
      case "VIEW":
        history.push(`/paper/${id}/result`)
        return
    }
  }
  const items = allPaper
    ? allPaper.map(
        (v) =>
          ({
            id: v.id,
            type: "diag",
            code: v.item,
            name: v.item.shortName,
            status: v.status,
          } as ItemProps)
      )
    : []
  return <MyPage items={items} onAction={onAction} />
}
