import { Box, Button, Heading, Text } from "grommet"
import React, { useEffect, useMemo, useRef } from "react"
import Copyright from "../../components/layout/Copyright"
import DiagLayout from "../../components/DiagLayout"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useMyPapersQuery } from "../../generated-types"
import _ from "lodash"
import Konva from "konva"
import { FMatQuadrantRenderer, FMatQuadrantGraphNode, FMatQuadrantTriangleNode } from "@dino/shared"

type ResultPageProps = {
  title: string
  results: string[]
  hideChart?: boolean
  onClose?: () => void
}

export const FMatResultPage: React.FC<ResultPageProps> = ({ title, results, onClose, hideChart = false }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data } = useMyPapersQuery() // HMI가 있는 경우를 처리하기 위함
  const el = useRef<HTMLDivElement>()
  const refStage = useRef<Konva.Stage>()
  const map = useMemo(
    () =>
      results.reduce((a, e) => {
        const [k, v] = e.split("=")
        a[k] = v
        return a
      }, {}) as any,
    [results]
  )
  const hmi = useMemo<number>(() => {
    if (!data) return undefined

    return _(data.allPaper)
      .filter((v) => v.status === "DONE" && v.item.id === "4MAT_HMI")
      .map(({ results }) => parseInt(results[0].split("=")[1]))
      .first()
  }, [data])

  useEffect(() => {
    if (el.current) {
      console.log(el.current.clientWidth)
    }
  }, [el])

  if (!onClose) {
    onClose = () => history.push("/my")
  }

  return (
    <div>
      <DiagLayout>
        <Heading level={2} style={{ marginBottom: "0.4em" }}>
          RESULT
        </Heading>
        <Text style={{ margin: 0 }}>{title}</Text>
        <Box ref={el} align="center">
          <FMatQuadrantRenderer ref={refStage} width={300}>
            <FMatQuadrantGraphNode value={[map.C, map.R, map.T, map.S]} />
            {hmi && <FMatQuadrantTriangleNode value={[map.C, map.R, map.T, map.S, map.D - map.W, hmi]} />}
          </FMatQuadrantRenderer>
        </Box>

        <Box gap="xsmall">
          {map.D && map.W && (
            <Box background="light-2" pad="small" round="small">
              <Text>
                Watching/Doing Score: <Text weight="bold">{map.D - map.W}</Text>
              </Text>
            </Box>
          )}
          {hmi && (
            <Box background="light-2" pad="small" round="small">
              <Text>
                HMI: <Text weight="bold">{hmi}</Text>
              </Text>
            </Box>
          )}
        </Box>
        <Box fill="horizontal" margin={{ top: "medium" }} gap="small">
          <Button label={t("button.confirm")} primary onClick={() => onClose()} />
          <Button
            label={t("button.download")}
            onClick={() => {
              const dataUrl = refStage.current.getStage().toDataURL({ pixelRatio: 2 })
              let link = document.createElement("a")
              link.download = "Result.png"
              link.href = dataUrl
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }}
          />
        </Box>

        <Copyright />
      </DiagLayout>
    </div>
  )
}
