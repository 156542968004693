import React, { useMemo, useState } from "react"
import { FMatTsiRenderer, FMatTsiGraphNode } from "@dino/shared"
import { useParams } from "react-router"
import { useDinoPaperQuery } from "../../../generated-types"
import { ResultLayout } from "./ResultLayout"
import { FMatDesc } from "./FMatDesc"
import { isSupportItem } from "../report/ReportUtil"

export default () => {
  const { paperId } = useParams<any>()
  const { data } = useDinoPaperQuery({ variables: { paperId } })
  const [showSendReport, setShowSendReport] = useState(false)
  const value = useMemo(() => {
    if (!data) {
      return []
    }
    setShowSendReport(isSupportItem(data.dinoPaper.item.id))

    return data.dinoPaper.results || []
  }, [data])

  return (
    <ResultLayout showSendReport extraContent={<FMatDesc pgmId={data?.dinoPaper?.item?.id} value={value} />}>
      {({ width, refStage }) => (
        <FMatTsiRenderer width={width} ref={refStage}>
          {value.length >= 4 && <FMatTsiGraphNode value={value} />}
        </FMatTsiRenderer>
      )}
    </ResultLayout>
  )
}
