import { Box } from "grommet"
import React from "react"
import Copyright from "../../components/layout/Copyright"
import DiagLayout from "../../components/DiagLayout"

export type EmptyPageProps = {}

export const EmptyPage: React.FC<EmptyPageProps> = props => {
  return (
    <DiagLayout>
      <Box pad="large">{props.children}</Box>
      <Copyright />
    </DiagLayout>
  )
}
