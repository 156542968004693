import { Box, Button, Form, TextInput } from "grommet"
import { Spinning } from "grommet-controls"
import _ from "lodash"
import React, { useCallback, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { MainLayout } from "../../components/layout/MainLayout"
import { useEntryCheckLazyQuery } from "../../generated-types"
import { handleGqlError } from "../../components/utils"
import { ToastsStore } from "react-toasts"
import { PageHeader } from "../dino/components/PageHeader"
import { LoginContext } from "../../components/context/LoginContext"

export default () => {
  const { t } = useTranslation()
  const { isLogined } = useContext(LoginContext)

  const history = useHistory()
  const { ecode: defaultCode } = useParams<any>()
  const [fetchEntryCheck, { loading, data }] = useEntryCheckLazyQuery({
    fetchPolicy: "network-only",
    onError: handleGqlError,
  })
  const [canContinue, setCanContinue] = React.useState(false)
  const [code, setCode] = React.useState(defaultCode || "")

  useEffect(() => {
    if (isLogined()) {
      history.push("/my")
    }
  }, [])

  const goNext = useCallback(() => {
    if (!(code.length === 8 || code.length === 16)) {
      ToastsStore.warning("자릿수가 맞지 않습니다")
      return
    }
    fetchEntryCheck({ variables: { code } })
  }, [code])

  React.useEffect(() => {
    if (!data) return
    history.push(`/login/${code}`)
  }, [data])

  React.useEffect(() => {
    if (!defaultCode) return
    goNext()
  }, [defaultCode])

  React.useEffect(() => {
    if (!code) return
    setCanContinue(code.length > 0)
  }, [code])

  const _onChange = (e) => {
    let value = _.toUpper(e.target.value)
    value = value.replace(new RegExp("[^A-Z0-9]"), "")
    setCode(value)
  }

  return (
    <MainLayout>
      <Box width="medium" align="center" pad="medium">
        <PageHeader texts={["STEP 1.", t("login.enter-code.header"), t("login.enter-code.msg")]} />

        <Box justify="center" width="medium" flex={false}>
          <Form onSubmit={() => goNext()}>
            <Box margin={{ top: "medium", bottom: "medium" }} gap="20px">
              <TextInput
                value={code}
                onChange={_onChange}
                style={{ fontWeight: "bold", fontSize: "22px", borderColor: "#000" }}
                placeholder="CODE"
                maxLength={16}
              />
              <Button
                label={t("button.continue")}
                icon={loading ? <Spinning /> : undefined}
                primary
                type="submit"
                disabled={!canContinue}
                color="#d6544d"
                alignSelf="center"
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </MainLayout>
  )
}
