import { Box, Heading } from "grommet"
import React from "react"
import Copyright from "../../components/layout/Copyright"
import { DinoLayout } from "./components/Layout"

// TODO check 서브 페이지에서도 컨텍스트 사용 가능한가? OK
// TODO 상태를 파악하여 완료되었으면 결과페이지로 바로 리다이렉션
export default () => {
  return (
    <DinoLayout>
      <Box width="medium" align="center">
        <Heading level={2} margin={{ bottom: "small" }}>
          Loading...
        </Heading>
      </Box>

      <Copyright />
    </DinoLayout>
  )
}
