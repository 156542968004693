import { FiveLLNode, FiveLLRenderer } from "@dino/shared"
import React from "react"
import { useParams } from "react-router"
import { useDinoPaperQuery } from "../../../generated-types"
import { ResultLayout } from "./ResultLayout"

export default () => {
  const { paperId } = useParams<any>()
  const { data } = useDinoPaperQuery({ variables: { paperId } })

  return (
    <ResultLayout>
      {({ width, refStage }) => (
        <FiveLLRenderer width={width} ref={refStage}>
          {data?.dinoPaper?.results && <FiveLLNode value={data.dinoPaper.results} />}
        </FiveLLRenderer>
      )}
    </ResultLayout>
  )
}
