import { ApolloError } from "@apollo/client"
import { ToastsStore } from "react-toasts"
import _ from "lodash"
import i18n from "../i18n"

export const resolveApolloError = (e: ApolloError) => {
  const { graphQLErrors, networkError } = e

  const messages = []
  if (networkError) messages.push(networkError.message)
  if (graphQLErrors)
    graphQLErrors.forEach((err) => messages.push(i18n.t("messages." + _.get(err, "extensions.code")) || err.message))
  return messages
}

export const handleGqlError = (e: ApolloError) => {
  resolveApolloError(e).forEach((v) => ToastsStore.error(v, 5000))
}
