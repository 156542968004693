import { FMatQuadrantGraphNode, FMatQuadrantRenderer, FMatQuadrantTriangleNode } from "@dino/shared"
import React, { useMemo } from "react"
import { useParams } from "react-router"
import { useDinoPaperQuery, useEcodeByPaperQuery } from "../../../generated-types"
import { ResultLayout } from "./ResultLayout"
import { useTranslation } from "react-i18next"
import { FMatDesc } from "./FMatDesc"
import Markdown from "markdown-to-jsx"

const title = ({ props, children }) => (
  <div style={{ fontSize: "22px", fontWeight: "bold", textAlign: "center" }} {...props}>
    {children}
  </div>
)

export default () => {
  const { paperId } = useParams<any>()
  const { t } = useTranslation()
  const { data } = useDinoPaperQuery({ variables: { paperId } })
  const { data: paper } = useEcodeByPaperQuery({ variables: { paperId } })

  const value = useMemo(() => {
    const newValue = []
    if (data) {
      const { results } = data.dinoPaper
      newValue.push(results[0])
      newValue.push(results[1])
      newValue.push(results[2])
      newValue.push(results[3])
    }
    return newValue
  }, [data, paper])

  return (
    <ResultLayout
      extraContent={paper?.ecodeByPaper.isLeader ? <FMatDesc pgmId={data?.dinoPaper?.item?.id} value={value} /> : null}
    >
      {({ width, refStage }) => (
        <>
          {paper?.ecodeByPaper.isLeader ? (
            <FMatQuadrantRenderer width={width} ref={refStage}>
              {value.length >= 4 && <FMatQuadrantGraphNode value={value} />}
              {value.length === 6 && <FMatQuadrantTriangleNode value={value} />}
            </FMatQuadrantRenderer>
          ) : (
            <Markdown options={{ overrides: { title } }} style={{ textAlign: "center" }}>
              {t("FMAT_LBI360.result-message")}
            </Markdown>
          )}
        </>
      )}
    </ResultLayout>
  )
}
