import { Box } from "grommet"
import React from "react"
import { useParams } from "react-router"
import Copyright from "../../../components/layout/Copyright"
import { GrommetRoot } from "../../../components/layout/GrommetRoot"
import { useDinoPaperQuery } from "../../../generated-types"

export const DinoLayout: React.FC = (props) => {
  const { paperId } = useParams<{ paperId: string }>()
  const { data } = useDinoPaperQuery({ variables: { paperId } })
  return (
    <GrommetRoot>
      <style>{"body { background-color: #00739D; margin-bottom: 20px}"}</style>
      <Box
        fill
        align="center"
        justify="start"
        overflow="scroll"
        background={{ color: "neutral-3" }}
        pad={{ top: "20px", left: "20px", right: "20px" }}
      >
        <Box
          fill
          width="large"
          style={{ minHeight: "calc(100vh - 150px)" }}
          align="center"
          background={{ color: "white" }}
          round="10px"
          pad="20px"
          gap="small"
        >
          {props.children}
        </Box>
        <Box>
          <Copyright owner={data?.dinoPaper?.item?.owner} />
        </Box>
      </Box>
    </GrommetRoot>
  )
}
