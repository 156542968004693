import { useMachine } from "@xstate/react"
import { Box, Button, Grommet, Meter, Stack, Text, ThemeType } from "grommet"
import { LinkNext, Refresh } from "grommet-icons"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Page1 from "../../../imgs/4mat_seqp_guide_1.png"
import Page2 from "../../../imgs/4mat_seqp_guide_2.png"
import Page3 from "../../../imgs/4mat_seqp_guide_3.png"
import { GuideFrame } from "./GuideFrame"
import { createGuideMachine } from "./GuideMachine"
import i18n from "../../../i18n"
import { useParams } from "react-router"
import { useEcodeByPaperQuery } from "../../../generated-types"

const grommetTheme: ThemeType = {
  global: { colors: { brand: "#00739D" } },
}

export interface SequencePickerGuideProps {
  onFinish?: () => void
}

// FIXME 화면 전환시 이미지 부분이 튀는 문제
const FPS = 10,
  END_SEC = 10,
  END_FRAME = END_SEC * FPS

export const SequencePickerGuide: React.FC<SequencePickerGuideProps> = ({ onFinish = () => {} }) => {
  const { paperId, pgmId } = useParams<any>()
  const [state, send] = useMachine(() =>
    createGuideMachine({ fps: FPS, trigger: { [4 * FPS]: 1, [8 * FPS]: 2 }, end: END_FRAME })
  )
  const { data } = useEcodeByPaperQuery({ variables: { paperId: Number(paperId) } })
  let keyPrefix = i18n.exists(pgmId) ? `${pgmId}.` : ""
  const { t } = useTranslation()
  const tt = (key) => {
    if (data && data.ecodeByPaper.isLeader) {
      keyPrefix = ""
    }
    return t(`${keyPrefix}${key}`)
  }
  const frames = useMemo(
    () => [
      {
        text: tt("intro.seqpick_1"),
        image: Page1,
      },
      { text: tt("intro.seqpick_2"), image: Page2 },
      {
        text: tt("intro.seqpick_3"),
        image: Page3,
      },
    ],
    [data]
  )

  return (
    <Grommet full theme={grommetTheme}>
      <Box fill background="brand">
        <Box pad="15px">
          <Box justify="center" align="end" fill="horizontal" height="50px">
            {state.matches("running") ? (
              <Stack anchor="center">
                <Meter
                  type="circle"
                  size="40px"
                  thickness="4px"
                  max={100}
                  values={[{ value: Math.ceil(100 * (1 - state.context.progress)), color: "#C51F31" }]}
                />
                <Box direction="row" align="center" pad={{ bottom: "4px" }}>
                  <Text weight="bold">{Math.ceil(END_SEC * (1 - state.context.progress))}</Text>
                </Box>
              </Stack>
            ) : (
              <Box direction="row" gap="medium">
                <Button plain focusIndicator={false} icon={<Refresh />} onClick={() => send("RESET")} />
                <Button plain focusIndicator={false} icon={<LinkNext />} onClick={() => onFinish()} />
              </Box>
            )}
          </Box>
        </Box>
        {frames.map((v, i) => i === state.context.page && <GuideFrame key={i} texts={v.text} image={v.image} />)}
        {/* {frames.map((v, i) => (
          <GuideFrame key={i} texts={v.text} image={v.image} hide={i !== state.context.page} />
        ))} */}
        {/* {state.context.page >= 0 && (
          <GuideFrame texts={frames[state.context.page].text} image={frames[state.context.page].image} />
        )} */}
      </Box>
    </Grommet>
  )
}
