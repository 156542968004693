import React, { useState } from "react"
import { useHistory, useParams } from "react-router"
import { useGetResultQuery, useSendReportToEmailMutation } from "../../../generated-types"
import { Box, Button, CheckBox, Form, Heading, Menu, Text } from "grommet"
import { EmailField } from "../../login/EmailField"
import { useTranslation } from "react-i18next"
import DiagLayout from "../../../components/DiagLayout"
import { notification } from "antd"

export const ResultReportPage: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { paperId, pgmId } = useParams<any>()
  const [lang, setLang] = useState("ko")
  const [agreeEmail, setAgreeEmail] = useState(false)
  const [processing, setProcessing] = useState(false)
  const { loading, error } = useGetResultQuery({ variables: { paperId } })
  const [sendReportToEmail] = useSendReportToEmailMutation({})
  if (loading) return null
  if (error) {
    return <div>{error.message}</div>
  }

  const languages = [
    { value: "ko", label: "한국어", onClick: () => setLang("ko") },
    { value: "en", label: "English", onClick: () => setLang("en") },
  ]

  if (pgmId === "FMAT_LTM") {
    languages.push({ value: "swedish", label: "Swedish", onClick: () => setLang("swedish") })
  }

  const sendReport = (form) => {
    const { email } = form
    setProcessing(true)
    sendReportToEmail({ variables: { paperId, lang, email } })
      .then((r) => {
        if (r.data.sendReportToEmail) {
          notification.info({ message: "Send report completed!" })
        } else {
          notification.error({ message: "Failed to send report!" })
        }
      })
      .catch(() => {
        notification.error({ message: "Failed to send report!" })
      })
      .finally(() => setProcessing(false))
  }

  return (
    <DiagLayout>
      <Heading level={2} style={{ marginBottom: "0.4em" }}>
        {t("label.result-report-title")}
      </Heading>
      <Text size="18px" color="#777777" style={{ margin: 0 }}>
        {t("label.result-report-desc")}
      </Text>
      <Box justify="center" width="medium" pad="small">
        <Form onSubmit={(e) => sendReport(e.value)}>
          <Box margin={{ vertical: "medium" }}>
            <EmailField field="email" label="please-input-email" />
            <label style={{ margin: "auto" }}>
              {t("common.lang.word")}:
              <Menu
                items={languages.filter((l) => l.value !== lang)}
                margin={{ left: "small" }}
                label={languages.find((l) => l.value === lang).label}
              />
            </label>
          </Box>
          <Box direction="row" justify="stretch" gap="small">
            <Button
              type="button"
              label={t("button.cancel")}
              fill="horizontal"
              color="#d6544d"
              onClick={() => history.goBack()}
            />
            <Button
              type="submit"
              label={t("button.send")}
              disabled={processing}
              primary
              fill="horizontal"
              color="#d6544d"
            />
          </Box>
          <Box margin={{ top: "large", bottom: "large" }}>
            <CheckBox
              name="isAgreeEmail"
              checked={agreeEmail}
              required
              onChange={(e) => setAgreeEmail(e.target.checked)}
              label={
                <Text color="#999999" size="14px" wordBreak="keep-all">
                  {t("label.agree-email2")}
                </Text>
              }
            />
          </Box>
        </Form>
      </Box>
    </DiagLayout>
  )
}
