import { Box, Stack, Text } from "grommet"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { ToastsStore } from "react-toasts"
import { useLanguageContext } from "../../components/context/LanguageContext"
import { useLoginContext } from "../../components/context/LoginContext"
import { MainLayout } from "../../components/layout/MainLayout"
import { PaperStatus, useDinoMyPapersQuery } from "../../generated-types"
import { PageHeader } from "./components/PageHeader"
import FMatIcon from "../../imgs/icon_4mat.svg"
import FllIcon from "../../imgs/icon_5ll.svg"

interface DinoButtonProps {
  title: string
  subtitle: string
  id: string
  color: string[]
  status: PaperStatus
}

const ICON_SIZE = "150px"

export const DinoButton: React.FC<DinoButtonProps> = ({ title, subtitle, id, color, status }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const icon = useMemo(() => {
    switch (title) {
      case "4MAT":
      case "LBI360":
        return FMatIcon
      default:
        return FllIcon
    }
  }, [])

  return (
    <Box
      width={ICON_SIZE}
      height={ICON_SIZE}
      margin={{ bottom: "12px" }}
      onClick={() => {
        history.push(`/papers/${id}`)
      }}
      alignSelf="stretch"
    >
      <Stack fill>
        <img src={icon} />
        <Box round="8px" pad="20px">
          <Text color="white" weight="bold" size="1.3em">
            {title}
          </Text>
          <Text color="white" weight="bold" size="1.3em" style={{ whiteSpace: "pre-wrap" }}>
            {subtitle}
          </Text>
        </Box>
        {status === PaperStatus.Done && (
          <Box fill round="6px" background={{ color: "black", opacity: 0.45 }} align="center" justify="center">
            <Box pad="1.2em" round="35px" border={{ color: "white", size: "2px" }}>
              <Text color="white" weight="bold">
                {t("program-item.view-result")}
              </Text>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default () => {
  const { isLogined } = useLoginContext()
  const history = useHistory()
  const { t } = useTranslation()
  const { lang } = useLanguageContext()
  const { data, loading } = useDinoMyPapersQuery({ context: { "x-dino-lang": lang } })

  useEffect(() => {
    if (!isLogined()) {
      ToastsStore.error("인증 정보가 없습니다")
      history.replace("/")
    }
  }, [])

  return (
    <MainLayout>
      <PageHeader texts={["STEP 3.", t("mypage.title"), t("mypage.desc")]} />
      {loading ? (
        <Box>Loading...</Box>
      ) : (
        <Box fill="horizontal" direction="row" wrap justify="around" alignContent="around">
          {data?.dinoAllPaper?.map((v, i) => (
            <DinoButton
              key={i}
              id={v.id}
              title={t(v.item.title)}
              subtitle={t(v.item.subtitle)}
              color={v.item.colorPallete}
              status={v.statusCd}
            />
          ))}
        </Box>
      )}
    </MainLayout>
  )
}
