import React from "react"
import { FMatResultPage } from "../diag/Result"
import FMatResultHmiPage from "../diag/ResultHmi"
import { useGetResultQuery } from "../../generated-types"
import { useParams } from "react-router"

export const PaperResultPage: React.FC = () => {
  // 완료 상태여야 함
  const { paperId } = useParams<any>()
  const { loading, data, error } = useGetResultQuery({ variables: { paperId } })
  if (loading) return null
  if (error) {
    return <div>{error.message}</div>
  }

  const {
    paper: { item, results },
  } = data

  // TODO check stat
  let resultComponent,
    resultProps = { title: item.name, results, hideChart: undefined }

  switch (item.id) {
    case "4MAT_SLP":
    case "4MAT_LTM":
    case "4MAT_LBI":
    case "4MAT_LBI360":
      resultComponent = FMatResultPage
      break
    case "4MAT_TRSI":
    case "4MAT_TSI":
      resultComponent = FMatResultPage
      resultProps = { ...resultProps, hideChart: true }
      break
    case "4MAT_HMI":
      resultComponent = FMatResultHmiPage
      break
    default:
      resultComponent = null
  }

  if (!resultComponent) return null
  return React.createElement(resultComponent, resultProps, null)
}
