export const isSupportItem = (item) => {
  switch (item) {
    case "4MAT_LTM":
    case "4MAT_SLP":
    case "4MAT_HMI":
    case "4MAT_TSI":
    case "4MAT_LBI":
      return true
  }
  return false
}
