import { Box } from "grommet"
import Markdown from "markdown-to-jsx"
import React from "react"
import { useLanguageContext } from "../../../components/context/LanguageContext"
import { useTranslation } from "react-i18next"
import FMatType1Img from "../../../imgs/4mat_result_type1.gif"
import FMatType2Img from "../../../imgs/4mat_result_type2.gif"
import FMatType3Img from "../../../imgs/4mat_result_type3.gif"
import FMatType4Img from "../../../imgs/4mat_result_type4.gif"
import { getDominant } from "@dino/shared"

export interface FMatDescProps {
  pgmId: string
  value: number[]
}

const typeImgs = [FMatType1Img, FMatType2Img, FMatType3Img, FMatType4Img]

const getDesc = (lang, pgmId, value, t, i18n) => {
  let response = { icon: null, text: "" }
  if (!pgmId || !value) {
    return response
  }

  if (pgmId === "FMAT_LBI360") {
    pgmId = "FMAT_LBI"
  }

  switch (pgmId) {
    case "FMAT_LTM":
    case "FMAT_SLP":
    case "FMAT_LBI":
    case "FMAT_TSI":
      const dom = getDominant(value)
      const type = dom + 1

      if (type <= 0) {
        return response
      }

      const resultKey = `${pgmId}.result.type${type}`
      if (!i18n.exists(resultKey) || typeImgs.length <= dom) {
        return response
      }

      const [, , , , wd] = value
      const wdText = wd ? `WD.result.${wd > 0 ? "D" : "W"}` : ""
      response.icon = typeImgs[dom]
      response.text = t(resultKey) + (wdText && "\n\n\n\n" + t(wdText))
      break
    case "FMAT_HMI":
      const [hmi] = value
      response.text = hmi ? t(`HMI.result.${hmi > 0 ? "R" : "L"}`) : ""
  }

  return response
}
export const FMatDesc: React.FC<FMatDescProps> = ({ pgmId, value }) => {
  const { lang } = useLanguageContext()
  const { t, i18n } = useTranslation()
  const desc = getDesc(lang, pgmId, value, t, i18n)

  return (
    <Box pad="12px">
      {desc.icon && (
        <Box alignSelf="center" margin={{ bottom: "20px" }}>
          <img src={desc.icon} width={200} height={200} />
        </Box>
      )}

      <Box>
        <Markdown
          options={{
            overrides: {
              p: {
                component: ({ children, ...props }) => <div {...props}>{children}</div>,
                props: { style: { fontSize: "15px", marginBottom: "5px", marginTop: "5px" } },
              },
              h4: {
                component: ({ children, ...props }) => <div {...props}>{children}</div>,
                props: { style: { color: "#75bcea", fontWeight: "bold", marginTop: "20px" } },
              },
              ul: {
                component: ({ children, ...props }) => <ul {...props}>{children}</ul>,
                props: { style: { paddingLeft: "25px", marginTop: "0px" } },
              },
              li: {
                component: ({ children, ...props }) => <li {...props}>{children}</li>,
                props: { style: { fontSize: "15px", lineHeight: "20px" } },
              },
            },
          }}
        >
          {desc.text}
        </Markdown>
      </Box>
    </Box>
  )
}
