import React, { useEffect, useMemo, useState } from "react"
import { useApolloClient } from "@apollo/client"
import { IntroPartsFragment, IntroPartsFragmentDoc, useEcodeByPaperQuery } from "../../generated-types"
import { Box, Button, Heading, Markdown, Paragraph } from "grommet"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { ToastsStore } from "react-toasts"
import { useDinoContext } from "./components/DinoContext"
import { DinoLayout } from "./components/Layout"
import { DiagIcon } from "./components/DiagIcon"
import { template as _template } from "lodash"

interface PathParams {
  paperId: string
  pgmId: string
  contentId: string
}

export const Intro: React.FC = () => {
  const { paperId, pgmId, contentId } = useParams<PathParams>()
  const history = useHistory()
  const client = useApolloClient()
  const { t } = useTranslation()
  const dino = useDinoContext()
  const [leaderName, setLeaderName] = useState("")
  const { data } = useEcodeByPaperQuery({ variables: { paperId: Number(paperId) } })
  const introData = useMemo(() => {
    if (data) {
      setLeaderName(data.ecodeByPaper.leaderName)
    }

    if (!data || pgmId !== "FMAT_LBI360" || (data && !data.ecodeByPaper.isLeader))
      return client.readFragment<IntroPartsFragment>({
        fragment: IntroPartsFragmentDoc,
        id: `DinoContentIntro:${pgmId}:${contentId}:INTRO`,
      })

    return client.readFragment<IntroPartsFragment>({
      fragment: IntroPartsFragmentDoc,
      id: `DinoContentIntro:${pgmId}:1:INTRO`,
    })
  }, [data])
  useEffect(() => {
    if (!introData) {
      ToastsStore.warning("Illegal Access?")
      history.push(`/papers/${paperId}`)
    }
  }, [introData])
  const [title, subtitle, fullname, part, desc1, desc2] = introData?.texts || []
  const desc2Template = _template(t(desc2))

  return (
    <DinoLayout>
      <Box width="large" align="center" pad={{ top: "20px" }}>
        <DiagIcon title={t(title)} subtitle={t(subtitle)} fullname={t(fullname)} part={t(part)} />
        <Heading
          level={3}
          margin={{ bottom: "small" }}
          textAlign="center"
          style={{ marginTop: "1em", whiteSpace: "pre-wrap" }}
        >
          {t(desc1) || ""}
        </Heading>
      </Box>
      <Markdown
        style={{ wordBreak: "keep-all" }}
        components={{
          p: (props) => <Paragraph {...props} fill textAlign="center" margin={{ top: "0.5em" }} color="#A4A4A4" />,
        }}
      >
        {desc2Template({ leaderName }) || ""}
      </Markdown>
      <Box margin={{ top: "20px" }}>
        <Button primary color="#d6544d" onClick={() => dino.goNext()} label={t("button.start")} />
      </Box>
    </DinoLayout>
  )
}
