import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpBackend from "i18next-http-backend"

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    ns: ["COMMON", "CHECK"],
    defaultNS: "COMMON",
    fallbackNS: "CHECK",
    cleanCode: true,
    interpolation: {
      escapeValue: true,
    },
    detection: {
      order: ["cookie", "localStorage", "navigator"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: `${process.env.REACT_APP_PNM_I18N_URL}/api/messages/{{ns}}/{{lng}}`,
    },
  })

export default i18n
