import React from "react"
import { useTranslation } from "react-i18next"
import { FormField, TextInput } from "grommet"

interface ProfileField {
  label: string
  field: string
}
export const EmailField: React.FC<ProfileField> = ({ label, field }) => {
  const { t } = useTranslation()
  return (
    <FormField
      // label={t(`label.${label}`)}
      name={field}
      required
      autoCorrect="off"
      autoCapitalize="off"
      placeholder={t(`label.${label}`)}
      validate={{
        regexp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t("messages.VALIDATION/EMAIL"),
      }}
    />
  )
}
