import { Anchor, Box, Text } from "grommet"
import React from "react"

export interface CopyrightProps {
  owner?: string
}

const Copyright: React.FC<CopyrightProps> = ({ owner = "Paul&Mark" }) => {
  return (
    <Box margin={{ top: "20px", bottom: "20px" }} align="center">
      {owner ? (
        <Text size="xsmall">
          © {new Date().getFullYear()} {owner}. All rights reserved.
        </Text>
      ) : (
        <Text size="xsmall">
          Powered by <Anchor label="Paul&Mark" href="https://paulnmark.com" target="_blank" />
        </Text>
      )}
    </Box>
  )
}

export default Copyright
