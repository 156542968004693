import { FMatQuadrantGraphNode, FMatQuadrantRenderer, FMatQuadrantTriangleNode } from "@dino/shared"
import { Box, Text } from "grommet"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { useDinoAllPaperLazyQuery, useDinoPaperQuery } from "../../../generated-types"
import { ResultLayout } from "./ResultLayout"
import { useTranslation } from "react-i18next"
import { FMatDesc } from "./FMatDesc"
import { isSupportItem } from "../report/ReportUtil"

export default () => {
  const { paperId } = useParams<any>()
  const { t } = useTranslation()
  const { data } = useDinoPaperQuery({ variables: { paperId } })
  const [fetchAllPaper, { data: allPapers }] = useDinoAllPaperLazyQuery()
  const [showSendReport, setShowSendReport] = useState(false)

  const value = useMemo(() => {
    const newValue = []
    if (data) {
      const { results } = data.dinoPaper
      newValue.push(results[0])
      newValue.push(results[1])
      newValue.push(results[2])
      newValue.push(results[3])

      if (data.dinoPaper.results[4] !== undefined) {
        newValue[4] = data.dinoPaper.results[4]
      }

      if (allPapers) {
        const hmiPaper = allPapers.dinoAllPaper.find((v) => v.item.id === "FMAT_HMI" && v.statusCd === "DONE")
        if (hmiPaper) {
          newValue[5] = hmiPaper.results[0]
          setShowSendReport(true)
        } else {
          setShowSendReport(false)
        }
      }
    }

    return newValue
  }, [data, allPapers])

  useEffect(() => {
    if (!data) {
      return
    }
    if (["FMAT_LTM", "FMAT_SLP"].includes(data.dinoPaper.item.id)) {
      fetchAllPaper()
    } else {
      setShowSendReport(isSupportItem(data.dinoPaper.item.id))
    }
  }, [data])

  return (
    <ResultLayout showSendReport extraContent={<FMatDesc pgmId={data?.dinoPaper?.item?.id} value={value} />}>
      {({ width, refStage }) => (
        <>
          <FMatQuadrantRenderer width={width} ref={refStage}>
            {value.length >= 4 && <FMatQuadrantGraphNode value={value} />}
            {value.length === 6 && <FMatQuadrantTriangleNode value={value} />}
          </FMatQuadrantRenderer>
          <Box margin={{ top: "15px" }} gap="small">
            {value[4] !== undefined && (
              <Text size="22px" weight="bold">
                {t("dw-score")}: {value[4]}
              </Text>
            )}
            {value[5] !== undefined && (
              <Text size="22px" weight="bold">
                {t("hmi-score")}: {value[5]}
              </Text>
            )}
          </Box>
        </>
      )}
    </ResultLayout>
  )
}
