import { Box, Button, Heading, Stack, Text } from "grommet"
import { Checkmark } from "grommet-icons"
import React from "react"
import Copyright from "../../components/layout/Copyright"
import DiagLayout from "../../components/DiagLayout"
import { DiagProgressBar } from "../../components/DiagProgressBar"
import { QuestionLabel } from "../../components/QuestionLabel"
import { DiagControlBar } from "../../components/DiagControlBar"

type FMatAnswerOnePageProps = {
  index: number
  total: number
  question?: string
  answers: string[]
  score: number[] | null
  hasPrevious?: boolean
  hasNext?: boolean
  waiting?: boolean
  onUpdateScore: (seq: number[], done: boolean) => void
  onPrevious: () => void
  onNext: () => void
}

export const FMatAnswerOnePage: React.FC<FMatAnswerOnePageProps> = ({
  index,
  total,
  question,
  answers,
  score = [null, null],
  onUpdateScore,
  waiting,
  hasPrevious = false,
  hasNext,
  onPrevious,
  onNext,
}) => {
  const ctrlProps = {
    hasPrevious,
    hasNext,
    waiting,
    onPrevious,
    onNext,
  }

  return (
    <DiagLayout>
      <Box width="medium" align="center">
        <Heading level={2} margin={{ bottom: "small" }}>
          4MAT - Part B
        </Heading>
      </Box>

      <DiagProgressBar index={index} total={total} />
      <QuestionLabel question={question} waiting={waiting} />
      <DiagControlBar {...ctrlProps} />
      {/* <Box
        fill="horizontal"
        direction="row"
        margin={{ top: "small", bottom: "small" }}
        justify="between"
        height="1.3em"
      >
        <Box>{hasPrevious && <Button plain icon={<Previous size="medium" />} />}</Box>
      </Box> */}
      <Box fill="horizontal" gap="small">
        {answers.map((a, _i) => {
          const i = _i + 1
          return (
            <Button
              plain
              key={_i}
              onClick={() => onUpdateScore(i === 1 ? [1, 0] : [0, 1], true)}
              disabled={waiting}
              focusIndicator={false}
            >
              <Box
                fill="horizontal"
                height="70px"
                pad="middle"
                border={{ size: "2px", color: "brand" }}
                round="medium"
                justify="center"
              >
                <Stack anchor="right" fill={true}>
                  <Box fill justify="center" pad={{ left: "20px", right: "50px" }} style={{ wordBreak: "keep-all" }}>
                    <Text style={{ fontWeight: "bold", lineHeight: "18px" }} alignSelf="start">
                      {a}
                    </Text>
                  </Box>
                  <Box
                    background={score[_i] === 1 ? "brand" : "light-5"}
                    pad="5px"
                    round="full"
                    width="35px"
                    align="center"
                    margin={{ right: "10px" }}
                  >
                    {score[_i] === 1 ? <Checkmark /> : <Text>-</Text>}
                  </Box>
                </Stack>
              </Box>
            </Button>
          )
        })}
      </Box>
      <Copyright />
    </DiagLayout>
  )
}

export default FMatAnswerOnePage
