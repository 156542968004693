import { Machine, assign } from "xstate"
import _ from "lodash"

interface GuideMachineContext {
  frames: number
  page: number
  progress: number
}

interface CreateGuideMachineParam {
  fps?: number
  trigger: { [k: number]: number }
  end: number
}
export const createGuideMachine = ({ fps = 10, trigger, end }: CreateGuideMachineParam) => {
  return Machine<GuideMachineContext>({
    id: "guide",
    initial: "running",
    context: {
      frames: 0,
      page: 0,
      progress: 0,
    },
    states: {
      running: {
        invoke: {
          src: (context) => (cb) => {
            const interval = setInterval(() => {
              cb("TICK")
            }, 1000 / fps)
            return () => {
              clearInterval(interval)
            }
          },
        },
        always: [
          {
            target: "paused",
            cond: ({ frames }) => frames >= end,
          },
        ],
        on: {
          TICK: {
            actions: assign((context) => {
              const newFrames = context.frames + 1
              const newPage = trigger[newFrames]
              return { frames: newFrames, page: newPage || context.page, progress: newFrames / end }
            }),
          },
        },
      },
      paused: {
        always: [
          {
            target: "running",
            cond: ({ frames }) => frames < end,
          },
        ],
      },
    },
    on: {
      RESET: {
        actions: assign<GuideMachineContext>({ frames: 0, page: 0 }),
      },
    },
  })
}
