import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, Observable } from "@apollo/client"
import { onError } from "apollo-link-error"

// TODO 쿠키 방식 인증 검토
const GRAPHQL_URL = process.env.REACT_APP_API

const cache = new InMemoryCache()

// @client를 쓰는 경우 link를 쓰지 않는다 ㅠ
// const stateLink = withClientState({
//   cache,
//   resolvers: {
//     Mutation: {
//       initDiag: (_, param, ctx) => {
//         const cache = ctx.cache as InMemoryCache
//         const data = {
//           diagId: 1,
//           answer: [],
//         }
//         cache.writeData({ id: "diag", data })
//         return null
//       },
//       addAnswer: (_, param, ctx) => {
//         const cache = ctx.cache as InMemoryCache
//       },
//       closeDiag: (_, param, ctx) => {
//         const cache = ctx.cache as InMemoryCache
//         // cache.evict()
//       },
//     },
//     Query: {
//       currentDiag: (_, param, { cache }) => {
//         return null
//       },
//     },
//   },
// })

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = sessionStorage.getItem("token")
//   // return the headers to the context so httpLink can read them
//   // TODO exp 확인하여 알아서 제거 처리
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     },
//   }
// })

// reference from: https://www.apollographql.com/docs/link/links/rest/#rest-directive
const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const token = localStorage.getItem("token")
    return {
      headers: {
        ...headers,
        Accept: "application/json",
        Authorization: token,
      },
    }
  })
  return forward(operation).map((result) => {
    const { restResponses } = operation.getContext()
    const authTokenResponse = restResponses.find((res) => res.headers.has("Authorization"))
    // You might also filter on res.url to find the response of a specific API call
    if (authTokenResponse) {
      localStorage.setItem("token", authTokenResponse.headers.get("Authorization"))
    }
    return result
  })
})

const authLink = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem("token")
  const lang = localStorage.getItem("i18nextLng")
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      "x-dino-lang": lang,
      authorization: token ? `Bearer ${token}` : "",
    },
  }))

  // forward(operation) // 이 구문 자체가 실행을 의미하지는 않는다!

  return new Observable((observer) => {
    let handle
    try {
      handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      })
    } catch (e) {
      observer.error.bind(observer)
    }
    return () => {
      if (handle) handle.unsubscribe()
    }
  })
})

const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // TODO 인증 에러시 토큰 연장 후 재전송 처리
  if (graphQLErrors) graphQLErrors.map((err) => console.error(err))

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

export const createClient = (eventLink?: ApolloLink) => {
  const _eventLink = eventLink ? [eventLink] : []

  return new ApolloClient({
    cache,
    connectToDevTools: true,
    link: ApolloLink.from([..._eventLink, authLink, new HttpLink({ uri: GRAPHQL_URL, credentials: "same-origin" })]),
  })
}

// TODO x-dino-lang 처리 필요
