import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSubmitAnswersMutation, usePaperSheetQuery, usePaperQuery } from "../../generated-types"
import { IntroPage } from "./Intro"
import { FMatAnswerSeqPage } from "./FMatAnswerSeq"
import { FMatAnswerOnePage } from "./FMatAnswerOne"
import FMatAnswerHmiPage from "./FMatAnswerHmi"
import { useHistory, useParams } from "react-router"
import { LanguageContext } from "../../components/context/LanguageContext"
import { EmptyPage } from "./EmptyPage"
import { Spinning } from "grommet-controls"
import { Text } from "grommet"
import { useEffect } from "react"
import { handleGqlError, resolveApolloError } from "../../components/utils"
import { ToastsStore } from "react-toasts"

const TRANSITION_TIME_MS = 280

type Action =
  | { type: "prev" }
  | { type: "next" }
  | { type: "advance" }
  | { type: "update"; score: number[]; done: boolean; finished: boolean }
  | { type: "magic"; key: string }

const REDUCER = (state, action: Action) => {
  let toIdx = state.idx

  switch (action.type) {
    case "prev":
      toIdx = state.idx === 0 ? toIdx : toIdx - 1
      return { ...state, idx: toIdx, waiting: false, score: state.sheet[toIdx] }
    case "next":
      if (state.finished) return state
      toIdx = toIdx + 1
      return { ...state, idx: toIdx, waiting: false, score: state.sheet[toIdx] }
    case "advance":
      if (state.finished) return state
      toIdx = toIdx + 1
      return { ...state, idx: toIdx, waiting: false, done: false, score: state.sheet[toIdx] }
    case "update":
      const newState = { ...state, score: action.score, waiting: action.done, finished: action.finished || false }
      if (action.done) {
        const newSheet = [...state.sheet]
        newSheet[state.idx] = action.score
        newState.sheet = newSheet
        newState.done = true
      }
      return newState
    case "magic":
      return action.key === "A"
        ? {
            idx: 16,
            score: undefined,
            waiting: false,
            finished: false,
            sheet: [undefined, ..._.times(15, () => _.shuffle([4, 3, 2, 1]))],
          }
        : {
            idx: 27,
            score: undefined,
            waiting: false,
            finished: false,
            sheet: [...state.sheet, undefined, ..._.times(10, () => [1])],
          }
  }
}

// 유저의 행동에 따른 값 및 상태값 제어 그리고 페이지 페칭 전담
// 결과 페이지만 별도의 URL로 구성
// 저장소에 처리중이었던 진단 내용이 있으면 resume 가능
export const FMatControlPage: React.FC = (props) => {
  const { paperId } = useParams<any>()
  const { t } = useTranslation()
  const history = useHistory()
  let { lang } = useContext(LanguageContext)
  if (!["en", "ko"].includes(lang)) lang = "en"
  const { data: paperData } = usePaperQuery({
    variables: { paperId },
    onError: (e) => {
      handleGqlError(e)
      history.push("/")
    },
  })
  const { loading, data, error } = usePaperSheetQuery({ variables: { paperId, lang }, fetchPolicy: "no-cache" })

  const [submitAnswer] = useSubmitAnswersMutation()
  const [state, dispatch] = React.useReducer(REDUCER, {
    idx: 0,
    score: undefined, // 현재 표시되는 점수
    done: false,
    waiting: false,
    finished: false,
    sheet: [], // 점수 입력 내용
  })

  useEffect(() => {
    if (!paperData) return

    if (/^5LL/.test(paperData.paper.item.id)) history.push(`/papers/${paperId}`)
    if (paperData.paper.status === "DONE") history.push(`/paper/${paperId}/result`)
  }, [paperData])

  const { idx, score, waiting, sheet } = state
  const steps = data ? data.paperSheet.steps : []

  const seq = useMemo(
    () =>
      _.chain(data ? data.paperSheet.steps : [])
        .map((v, idx) => ({ ...v, idx }))
        .groupBy((v) => v.type + "/" + v.group)
        .mapValues((v) => v.map(({ idx }) => idx))
        .value(),
    [data]
  )

  const onUpdateScore = React.useCallback(
    (score: number[], done: boolean) => {
      dispatch({ type: "update", score, done, finished: done && state.idx === steps.length - 1 })
    },
    [state, steps]
  )

  useEffect(() => {
    let timer

    if (state.done && !state.finished) {
      timer = setTimeout(() => dispatch({ type: "advance" }), TRANSITION_TIME_MS)
    }
    return () => timer && clearTimeout(timer)
  }, [state])

  useEffect(() => {
    if (state.finished) {
      console.log(state)
      const answerSheet = _.chain(state.sheet)
        .map((seq, i) =>
          seq
            ? _.chain(seq)
                .map((a, ai) => [ai + 1, a])
                .filter(([, s]) => s > 0)
                .orderBy([([, a]) => a], ["desc"])
                .map(([ai]) => steps[i].res[ai].id)
                .join(",")
                .value()
            : undefined
        )
        .compact()
        .value()

      submitAnswer({ variables: { paperId, answers: answerSheet } })
        .then((r) => {
          history.push(`/paper/${paperId}/result`)
        })
        .catch((e) => console.error(e))
    }
  }, [state, paperId, steps, history, submitAnswer])

  if (loading) {
    return (
      <EmptyPage>
        <Spinning size="medium" kind="circle" />
      </EmptyPage>
    )
  }

  if (error) {
    const errorCodes = resolveApolloError(error)
    // const { networkError, graphQLErrors } = error
    // const errorCodes = []
    // if (networkError) errorCodes.push(networkError)
    // graphQLErrors.forEach(({ message }) => {
    //   errorCodes.push(message)
    // })
    return (
      <EmptyPage>
        {errorCodes.map((code, i) => (
          <Text key={i} color="status-error">
            {t(`error.${code}`)}
          </Text>
        ))}
      </EmptyPage>
    )
  }

  const { type, group, res } = steps[idx]
  const key = type + "/" + group

  if (type === "INFO") {
    const { desc } = res[0]

    return (
      <IntroPage
        onRequestNext={() => dispatch({ type: "next" })}
        description={desc || ""}
        title={`${paperData.paper.item.name}${group.startsWith("_") ? "" : " (Part " + group + ")"}`}
        onMagic={(key) => {
          dispatch({ type: "magic", key })
        }}
        id={group}
      />
    )
  } else {
    const props = {
      index: seq[key].indexOf(idx) + 1,
      total: seq[key].length,
      // onDone: onNext,
      score,
      waiting,
      hasPrevious: seq[key].includes(idx - 1),
      hasNext: !!sheet[idx],
      onUpdateScore,
      onPrevious: () => dispatch({ type: "prev" }),
      onNext: () => dispatch({ type: "next" }),
    }

    const [q, ...a] = res
    switch (type) {
      case "FMAT_SEQ":
        return <FMatAnswerSeqPage {...props} question={q.desc} answers={a.map(({ desc }) => desc)} />
      case "FMAT_ONE":
        return <FMatAnswerOnePage {...props} question={q.desc} answers={a.map(({ desc }) => desc)} />
      case "FMAT_HMI":
        return <FMatAnswerHmiPage {...props} question={undefined} answers={[res[0].desc, res[1].desc]} />
    }
  }

  return <EmptyPage />
}

export const DiagRedirector = (props) => {
  const { paperId } = useParams<any>()
  const history = useHistory()
  const { data, called } = usePaperQuery({
    variables: { paperId },
    onError: (e) => {
      handleGqlError(e)
      history.push("/")
    },
  })

  useEffect(() => {
    if (!data) {
      if (called) {
        ToastsStore.error("Wrong Access")
        history.push("/")
        return
      }
    }

    if (data.paper.status === "DONE") {
      history.push(`/paper/${paperId}/result`)
      return
    }

    history.push(`/paper/${paperId}/diag`)
  }, [data])
  return null
}
