import React from "react"
import { Box, Button } from "grommet"
import { Previous, Next } from "grommet-icons"
import { useDinoContext } from "./DinoContext"

interface NavivationControlProps {
  prev?: boolean
  next?: boolean
}
export const NavigationControl: React.FC<NavivationControlProps> = ({ prev, next }) => {
  const dino = useDinoContext()

  return (
    <Box align="center">
      <Box direction="row" margin={{ top: "0.7em", bottom: "0.7em" }} height="1.7em">
        <Box flex={false} direction="row" gap="small">
          <Button
            plain
            icon={<Previous size="30px" />}
            onClick={() => dino.goPrev()}
            disabled={dino?.waiting || (prev !== undefined ? !prev : !dino?.nav[0])}
            focusIndicator={false}
          />
          <Button
            plain
            icon={<Next size="30px" />}
            onClick={() => dino.goNext()}
            disabled={dino?.waiting || (next !== undefined ? !next : !dino?.nav[1])}
            focusIndicator={false}
          />
        </Box>
      </Box>
    </Box>
  )
}
