import { useApolloClient } from "@apollo/client"
import { Box, Layer, Stack, Text } from "grommet"
import _ from "lodash"
import React, { useState } from "react"
import { useParams } from "react-router"
import { ItemPartsFragment, ItemPartsFragmentDoc, useEcodeByPaperQuery } from "../../../generated-types"
import { CircleButton } from "../components/CircleButton"
import { useDinoContext } from "../components/DinoContext"
import { NavigationControl } from "../components/NavigationControl"
import { QuestionLabel } from "../components/QuestionLabel"
import produce from "immer"
import { SequencePickerGuide } from "../components/SequencePickerGuide"
import { useTranslation } from "react-i18next"

interface SequencePickerPanelProps {
  value: string
  text: string
  onSelect: (score) => void
}

const SequencePickerPanel: React.FC<SequencePickerPanelProps> = ({ text, value, onSelect }) => {
  const r = "45px"
  const dino = useDinoContext()

  const score = dino.response[value]
  const alreadySelected = new Set(Object.values(dino.response))

  return (
    <Box pad={{ right: "15px" }} margin={{ bottom: "15px" }}>
      <Box direction="row" width="100%">
        <Box width="5px" background="#AAAAAA"></Box>
        <Box width="100%" margin={{ left: "10px" }} pad={{ top: "5px", bottom: "5px" }}>
          <Box margin={{ bottom: "5px" }}>
            <Text size="18px" weight="bold">
              {text}
            </Text>
          </Box>
          <Stack>
            <Box height={r}>
              <Box style={{ background: "#e3e3e3", height: "2px" }} margin={{ top: "21px" }} />
            </Box>
            <Box direction="row" justify="between">
              {_.times(4, (i) => (
                <CircleButton
                  key={i}
                  label={"" + (i + 1)}
                  status={score === i + 1 ? "SELECTED" : alreadySelected.has(i + 1) ? "DIMMED" : "NONE"}
                  onClick={() => {
                    onSelect(i + 1)
                  }}
                />
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

// TODO 신규 페이지인지 갔다온 페이지인지 구분이 되어야 함 =>
// isDirty=true 상태에서 모든 답을 채우지 않은 경우 다음페이지로 넘어가지 못함
// TODO context scope를 따로 제한하는 방법이 있나?
export const SequencePick = () => {
  const { pgmId, contentId, itemId, paperId } = useParams<{
    pgmId: string
    contentId: string
    itemId: string
    paperId: string
  }>()
  const { t } = useTranslation()
  const client = useApolloClient()
  const dino = useDinoContext()
  const [tutorial, setTutorial] = useState(() => Object.keys(dino.response).length === 0)
  const { data } = useEcodeByPaperQuery({ variables: { paperId: Number(paperId) } })
  const questionData = (() => {
    if (!data || pgmId !== "FMAT_LBI360")
      return client.readFragment<ItemPartsFragment>({
        fragment: ItemPartsFragmentDoc,
        id: `DinoContentItem:${pgmId}:${contentId}:${itemId}`,
      })

    if (tutorial && pgmId === "FMAT_LBI360") {
      dino.spliceSheets(data.ecodeByPaper.isLeader ? 0 : 1)
    }

    return client.readFragment<ItemPartsFragment>({
      fragment: ItemPartsFragmentDoc,
      id: `DinoContentItem:${pgmId}:${data.ecodeByPaper.isLeader ? 1 : 0}:${itemId}`,
    })
  })()

  return (
    <>
      <QuestionLabel waiting={dino?.waiting} question={t(questionData?.texts[0])} />

      <Box fill="horizontal" gap="small" margin={{ top: "20px" }}>
        {questionData?.values?.map((v, i) => (
          <SequencePickerPanel
            key={i}
            value={v.key}
            text={t(v.text)}
            onSelect={(score) => {
              const initResponse =
                Object.keys(dino.response).length > 0
                  ? dino.response
                  : _.transform(
                      questionData.values.map(({ key }) => key),
                      (a, key) => {
                        a[key] = null
                      },
                      {}
                    )

              const newResponse = produce(initResponse, (draft) => {
                Object.entries(dino.response)
                  .filter(([, _score]) => score === _score)
                  .forEach(([value]) => {
                    draft[value] = null
                  })

                draft[v.key] = score
              })

              dino.putResponse(newResponse)
            }}
          />
        ))}
      </Box>

      <NavigationControl next={questionData?.values.length === _(dino?.response).values().compact().size()} />

      {tutorial && (
        <Layer responsive={false} full animation="slide" position="top">
          <SequencePickerGuide onFinish={() => setTutorial(false)} />
        </Layer>
      )}
    </>
  )
}
