import React from "react"
import { GrommetRoot } from "../components/layout/GrommetRoot"
import { Box, Spinner } from "grommet"

export const LoadingPage: React.FC = () => {
  return (
    <GrommetRoot>
      <Box fill align="center" justify="center" direction="column" style={{ minHeight: "100vh" }}>
        <Spinner size="large" />
      </Box>
    </GrommetRoot>
  )
}
