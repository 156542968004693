import { Box, Heading } from "grommet"
import _ from "lodash"
import React from "react"
import Copyright from "../../components/layout/Copyright"
import { DiagControlBar } from "../../components/DiagControlBar"
import DiagLayout from "../../components/DiagLayout"
import { DiagProgressBar } from "../../components/DiagProgressBar"
import { FlowButtonPanel } from "../../components/FlowButtonPanel"
import { MetaButtonPanel } from "../../components/MetaButtonPanel"
import { QuestionLabel } from "../../components/QuestionLabel"

type FMatAnswerSeqPageProps = {
  index: number
  total: number
  question?: string
  answers: string[]
  score: number[] | null
  hasPrevious?: boolean
  hasNext?: boolean
  waiting?: boolean
  onUpdateScore: (seq: number[], done: boolean) => void
  onPrevious: () => void
  onNext: () => void
}

const isDone = (score: number[]) =>
  _.chain(score)
    .compact()
    .size()
    .eq(4)
    .value()

const isDirty = (score: number[]) =>
  _.chain(score)
    .compact()
    .size()
    .value() > 0

const INIT_SCORE = [null, null, null, null]

export const FMatAnswerSeqPage: React.FC<FMatAnswerSeqPageProps> = ({
  index,
  total,
  question,
  answers,
  score = INIT_SCORE,
  hasPrevious,
  hasNext,
  waiting = false,
  onUpdateScore,
  onPrevious,
  onNext,
}) => {
  const [metaMode, setMetaMode] = React.useState(false)

  React.useEffect(() => {
    onUpdateScore(INIT_SCORE, false)
  }, [metaMode])

  const ctrlProps = {
    hasPrevious,
    hasNext,
    waiting,
    onPrevious,
    onNext,
    onReset: () => onUpdateScore(INIT_SCORE, false),
    onToggleMetaView: () => setMetaMode(!metaMode),
  }

  return (
    <DiagLayout>
      <Box width="large" align="center">
        <Heading level={2} margin={{ bottom: "small" }}>
          4MAT - Part A
        </Heading>
      </Box>
      <DiagProgressBar index={index} total={total} />
      <QuestionLabel question={question} waiting={waiting} />
      <DiagControlBar {...ctrlProps} dirty={isDirty(score)} isMetaView={metaMode} />
      {metaMode ? (
        <MetaButtonPanel answers={answers} score={score} onUpdateScore={seq => onUpdateScore(seq, isDone(seq))} />
      ) : (
        <FlowButtonPanel answers={answers} score={score} onUpdateScore={seq => onUpdateScore(seq, isDone(seq))} />
      )}
      <Copyright />
    </DiagLayout>
  )
}

export default FMatAnswerSeqPage
