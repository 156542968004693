import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { useEntryCheckQuery, useAuthByCodeMutation } from "../../generated-types"
import { handleGqlError } from "../../components/utils"
import { useLoginContext } from "../../components/context/LoginContext"
import { MainLayout } from "../../components/layout/MainLayout"

export default () => {
  const { code } = useParams<any>()
  const history = useHistory()
  const { login, isLogined } = useLoginContext()
  const { data } = useEntryCheckQuery({
    variables: { code },
    onError: (e) => {
      handleGqlError(e)
      history.push("/")
    },
  })
  const [authByCodeMutation] = useAuthByCodeMutation({ fetchPolicy: "no-cache" })

  useEffect(() => {
    if (isLogined()) {
      history.push("/my")
    }
  }, [])

  useEffect(() => {
    if (!data) return
    if (data.entryCheck.isUsed) {
      authByCodeMutation({ variables: { code } })
        .then((r) => {
          const { username, token, exp } = r.data.authByCode
          login(username, token, exp)
          // TODO redir 파라메터 처리
          history.push("/my")
        })
        .catch(handleGqlError)
    } else {
      history.push(
        data.entryCheck.codeType === "RCODE" ? `/login/${code}/profile` : `/login/${data.entryCheck.ecode}/profile`
      )
    }
  }, [data])

  return <MainLayout loading={true} />
}
