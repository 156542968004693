import { Box } from "grommet"
import { Spinning } from "grommet-controls"
import React from "react"
import { useTranslation } from "react-i18next"
import { DinoLayout } from "./Layout"

export const ProcessingPage = () => {
  const { t } = useTranslation()
  return (
    <DinoLayout>
      <Box width="100%" height="calc(100vh - 150px)" align="center" justify="center" gap="20px">
        <Box>
          <Spinning kind="wandering-cubes" size="large" color="neutral-1" />
        </Box>
        <Box align="center">{t("label.under-analysis")}</Box>
      </Box>
    </DinoLayout>
  )
}
