import React from "react"
import { Box, Meter, Text } from "grommet"
import { useDinoContext } from "./DinoContext"

export type DiagProgressBarProps = {
  color?: string
}
export const DiagProgressBar: React.FC<DiagProgressBarProps> = ({ color = "#FEC962" }) => {
  const dino = useDinoContext()
  if (!dino) return null
  const [, index, total] = dino.meta || [0, 0, 0]
  return (
    <Box direction="row" align="center" justify="center" gap="small">
      <Meter
        values={[
          {
            color,
            value: index,
            label: "progress",
          },
        ]}
        round={true}
        thickness="xsmall"
        aria-label="meter"
        background="light-3"
        max={total}
      />
      <Text size="small" color="dark-6">
        {total !== undefined && `${index}/${total}`}
      </Text>
    </Box>
  )
}
