import React from "react"
import { Box, Button } from "grommet"
import { Previous, Next, Revert, FormView, Hide } from "grommet-icons"

export type DiagControlBarProps = {
  hasPrevious: boolean
  hasNext: boolean
  waiting: boolean
  dirty?: boolean
  isMetaView?: boolean
  onPrevious: () => void
  onNext: () => void
  onReset?: () => void
  onToggleMetaView?: () => void
}
export const DiagControlBar: React.FC<DiagControlBarProps> = props => {
  const { hasPrevious, hasNext, waiting, dirty, isMetaView, onPrevious, onNext, onReset, onToggleMetaView } = props
  const ICON_SIZE = "1.7em"
  return (
    <Box fill="horizontal" direction="row" margin={{ top: "0.7em", bottom: "0.7em" }} height="1.7em" justify="between">
      <Box flex={false} direction="row" gap="small">
        <Button
          plain
          icon={<Previous size="1.7em" />}
          onClick={() => onPrevious()}
          disabled={waiting || !hasPrevious}
          focusIndicator={false}
        />
        <Button
          plain
          icon={<Next size={ICON_SIZE} />}
          onClick={() => onNext()}
          disabled={waiting || !hasNext}
          focusIndicator={false}
        />
      </Box>
      <Box flex={false} direction="row" gap="small">
        {onToggleMetaView && (
          <Button
            plain
            icon={isMetaView ? <FormView size={ICON_SIZE} /> : <Hide size={ICON_SIZE} />}
            onClick={() => onToggleMetaView()}
            focusIndicator={false}
          />
        )}
        {onReset && (
          <Button
            plain
            icon={<Revert size={ICON_SIZE} />}
            onClick={() => onReset()}
            disabled={waiting || !dirty}
            focusIndicator={false}
          />
        )}
      </Box>
    </Box>
  )
}
