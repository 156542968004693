import React, { useEffect, useState } from "react"
import { useLanguageContext } from "../context/LanguageContext"
import { Menu } from "grommet"
import { useTranslation } from "react-i18next"

type Size = "small" | "medium" | "large" | "xlarge" | string

type Props = {
  size?: Size
}

export const LanguageSelector = ({ size }: Props) => {
  const { getLocales, changeLanguage, lang } = useLanguageContext()
  const [items, setItems] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    getLocales().then((locs) =>
      setItems(
        locs.map((locale) => ({
          label: t(`common.lang.${locale}`),
          onClick: () => changeLanguage(locale),
        }))
      )
    )
  }, [lang])

  return (
    <Menu
      size={size ?? "small"}
      dropProps={{
        align: { top: "bottom", left: "left" },
        elevation: "xlarge",
      }}
      label={t("common.lang.word") + ": " + t(`common.lang.${lang}`)}
      items={items}
    />
  )
}
