import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  EmailAddress: any;
};

export type CachedDatum = {
  __typename?: 'CachedDatum';
  key: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type CodeProfileInput = {
  nid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export enum CodeType {
  Rcode = 'RCODE',
  Ecode = 'ECODE'
}

export type CreateGroupInput = {
  groupName: Scalars['String'];
  regionCode: Scalars['String'];
  assessments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOrderInput = {
  groupId: Scalars['Int'];
  itemId: Scalars['String'];
  qty: Scalars['Int'];
};

export type CreatePapersInput = {
  itemId: Scalars['String'];
  groupId: Scalars['Int'];
  qty: Scalars['Int'];
};

export type CreateSessionInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  orgNm?: Maybe<Scalars['String']>;
  joinMethod: JoinMethod;
  nidLabel: NidLabel;
  participantCnt: Scalars['Int'];
  ecodePrefix?: Maybe<Scalars['String']>;
  items: Array<Scalars['String']>;
  fields?: Maybe<Array<ProfileField>>;
  client?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  trainer?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  username: Scalars['String'];
  authority: Scalars['String'];
  regionCode: Scalars['String'];
  groupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type DiagStep = {
  __typename?: 'DiagStep';
  id: Scalars['ID'];
  type: DiagStepType;
  group: Scalars['String'];
  res?: Maybe<Array<DiagStepRes>>;
};

/** Diag Step Resources */
export type DiagStepRes = {
  __typename?: 'DiagStepRes';
  id: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
};

export enum DiagStepType {
  Info = 'INFO',
  FmatSeq = 'FMAT_SEQ',
  FmatOne = 'FMAT_ONE',
  FmatHmi = 'FMAT_HMI',
  Fivell = 'FIVELL'
}

export type DinoContent = {
  __typename?: 'DinoContent';
  id: Scalars['ID'];
  intro?: Maybe<DinoContentIntro>;
  itemView: Scalars['String'];
  items?: Maybe<Array<DinoContentItem>>;
};

export type DinoContentIntro = {
  __typename?: 'DinoContentIntro';
  id: Scalars['ID'];
  texts?: Maybe<Array<Scalars['String']>>;
};

export type DinoContentItem = {
  __typename?: 'DinoContentItem';
  id: Scalars['ID'];
  texts?: Maybe<Array<Scalars['String']>>;
  values?: Maybe<Array<DinoContentItemValue>>;
};

export type DinoContentItemValue = {
  __typename?: 'DinoContentItemValue';
  key?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type DinoPaper = {
  __typename?: 'DinoPaper';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<DinoContent>>>;
};

export type DinoPapersFilter = {
  ecId?: Maybe<Scalars['Int']>;
};

export type DItem = {
  __typename?: 'DItem';
  id: Scalars['ID'];
  availableLangs: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  colorPallete?: Maybe<Array<Scalars['String']>>;
  owner: Scalars['String'];
};

export type DPaper = {
  __typename?: 'DPaper';
  id: Scalars['ID'];
  item: DItem;
  statusCd: PaperStatus;
  tryCnt: Scalars['Int'];
  results?: Maybe<Array<Scalars['Int']>>;
};

export type ECode = {
  __typename?: 'ECode';
  id: Scalars['ID'];
  sessId: Scalars['ID'];
  ecode: Scalars['String'];
  rcode?: Maybe<Scalars['String']>;
  session: Session;
  isUsed: Scalars['Boolean'];
  usedAt?: Maybe<Scalars['DateTime']>;
  isPredefined: Scalars['Boolean'];
  isLeader: Scalars['Boolean'];
  nid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
  papers?: Maybe<Array<Paper>>;
  status?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type EcodeField = {
  nid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ECodeField = {
  __typename?: 'ECodeField';
  id: Scalars['ID'];
  label: Scalars['String'];
  field: Scalars['String'];
};

export type ECodeInput = {
  id: Scalars['ID'];
  nid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
};

export enum ECodeStatus {
  Ready = 'READY',
  Done = 'DONE',
  Deleted = 'DELETED'
}


export type EntryCheck = {
  __typename?: 'EntryCheck';
  id: Scalars['ID'];
  ecode: Scalars['String'];
  codeType: CodeType;
  session: Session;
  isUsed: Scalars['Boolean'];
};

export type GetGroupsCriteria = {
  regionCode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type GetOrdersCriteria = {
  groupId?: Maybe<Scalars['Int']>;
  regionCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** ------------- New Graphql ------------- */
export type GetSessionsCriteria = {
  searchKeyword?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  regionCode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type GetUsersCriteria = {
  regionCode?: Maybe<Scalars['String']>;
  groupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Group = {
  __typename?: 'Group';
  groupId?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  assessments?: Maybe<Array<Maybe<GroupAssessment>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<Maybe<User>>>;
  managerNames?: Maybe<Scalars['String']>;
};

export type GroupAssessment = {
  __typename?: 'GroupAssessment';
  groupId?: Maybe<Scalars['Int']>;
  assessment?: Maybe<Scalars['String']>;
  item?: Maybe<NewItem>;
};

export type GroupAssessmentInput = {
  groupId: Scalars['Int'];
  assessment: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  type: ItemType;
  name: Scalars['String'];
  shortName: Scalars['String'];
  availableLangs: Array<Scalars['String']>;
  priceTier: Scalars['Int'];
};

export enum ItemType {
  Diag = 'DIAG',
  Report = 'REPORT'
}

export enum JoinMethod {
  Public = 'PUBLIC',
  InHouse = 'IN_HOUSE',
  Redeem = 'REDEEM'
}

export type LoginPayload = {
  __typename?: 'LoginPayload';
  username?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  exp: Scalars['Int'];
};

export type Manager = {
  __typename?: 'Manager';
  id: Scalars['ID'];
  email: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  groupId: Scalars['String'];
  authority: Scalars['String'];
  regionCode: Scalars['String'];
  roles: Array<Role>;
  needsChangePasswd: Scalars['Boolean'];
  lastChangePasswdAt?: Maybe<Scalars['DateTime']>;
  lastAccessAt?: Maybe<Scalars['DateTime']>;
  /** 생성시에만 응답됨 */
  rawPasswd?: Maybe<Scalars['String']>;
};

export type ManagerLoginPayload = {
  __typename?: 'ManagerLoginPayload';
  username?: Maybe<Scalars['String']>;
  authority?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  exp: Scalars['Int'];
};

export type ModifyGroupInput = {
  groupId: Scalars['Int'];
  groupName: Scalars['String'];
  regionCode: Scalars['String'];
  assessments?: Maybe<Array<Maybe<GroupAssessmentInput>>>;
};

export type ModifyOrderInput = {
  orderId: Scalars['Int'];
  itemId: Scalars['String'];
  qty: Scalars['Int'];
  paymentMethod?: Maybe<Scalars['String']>;
  unitValue: Scalars['Int'];
  supplyValue: Scalars['Int'];
  tax: Scalars['Int'];
  totalAmount: Scalars['Int'];
};

export type ModifyPaperReceiveEmailInput = {
  paperId: Scalars['Int'];
  email: Scalars['String'];
};

export type ModifyUserInput = {
  uid: Scalars['Int'];
  email: Scalars['String'];
  username: Scalars['String'];
  authority: Scalars['String'];
  regionCode: Scalars['String'];
  groupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSession?: Maybe<Session>;
  authByCode: LoginPayload;
  /** ECode에 사전정의 프로필을 입력합니다. IN_HOUSE 방식만 가능 */
  batchUpdateECodeProfile: Scalars['Int'];
  cancelOrder?: Maybe<Scalars['Boolean']>;
  confirmOrder?: Maybe<Scalars['Boolean']>;
  createEcodes: Scalars['Boolean'];
  createEmptyEcode: Scalars['Boolean'];
  /** 그룹 관리 */
  createGroup?: Maybe<Group>;
  /** 주문 관리 */
  createOrder?: Maybe<Scalars['Int']>;
  /** 진단지 관리 */
  createPapers?: Maybe<Scalars['Int']>;
  /** Session과 참가자수에 맞는 ECode를 생성합니다 */
  createSession: Scalars['Boolean'];
  /** 사용자 관리 */
  createUser?: Maybe<User>;
  deleteEcode: Scalars['Boolean'];
  deleteEcodes: Scalars['Boolean'];
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deletePapers?: Maybe<Scalars['Int']>;
  /** 세션 관리 */
  deleteSession: Scalars['Boolean'];
  deleteUser?: Maybe<Scalars['Boolean']>;
  dinoProcessResponse: DPaper;
  /** 진단을 종료합니다 */
  finalizeSession?: Maybe<Session>;
  generateReport: Scalars['String'];
  joinByCode: LoginPayload;
  managerChangePasswd: Manager;
  managerChangePasswdByToken: Scalars['Boolean'];
  managerLogin: ManagerLoginPayload;
  managerResetPasswd: Scalars['Boolean'];
  modifyGroup?: Maybe<Group>;
  modifyOrder?: Maybe<Scalars['Boolean']>;
  modifyPaperReceiveEmail?: Maybe<Scalars['Boolean']>;
  modifySessionTitle: Scalars['Boolean'];
  modifyUser?: Maybe<User>;
  restoreSession: Scalars['Boolean'];
  sendEmailReport: Scalars['Int'];
  sendReportToEmail: Scalars['Boolean'];
  sendReportToEmailAll: Scalars['Boolean'];
  sendSmsByEcode?: Maybe<Scalars['Boolean']>;
  sendSmsBySession?: Maybe<Scalars['Boolean']>;
  /** 진단을 시작합니다 */
  startSession?: Maybe<Session>;
  submitAnswers: Paper;
  updateECodes: Array<ECode>;
  updateEcode: Scalars['Boolean'];
  updateLeader: Scalars['Boolean'];
};


export type MutationActivateSessionArgs = {
  sessId: Scalars['ID'];
};


export type MutationAuthByCodeArgs = {
  code: Scalars['String'];
};


export type MutationBatchUpdateECodeProfileArgs = {
  sessId: Scalars['ID'];
  profiles: Array<UploadProfileInput>;
};


export type MutationCancelOrderArgs = {
  orderId: Scalars['Int'];
};


export type MutationConfirmOrderArgs = {
  orderIds: Array<Scalars['Int']>;
};


export type MutationCreateEcodesArgs = {
  sessId: Scalars['ID'];
  profiles: Array<CodeProfileInput>;
};


export type MutationCreateEmptyEcodeArgs = {
  sessId: Scalars['ID'];
  leaderEcId?: Maybe<Scalars['Int']>;
};


export type MutationCreateGroupArgs = {
  group: CreateGroupInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreatePapersArgs = {
  input: CreatePapersInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationCreateUserArgs = {
  userInput: CreateUserInput;
};


export type MutationDeleteEcodeArgs = {
  ecId: Scalars['ID'];
  forceDelete?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteEcodesArgs = {
  ecIds: Array<Scalars['ID']>;
  forceDelete?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['Int'];
};


export type MutationDeletePapersArgs = {
  input: CreatePapersInput;
};


export type MutationDeleteSessionArgs = {
  sessionIds: Array<Scalars['Int']>;
};


export type MutationDeleteUserArgs = {
  uid: Scalars['Int'];
};


export type MutationDinoProcessResponseArgs = {
  paperId: Scalars['ID'];
  answers: Array<Scalars['String']>;
};


export type MutationFinalizeSessionArgs = {
  sessId: Scalars['ID'];
};


export type MutationGenerateReportArgs = {
  paperId: Scalars['ID'];
  lang: Scalars['String'];
};


export type MutationJoinByCodeArgs = {
  code: Scalars['ID'];
  profile: CodeProfileInput;
  isAgreeEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationManagerChangePasswdArgs = {
  passwd: Scalars['String'];
  newPasswd: Scalars['String'];
};


export type MutationManagerChangePasswdByTokenArgs = {
  token: Scalars['String'];
  newPasswd: Scalars['String'];
};


export type MutationManagerLoginArgs = {
  email: Scalars['String'];
  passwd: Scalars['String'];
};


export type MutationManagerResetPasswdArgs = {
  email: Scalars['String'];
};


export type MutationModifyGroupArgs = {
  group: ModifyGroupInput;
};


export type MutationModifyOrderArgs = {
  input: ModifyOrderInput;
};


export type MutationModifyPaperReceiveEmailArgs = {
  input?: Maybe<ModifyPaperReceiveEmailInput>;
};


export type MutationModifySessionTitleArgs = {
  sessionId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationModifyUserArgs = {
  userInput: ModifyUserInput;
};


export type MutationRestoreSessionArgs = {
  sessionIds: Array<Scalars['Int']>;
};


export type MutationSendEmailReportArgs = {
  sessId: Scalars['ID'];
};


export type MutationSendReportToEmailArgs = {
  paperId: Scalars['ID'];
  lang: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendReportToEmailAllArgs = {
  sessId: Scalars['ID'];
  lang: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationSendSmsByEcodeArgs = {
  ecodeIds: Array<Scalars['Int']>;
};


export type MutationSendSmsBySessionArgs = {
  sessionId: Scalars['Int'];
};


export type MutationStartSessionArgs = {
  sessId: Scalars['ID'];
};


export type MutationSubmitAnswersArgs = {
  paperId: Scalars['ID'];
  answers: Array<Scalars['String']>;
};


export type MutationUpdateECodesArgs = {
  sessId: Scalars['ID'];
  data: Array<ECodeInput>;
};


export type MutationUpdateEcodeArgs = {
  sessId: Scalars['ID'];
  ecId: Scalars['ID'];
  changed?: Maybe<EcodeField>;
};


export type MutationUpdateLeaderArgs = {
  sess_id: Scalars['ID'];
  ec_id: Scalars['ID'];
  is_leader?: Maybe<Scalars['Boolean']>;
};

export type NewECode = {
  __typename?: 'NewECode';
  ecId: Scalars['Int'];
  sessionId: Scalars['Int'];
  ecode: Scalars['String'];
  rcode?: Maybe<Scalars['String']>;
  leaderEcId?: Maybe<Scalars['Int']>;
  isUsed: Scalars['Boolean'];
  usedAt?: Maybe<Scalars['DateTime']>;
  isPredef: Scalars['Boolean'];
  isLeader: Scalars['Boolean'];
  leaderName?: Maybe<Scalars['String']>;
  nid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
  status: ECodeStatus;
  studentId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  smsSentAt?: Maybe<Scalars['DateTime']>;
  assessors?: Maybe<Array<Maybe<NewECode>>>;
  deletedAssessors?: Maybe<Array<Maybe<NewECode>>>;
};

export type NewItem = {
  __typename?: 'NewItem';
  itemId: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type NewSession = {
  __typename?: 'NewSession';
  sessionId: Scalars['ID'];
  groupId: Scalars['ID'];
  status: SessionStatus;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  participantCnt: Scalars['Int'];
  joinMethod: JoinMethod;
  nidLabel: NidLabel;
  ecode: Scalars['String'];
  isLocked: Scalars['Boolean'];
  client?: Maybe<Scalars['String']>;
  trainer?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  writer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  group?: Maybe<Group>;
  paperCount: Scalars['Int'];
  itemIds?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<SessionItem>>>;
  ecodeFields?: Maybe<Array<Maybe<SessionECodeField>>>;
};

export enum NidLabel {
  Email = 'EMAIL',
  Name = 'NAME',
  StudentId = 'STUDENT_ID',
  EmployeeId = 'EMPLOYEE_ID',
  AccessCode = 'ACCESS_CODE',
  Number = 'NUMBER'
}

export type Order = {
  __typename?: 'Order';
  orderId: Scalars['Int'];
  itemId: Scalars['String'];
  status: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  qty: Scalars['Int'];
  unitValue: Scalars['Int'];
  supplyValue: Scalars['Int'];
  tax: Scalars['Int'];
  totalAmount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  group?: Maybe<Group>;
  user?: Maybe<User>;
  item?: Maybe<NewItem>;
};

export type PagedECode = {
  __typename?: 'PagedECode';
  total: Scalars['Int'];
  data: Array<ECode>;
};

export type PagedPaper = {
  __typename?: 'PagedPaper';
  total: Scalars['Int'];
  fields: Array<Scalars['String']>;
  data: Array<Paper>;
};

export type PagedPaperView = {
  __typename?: 'PagedPaperView';
  total: Scalars['Int'];
  fields?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<PaperViewItem>>;
};

export type PagedRedeemCode = {
  __typename?: 'PagedRedeemCode';
  total: Scalars['Int'];
  data: Array<RedeemCode>;
};

export type PaginatedOrders = {
  __typename?: 'PaginatedOrders';
  orders?: Maybe<Array<Maybe<Order>>>;
  totalCount: Scalars['Int'];
};

export type PaginatedSessions = {
  __typename?: 'PaginatedSessions';
  sessions?: Maybe<Array<Maybe<NewSession>>>;
  totalCount: Scalars['Int'];
};

export type Paper = {
  __typename?: 'Paper';
  id: Scalars['ID'];
  item: Item;
  status: Scalars['String'];
  tryCount: Scalars['Int'];
  results?: Maybe<Array<Scalars['String']>>;
};

/** 진단지 */
export type PaperSheet = {
  __typename?: 'PaperSheet';
  id: Scalars['ID'];
  item: Item;
  steps: Array<DiagStep>;
};

export type PaperStat = {
  __typename?: 'PaperStat';
  item: NewItem;
  paperTotal: Scalars['Int'];
  paperUsed: Scalars['Int'];
  paperAvailable: Scalars['Int'];
};

export enum PaperStatus {
  Ready = 'READY',
  Proceeding = 'PROCEEDING',
  Done = 'DONE',
  Deleted = 'DELETED'
}

/** 진단지 결과보기 */
export type PaperView = {
  __typename?: 'PaperView';
  id: Scalars['ID'];
  fields?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<PaperViewItem>>;
};

export type PaperViewItem = {
  __typename?: 'PaperViewItem';
  id: Scalars['ID'];
  status: Scalars['String'];
  nid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isLeader?: Maybe<Scalars['Boolean']>;
  validation?: Maybe<Scalars['Int']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  emailStatus?: Maybe<Scalars['String']>;
  emailSentDate?: Maybe<Scalars['DateTime']>;
  ecId?: Maybe<Scalars['Int']>;
  leaderEcId?: Maybe<Scalars['Int']>;
};

export enum ProfileField {
  Name = 'NAME',
  Email = 'EMAIL',
  StudentId = 'STUDENT_ID',
  EmployeeId = 'EMPLOYEE_ID',
  Number = 'NUMBER'
}

export type Query = {
  __typename?: 'Query';
  allGroups?: Maybe<Array<Maybe<Group>>>;
  allPaper?: Maybe<Array<Paper>>;
  authorities?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedEcodesBySession?: Maybe<Array<Maybe<NewECode>>>;
  deletedSessions?: Maybe<PaginatedSessions>;
  dinoAllPaper?: Maybe<Array<DPaper>>;
  dinoLoadPaper: DinoPaper;
  dinoPaper?: Maybe<DPaper>;
  dinoPapers?: Maybe<Array<DPaper>>;
  ecodeByPaper: NewECode;
  ecodesBySession?: Maybe<Array<Maybe<NewECode>>>;
  /** ECode 또는 RCode를 이용한 세션 조회 */
  entryCheck: EntryCheck;
  groupCount: Scalars['Int'];
  groups?: Maybe<Array<Maybe<Group>>>;
  items?: Maybe<Array<Maybe<NewItem>>>;
  manager?: Maybe<Manager>;
  orderCount: Scalars['Int'];
  orders?: Maybe<PaginatedOrders>;
  paper?: Maybe<Paper>;
  paperSheet: PaperSheet;
  paperStatus?: Maybe<Array<PaperStat>>;
  paperViewsBySession: PagedPaperView;
  regions?: Maybe<Array<Maybe<Region>>>;
  /** ------------- New Graphql ------------- */
  session?: Maybe<NewSession>;
  sessions?: Maybe<PaginatedSessions>;
  userCount: Scalars['Int'];
  userTokenCheck?: Maybe<TokenCheckPayload>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAllGroupsArgs = {
  regionCode?: Maybe<Scalars['String']>;
};


export type QueryDeletedEcodesBySessionArgs = {
  sessionId: Scalars['Int'];
};


export type QueryDeletedSessionsArgs = {
  criteria: GetSessionsCriteria;
};


export type QueryDinoLoadPaperArgs = {
  paperId: Scalars['ID'];
  lang: Scalars['String'];
};


export type QueryDinoPaperArgs = {
  paperId: Scalars['ID'];
};


export type QueryDinoPapersArgs = {
  filter?: Maybe<DinoPapersFilter>;
};


export type QueryEcodeByPaperArgs = {
  paperId: Scalars['Int'];
};


export type QueryEcodesBySessionArgs = {
  sessionId: Scalars['Int'];
};


export type QueryEntryCheckArgs = {
  code: Scalars['String'];
};


export type QueryGroupCountArgs = {
  criteria: GetGroupsCriteria;
};


export type QueryGroupsArgs = {
  criteria: GetGroupsCriteria;
};


export type QueryManagerArgs = {
  id: Scalars['ID'];
};


export type QueryOrderCountArgs = {
  criteria: GetOrdersCriteria;
};


export type QueryOrdersArgs = {
  criteria: GetOrdersCriteria;
};


export type QueryPaperArgs = {
  paperId: Scalars['ID'];
};


export type QueryPaperSheetArgs = {
  paperId: Scalars['ID'];
  lang: Scalars['String'];
};


export type QueryPaperStatusArgs = {
  groupId?: Maybe<Scalars['Int']>;
};


export type QueryPaperViewsBySessionArgs = {
  sessId: Scalars['ID'];
  itemId: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySessionArgs = {
  sessionId: Scalars['Int'];
};


export type QuerySessionsArgs = {
  criteria: GetSessionsCriteria;
};


export type QueryUserCountArgs = {
  criteria: GetUsersCriteria;
};


export type QueryUserTokenCheckArgs = {
  token: Scalars['String'];
  type: TokenCheckType;
};


export type QueryUsersArgs = {
  criteria: GetUsersCriteria;
};

export type RedeemCode = {
  __typename?: 'RedeemCode';
  id: Scalars['ID'];
  code: Scalars['String'];
  regDt: Scalars['DateTime'];
  regId: Scalars['String'];
  isUsed: Scalars['Boolean'];
};

/** ------------- New Graphql ------------- */
export type Region = {
  __typename?: 'Region';
  regionCode?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
};

export enum Role {
  Root = 'ROOT',
  Admin = 'ADMIN'
}

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  status: SessionStatus;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  orgNm?: Maybe<Scalars['String']>;
  participantCnt: Scalars['Int'];
  joinMethod: JoinMethod;
  nidLabel: NidLabel;
  ecode: Scalars['String'];
  ecodeFields?: Maybe<Array<Maybe<ECodeField>>>;
  ecodes?: Maybe<Array<ECode>>;
  items: Array<Item>;
  isLocked: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paper: Scalars['Int'];
  client?: Maybe<Scalars['String']>;
  trainer?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  writer?: Maybe<Scalars['String']>;
  item?: Maybe<Scalars['String']>;
};

export type SessionECodeField = {
  __typename?: 'SessionECodeField';
  sessionId: Scalars['ID'];
  orderNo: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type SessionItem = {
  __typename?: 'SessionItem';
  sessionId: Scalars['ID'];
  orderNo: Scalars['Int'];
  itemId: Scalars['String'];
  item: NewItem;
};

export enum SessionStatus {
  Prepared = 'PREPARED',
  Proceeding = 'PROCEEDING',
  Done = 'DONE',
  Deleted = 'DELETED'
}

export type TokenCheckPayload = {
  __typename?: 'TokenCheckPayload';
  email: Scalars['String'];
};

export enum TokenCheckType {
  ChangePwd = 'CHANGE_PWD'
}

export type UploadProfileInput = {
  ecId: Scalars['Int'];
  nid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  uid?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  username: Scalars['String'];
  authority: Scalars['String'];
  regionCode: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Group>>>;
  needsChangePasswd: Scalars['Boolean'];
  lastChangePasswdAt?: Maybe<Scalars['DateTime']>;
  lastAccessAt?: Maybe<Scalars['DateTime']>;
};

export type SessionAuthPartsFragment = (
  { __typename?: 'Session' }
  & Pick<Session, 'id' | 'title' | 'orgNm' | 'joinMethod'>
  & { ecodeFields?: Maybe<Array<Maybe<(
    { __typename?: 'ECodeField' }
    & Pick<ECodeField, 'id' | 'label' | 'field'>
  )>>> }
);

export type EntryCheckQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type EntryCheckQuery = (
  { __typename?: 'Query' }
  & { entryCheck: (
    { __typename?: 'EntryCheck' }
    & Pick<EntryCheck, 'id' | 'ecode' | 'isUsed' | 'codeType'>
    & { session: (
      { __typename?: 'Session' }
      & SessionAuthPartsFragment
    ) }
  ) }
);

export type AuthByCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type AuthByCodeMutation = (
  { __typename?: 'Mutation' }
  & { authByCode: (
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'exp' | 'token' | 'username'>
  ) }
);

export type JoinByCodeMutationVariables = Exact<{
  code: Scalars['ID'];
  profile: CodeProfileInput;
  isAgreeEmail?: Maybe<Scalars['Boolean']>;
}>;


export type JoinByCodeMutation = (
  { __typename?: 'Mutation' }
  & { joinByCode: (
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'username' | 'token' | 'exp'>
  ) }
);

export type MyPapersQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPapersQuery = (
  { __typename?: 'Query' }
  & { allPaper?: Maybe<Array<(
    { __typename?: 'Paper' }
    & Pick<Paper, 'id' | 'status' | 'tryCount' | 'results'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'type' | 'name' | 'shortName'>
    ) }
  )>> }
);

export type PaperQueryVariables = Exact<{
  paperId: Scalars['ID'];
}>;


export type PaperQuery = (
  { __typename?: 'Query' }
  & { paper?: Maybe<(
    { __typename?: 'Paper' }
    & Pick<Paper, 'id' | 'results' | 'status'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'name' | 'shortName' | 'type'>
    ) }
  )> }
);

export type PaperSheetQueryVariables = Exact<{
  paperId: Scalars['ID'];
  lang: Scalars['String'];
}>;


export type PaperSheetQuery = (
  { __typename?: 'Query' }
  & { paperSheet: (
    { __typename?: 'PaperSheet' }
    & Pick<PaperSheet, 'id'>
    & { steps: Array<(
      { __typename?: 'DiagStep' }
      & Pick<DiagStep, 'type' | 'group'>
      & { res?: Maybe<Array<(
        { __typename?: 'DiagStepRes' }
        & Pick<DiagStepRes, 'id' | 'desc'>
      )>> }
    )> }
  ) }
);

export type IntroPartsFragment = (
  { __typename?: 'DinoContentIntro' }
  & Pick<DinoContentIntro, 'id' | 'texts'>
);

export type ItemPartsFragment = (
  { __typename?: 'DinoContentItem' }
  & Pick<DinoContentItem, 'id' | 'texts'>
  & { values?: Maybe<Array<(
    { __typename?: 'DinoContentItemValue' }
    & Pick<DinoContentItemValue, 'key' | 'text'>
  )>> }
);

export type DinoMyPapersQueryVariables = Exact<{ [key: string]: never; }>;


export type DinoMyPapersQuery = (
  { __typename?: 'Query' }
  & { dinoAllPaper?: Maybe<Array<(
    { __typename?: 'DPaper' }
    & DPaperPartsFragment
  )>> }
);

export type DinoLoadPaperQueryVariables = Exact<{
  paperId: Scalars['ID'];
  lang: Scalars['String'];
}>;


export type DinoLoadPaperQuery = (
  { __typename?: 'Query' }
  & { dinoLoadPaper: (
    { __typename?: 'DinoPaper' }
    & Pick<DinoPaper, 'title' | 'subtitle'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'DinoContent' }
      & DinoContentPartsFragment
    )>>> }
  ) }
);

export type DinoContentPartsFragment = (
  { __typename?: 'DinoContent' }
  & Pick<DinoContent, 'id' | 'itemView'>
  & { intro?: Maybe<(
    { __typename?: 'DinoContentIntro' }
    & IntroPartsFragment
  )>, items?: Maybe<Array<(
    { __typename?: 'DinoContentItem' }
    & ItemPartsFragment
  )>> }
);

export type PaperPartsFragment = (
  { __typename?: 'Paper' }
  & Pick<Paper, 'id' | 'status' | 'results'>
  & { item: (
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'type' | 'name' | 'shortName'>
  ) }
);

export type DPaperPartsFragment = (
  { __typename?: 'DPaper' }
  & Pick<DPaper, 'id' | 'statusCd' | 'results'>
  & { item: (
    { __typename?: 'DItem' }
    & Pick<DItem, 'id' | 'title' | 'subtitle' | 'colorPallete' | 'owner'>
  ) }
);

export type SubmitAnswersMutationVariables = Exact<{
  paperId: Scalars['ID'];
  answers: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubmitAnswersMutation = (
  { __typename?: 'Mutation' }
  & { submitAnswers: (
    { __typename?: 'Paper' }
    & PaperPartsFragment
  ) }
);

export type DinoAllPaperQueryVariables = Exact<{ [key: string]: never; }>;


export type DinoAllPaperQuery = (
  { __typename?: 'Query' }
  & { dinoAllPaper?: Maybe<Array<(
    { __typename?: 'DPaper' }
    & DPaperPartsFragment
  )>> }
);

export type DinoPaperQueryVariables = Exact<{
  paperId: Scalars['ID'];
}>;


export type DinoPaperQuery = (
  { __typename?: 'Query' }
  & { dinoPaper?: Maybe<(
    { __typename?: 'DPaper' }
    & DPaperPartsFragment
  )> }
);

export type DinoProcessResponseMutationVariables = Exact<{
  paperId: Scalars['ID'];
  answers: Array<Scalars['String']> | Scalars['String'];
}>;


export type DinoProcessResponseMutation = (
  { __typename?: 'Mutation' }
  & { dinoProcessResponse: (
    { __typename?: 'DPaper' }
    & DPaperPartsFragment
  ) }
);

export type GetResultQueryVariables = Exact<{
  paperId: Scalars['ID'];
}>;


export type GetResultQuery = (
  { __typename?: 'Query' }
  & { paper?: Maybe<(
    { __typename?: 'Paper' }
    & PaperPartsFragment
  )> }
);

export type EcodeByPaperQueryVariables = Exact<{
  paperId: Scalars['Int'];
}>;


export type EcodeByPaperQuery = (
  { __typename?: 'Query' }
  & { ecodeByPaper: (
    { __typename?: 'NewECode' }
    & Pick<NewECode, 'isLeader' | 'leaderName'>
  ) }
);

export type SendReportToEmailMutationVariables = Exact<{
  paperId: Scalars['ID'];
  lang: Scalars['String'];
  email: Scalars['String'];
}>;


export type SendReportToEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendReportToEmail'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  passwd: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { managerLogin: (
    { __typename?: 'ManagerLoginPayload' }
    & Pick<ManagerLoginPayload, 'username' | 'authority' | 'regionCode' | 'token' | 'exp'>
  ) }
);

export type ManagerResetPasswdMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ManagerResetPasswdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'managerResetPasswd'>
);

export type ManagerChangePasswdMutationVariables = Exact<{
  passwd: Scalars['String'];
  newPasswd: Scalars['String'];
}>;


export type ManagerChangePasswdMutation = (
  { __typename?: 'Mutation' }
  & { managerChangePasswd: (
    { __typename?: 'Manager' }
    & Pick<Manager, 'id'>
  ) }
);

export type ManagerChangePasswdByTokenMutationVariables = Exact<{
  token: Scalars['String'];
  newPasswd: Scalars['String'];
}>;


export type ManagerChangePasswdByTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'managerChangePasswdByToken'>
);

export type UserTokenCheckQueryVariables = Exact<{
  token: Scalars['String'];
  type: TokenCheckType;
}>;


export type UserTokenCheckQuery = (
  { __typename?: 'Query' }
  & { userTokenCheck?: Maybe<(
    { __typename?: 'TokenCheckPayload' }
    & Pick<TokenCheckPayload, 'email'>
  )> }
);

export type ECodePartsFragment = (
  { __typename?: 'ECode' }
  & Pick<ECode, 'id' | 'sessId' | 'rcode' | 'isUsed' | 'usedAt' | 'isPredefined' | 'isLeader' | 'nid' | 'name' | 'email' | 'dept' | 'status' | 'studentId' | 'employeeId' | 'number'>
);

export type NewECodePartsFragment = (
  { __typename?: 'NewECode' }
  & Pick<NewECode, 'ecId' | 'sessionId' | 'leaderEcId' | 'rcode' | 'isUsed' | 'usedAt' | 'isPredef' | 'isLeader' | 'nid' | 'name' | 'email' | 'dept' | 'status' | 'studentId' | 'employeeId' | 'number' | 'phone' | 'smsSentAt'>
);

export type SessionPartsFragment = (
  { __typename?: 'NewSession' }
  & Pick<NewSession, 'sessionId' | 'status' | 'title' | 'description' | 'orgName' | 'participantCnt' | 'joinMethod' | 'nidLabel' | 'isLocked' | 'ecode'>
  & { ecodeFields?: Maybe<Array<Maybe<(
    { __typename?: 'SessionECodeField' }
    & Pick<SessionECodeField, 'sessionId' | 'orderNo' | 'label' | 'field'>
  )>>>, items?: Maybe<Array<Maybe<(
    { __typename?: 'SessionItem' }
    & { item: (
      { __typename?: 'NewItem' }
      & Pick<NewItem, 'itemId' | 'name' | 'shortName'>
    ) }
  )>>> }
);

export type SessionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SessionQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'NewSession' }
    & SessionPartsFragment
  )> }
);

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;


export type CreateSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSession'>
);

export type StartSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StartSessionMutation = (
  { __typename?: 'Mutation' }
  & { startSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'status'>
  )> }
);

export type ActivateSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateSessionMutation = (
  { __typename?: 'Mutation' }
  & { activateSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'status'>
  )> }
);

export type FinalizeSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FinalizeSessionMutation = (
  { __typename?: 'Mutation' }
  & { finalizeSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'status'>
  )> }
);

export type BatchUpdateECodeProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  profiles: Array<UploadProfileInput> | UploadProfileInput;
}>;


export type BatchUpdateECodeProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'batchUpdateECodeProfile'>
);

export type UploadProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  profiles: Array<UploadProfileInput> | UploadProfileInput;
}>;


export type UploadProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'batchUpdateECodeProfile'>
);

export type EcodesBySessionQueryVariables = Exact<{
  sessionId: Scalars['Int'];
}>;


export type EcodesBySessionQuery = (
  { __typename?: 'Query' }
  & { ecodesBySession?: Maybe<Array<Maybe<(
    { __typename?: 'NewECode' }
    & { assessors?: Maybe<Array<Maybe<(
      { __typename?: 'NewECode' }
      & NewECodePartsFragment
    )>>> }
    & NewECodePartsFragment
  )>>> }
);

export type DeletedEcodesBySessionQueryVariables = Exact<{
  sessionId: Scalars['Int'];
}>;


export type DeletedEcodesBySessionQuery = (
  { __typename?: 'Query' }
  & { deletedEcodesBySession?: Maybe<Array<Maybe<(
    { __typename?: 'NewECode' }
    & { deletedAssessors?: Maybe<Array<Maybe<(
      { __typename?: 'NewECode' }
      & NewECodePartsFragment
    )>>> }
    & NewECodePartsFragment
  )>>> }
);

export type PaperViewsBySessionQueryVariables = Exact<{
  sessId: Scalars['ID'];
  itemId: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PaperViewsBySessionQuery = (
  { __typename?: 'Query' }
  & { paperViewsBySession: (
    { __typename?: 'PagedPaperView' }
    & Pick<PagedPaperView, 'total' | 'fields'>
    & { results?: Maybe<Array<(
      { __typename?: 'PaperViewItem' }
      & Pick<PaperViewItem, 'id' | 'status' | 'nid' | 'name' | 'validation' | 'values' | 'email' | 'emailStatus' | 'emailSentDate' | 'ecId' | 'leaderEcId'>
    )>> }
  ) }
);

export type DinoPapersByEcIdQueryVariables = Exact<{
  ecId: Scalars['Int'];
}>;


export type DinoPapersByEcIdQuery = (
  { __typename?: 'Query' }
  & { dinoPapers?: Maybe<Array<(
    { __typename?: 'DPaper' }
    & Pick<DPaper, 'id' | 'statusCd' | 'results'>
    & { item: (
      { __typename?: 'DItem' }
      & Pick<DItem, 'id' | 'title' | 'subtitle'>
    ) }
  )>> }
);

export type GenerateReportMutationVariables = Exact<{
  paperId: Scalars['ID'];
  lang: Scalars['String'];
}>;


export type GenerateReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateReport'>
);

export type UpdateLeaderMutationVariables = Exact<{
  id: Scalars['ID'];
  sessId: Scalars['ID'];
  isLeader: Scalars['Boolean'];
}>;


export type UpdateLeaderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLeader'>
);

export type DeleteSessionMutationVariables = Exact<{
  sessionIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSession'>
);

export type ModifySessionTitleMutationVariables = Exact<{
  sessionId: Scalars['Int'];
  title: Scalars['String'];
}>;


export type ModifySessionTitleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'modifySessionTitle'>
);

export type RestoreSessionMutationVariables = Exact<{
  sessionIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RestoreSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreSession'>
);

export type CreateEmptyEcodeMutationVariables = Exact<{
  sessId: Scalars['ID'];
  leaderEcId?: Maybe<Scalars['Int']>;
}>;


export type CreateEmptyEcodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEmptyEcode'>
);

export type CreateEcodesMutationVariables = Exact<{
  sessId: Scalars['ID'];
  profiles: Array<CodeProfileInput> | CodeProfileInput;
}>;


export type CreateEcodesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEcodes'>
);

export type UpdateEcodeMutationVariables = Exact<{
  sessId: Scalars['ID'];
  ecId: Scalars['ID'];
  changed?: Maybe<EcodeField>;
}>;


export type UpdateEcodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEcode'>
);

export type DeleteEcodeMutationVariables = Exact<{
  ecId: Scalars['ID'];
  forceDelete?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteEcodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEcode'>
);

export type DeleteEcodesMutationVariables = Exact<{
  ecIds: Array<Scalars['ID']> | Scalars['ID'];
  forceDelete?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteEcodesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEcodes'>
);

export type SendReportToEmailAllMutationVariables = Exact<{
  sessId: Scalars['ID'];
  lang: Scalars['String'];
  itemId: Scalars['String'];
}>;


export type SendReportToEmailAllMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendReportToEmailAll'>
);

export type RegionPartFragment = (
  { __typename?: 'Region' }
  & Pick<Region, 'regionCode' | 'regionName'>
);

export type NewItemPartFragment = (
  { __typename?: 'NewItem' }
  & Pick<NewItem, 'itemId' | 'name' | 'shortName'>
);

export type GroupsQueryVariables = Exact<{
  criteria: GetGroupsCriteria;
}>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'groupCount'>
  & { groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupId' | 'groupName' | 'regionCode' | 'updatedAt' | 'managerNames'>
    & { region?: Maybe<(
      { __typename?: 'Region' }
      & RegionPartFragment
    )>, assessments?: Maybe<Array<Maybe<(
      { __typename?: 'GroupAssessment' }
      & Pick<GroupAssessment, 'assessment'>
    )>>>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>>> }
  )>>>, regions?: Maybe<Array<Maybe<(
    { __typename?: 'Region' }
    & RegionPartFragment
  )>>>, items?: Maybe<Array<Maybe<(
    { __typename?: 'NewItem' }
    & NewItemPartFragment
  )>>> }
);

export type CreateGroupMutationVariables = Exact<{
  group: CreateGroupInput;
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupName'>
  )> }
);

export type ModifyGroupMutationVariables = Exact<{
  group: ModifyGroupInput;
}>;


export type ModifyGroupMutation = (
  { __typename?: 'Mutation' }
  & { modifyGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupId'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['Int'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGroup'>
);

export type CreateUserMutationVariables = Exact<{
  userInput: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'uid'>
  )> }
);

export type ModifyUserMutationVariables = Exact<{
  userInput: ModifyUserInput;
}>;


export type ModifyUserMutation = (
  { __typename?: 'Mutation' }
  & { modifyUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'uid'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  uid: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type UserPartFragment = (
  { __typename?: 'User' }
  & Pick<User, 'uid' | 'email' | 'username' | 'authority' | 'regionCode' | 'needsChangePasswd' | 'lastChangePasswdAt' | 'lastAccessAt'>
  & { groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupId' | 'groupName'>
  )>>> }
);

export type UsersQueryVariables = Exact<{
  criteria: GetUsersCriteria;
  regionCode?: Maybe<Scalars['String']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userCount' | 'authorities'>
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & UserPartFragment
  )>>>, regions?: Maybe<Array<Maybe<(
    { __typename?: 'Region' }
    & RegionPartFragment
  )>>>, allGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupId' | 'groupName'>
  )>>> }
);

export type PaperStatusQueryVariables = Exact<{
  groupId?: Maybe<Scalars['Int']>;
}>;


export type PaperStatusQuery = (
  { __typename?: 'Query' }
  & { paperStatus?: Maybe<Array<(
    { __typename?: 'PaperStat' }
    & Pick<PaperStat, 'paperTotal' | 'paperUsed' | 'paperAvailable'>
    & { item: (
      { __typename?: 'NewItem' }
      & Pick<NewItem, 'itemId' | 'name' | 'shortName'>
    ) }
  )>> }
);

export type CreatePapersMutationVariables = Exact<{
  input: CreatePapersInput;
}>;


export type CreatePapersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPapers'>
);

export type DeletePapersMutationVariables = Exact<{
  input: CreatePapersInput;
}>;


export type DeletePapersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePapers'>
);

export type ModifyPaperReceiveEmailMutationVariables = Exact<{
  input: ModifyPaperReceiveEmailInput;
}>;


export type ModifyPaperReceiveEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'modifyPaperReceiveEmail'>
);

export type LayoutMetasQueryVariables = Exact<{
  regionCode: Scalars['String'];
  criteria: GetOrdersCriteria;
}>;


export type LayoutMetasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'orderCount'>
  & { allGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupId' | 'groupName'>
  )>>>, regions?: Maybe<Array<Maybe<(
    { __typename?: 'Region' }
    & RegionPartFragment
  )>>> }
);

export type OrdersQueryVariables = Exact<{
  criteria: GetOrdersCriteria;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<(
    { __typename?: 'PaginatedOrders' }
    & Pick<PaginatedOrders, 'totalCount'>
    & { orders?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'orderId' | 'itemId' | 'status' | 'paymentMethod' | 'qty' | 'unitValue' | 'supplyValue' | 'tax' | 'totalAmount' | 'createdAt' | 'updatedAt'>
      & { group?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'groupName'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, item?: Maybe<(
        { __typename?: 'NewItem' }
        & Pick<NewItem, 'name' | 'shortName'>
      )> }
    )>>> }
  )>, items?: Maybe<Array<Maybe<(
    { __typename?: 'NewItem' }
    & NewItemPartFragment
  )>>> }
);

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrder'>
);

export type ModifyOrderMutationVariables = Exact<{
  input: ModifyOrderInput;
}>;


export type ModifyOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'modifyOrder'>
);

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CancelOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelOrder'>
);

export type ConfirmOrderMutationVariables = Exact<{
  orderIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ConfirmOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmOrder'>
);

export type SessionEditorQueryVariables = Exact<{
  regionCode: Scalars['String'];
}>;


export type SessionEditorQuery = (
  { __typename?: 'Query' }
  & { allGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'groupId' | 'groupName'>
    & { assessments?: Maybe<Array<Maybe<(
      { __typename?: 'GroupAssessment' }
      & Pick<GroupAssessment, 'assessment'>
      & { item?: Maybe<(
        { __typename?: 'NewItem' }
        & NewItemPartFragment
      )> }
    )>>> }
  )>>>, items?: Maybe<Array<Maybe<(
    { __typename?: 'NewItem' }
    & NewItemPartFragment
  )>>> }
);

export type SessionsQueryVariables = Exact<{
  criteria: GetSessionsCriteria;
}>;


export type SessionsQuery = (
  { __typename?: 'Query' }
  & { sessions?: Maybe<(
    { __typename?: 'PaginatedSessions' }
    & Pick<PaginatedSessions, 'totalCount'>
    & { sessions?: Maybe<Array<Maybe<(
      { __typename?: 'NewSession' }
      & Pick<NewSession, 'sessionId' | 'groupId' | 'title' | 'status' | 'joinMethod' | 'ecode' | 'client' | 'trainer' | 'date' | 'writer' | 'createdAt' | 'paperCount' | 'itemIds'>
      & { group?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'groupName'>
      )> }
    )>>> }
  )> }
);

export type DeletedSessionsQueryVariables = Exact<{
  criteria: GetSessionsCriteria;
}>;


export type DeletedSessionsQuery = (
  { __typename?: 'Query' }
  & { deletedSessions?: Maybe<(
    { __typename?: 'PaginatedSessions' }
    & Pick<PaginatedSessions, 'totalCount'>
    & { sessions?: Maybe<Array<Maybe<(
      { __typename?: 'NewSession' }
      & Pick<NewSession, 'sessionId' | 'groupId' | 'title' | 'status' | 'joinMethod' | 'ecode' | 'client' | 'trainer' | 'date' | 'writer' | 'createdAt' | 'paperCount' | 'itemIds'>
      & { group?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'groupName'>
      )> }
    )>>> }
  )> }
);

export type SendSmsByEcodeMutationVariables = Exact<{
  ecodeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SendSmsByEcodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendSmsByEcode'>
);

export type SendSmsBySessionMutationVariables = Exact<{
  sessionId: Scalars['Int'];
}>;


export type SendSmsBySessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendSmsBySession'>
);

export const SessionAuthPartsFragmentDoc = gql`
    fragment SessionAuthParts on Session {
  id
  title
  orgNm
  joinMethod
  ecodeFields {
    id
    label
    field
  }
}
    `;
export const IntroPartsFragmentDoc = gql`
    fragment IntroParts on DinoContentIntro {
  id
  texts
}
    `;
export const ItemPartsFragmentDoc = gql`
    fragment ItemParts on DinoContentItem {
  id
  texts
  values {
    key
    text
  }
}
    `;
export const DinoContentPartsFragmentDoc = gql`
    fragment DinoContentParts on DinoContent {
  id
  intro {
    ...IntroParts
  }
  itemView
  items {
    ...ItemParts
  }
}
    ${IntroPartsFragmentDoc}
${ItemPartsFragmentDoc}`;
export const PaperPartsFragmentDoc = gql`
    fragment PaperParts on Paper {
  id
  item {
    id
    type
    name
    shortName
  }
  status
  results
}
    `;
export const DPaperPartsFragmentDoc = gql`
    fragment DPaperParts on DPaper {
  id
  item {
    id
    title
    subtitle
    colorPallete
    owner
  }
  statusCd
  results
}
    `;
export const ECodePartsFragmentDoc = gql`
    fragment ECodeParts on ECode {
  id
  sessId
  rcode
  isUsed
  usedAt
  isPredefined
  isLeader
  nid
  name
  email
  dept
  status
  studentId
  employeeId
  number
}
    `;
export const NewECodePartsFragmentDoc = gql`
    fragment NewECodeParts on NewECode {
  ecId
  sessionId
  leaderEcId
  rcode
  isUsed
  usedAt
  isPredef
  isLeader
  nid
  name
  email
  dept
  status
  studentId
  employeeId
  number
  phone
  smsSentAt
}
    `;
export const SessionPartsFragmentDoc = gql`
    fragment SessionParts on NewSession {
  sessionId
  status
  title
  description
  orgName
  participantCnt
  joinMethod
  nidLabel
  isLocked
  ecode
  ecodeFields {
    sessionId
    orderNo
    label
    field
  }
  items {
    item {
      itemId
      name
      shortName
    }
  }
}
    `;
export const RegionPartFragmentDoc = gql`
    fragment RegionPart on Region {
  regionCode
  regionName
}
    `;
export const NewItemPartFragmentDoc = gql`
    fragment NewItemPart on NewItem {
  itemId
  name
  shortName
}
    `;
export const UserPartFragmentDoc = gql`
    fragment UserPart on User {
  uid
  email
  username
  authority
  regionCode
  needsChangePasswd
  lastChangePasswdAt
  lastAccessAt
  groups {
    groupId
    groupName
  }
}
    `;
export const EntryCheckDocument = gql`
    query entryCheck($code: String!) {
  entryCheck(code: $code) {
    id
    ecode
    isUsed
    codeType
    session {
      ...SessionAuthParts
    }
  }
}
    ${SessionAuthPartsFragmentDoc}`;

/**
 * __useEntryCheckQuery__
 *
 * To run a query within a React component, call `useEntryCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryCheckQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEntryCheckQuery(baseOptions: Apollo.QueryHookOptions<EntryCheckQuery, EntryCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EntryCheckQuery, EntryCheckQueryVariables>(EntryCheckDocument, options);
      }
export function useEntryCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntryCheckQuery, EntryCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EntryCheckQuery, EntryCheckQueryVariables>(EntryCheckDocument, options);
        }
export type EntryCheckQueryHookResult = ReturnType<typeof useEntryCheckQuery>;
export type EntryCheckLazyQueryHookResult = ReturnType<typeof useEntryCheckLazyQuery>;
export type EntryCheckQueryResult = Apollo.QueryResult<EntryCheckQuery, EntryCheckQueryVariables>;
export const AuthByCodeDocument = gql`
    mutation authByCode($code: String!) {
  authByCode(code: $code) {
    exp
    token
    username
  }
}
    `;
export type AuthByCodeMutationFn = Apollo.MutationFunction<AuthByCodeMutation, AuthByCodeMutationVariables>;

/**
 * __useAuthByCodeMutation__
 *
 * To run a mutation, you first call `useAuthByCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthByCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authByCodeMutation, { data, loading, error }] = useAuthByCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthByCodeMutation(baseOptions?: Apollo.MutationHookOptions<AuthByCodeMutation, AuthByCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthByCodeMutation, AuthByCodeMutationVariables>(AuthByCodeDocument, options);
      }
export type AuthByCodeMutationHookResult = ReturnType<typeof useAuthByCodeMutation>;
export type AuthByCodeMutationResult = Apollo.MutationResult<AuthByCodeMutation>;
export type AuthByCodeMutationOptions = Apollo.BaseMutationOptions<AuthByCodeMutation, AuthByCodeMutationVariables>;
export const JoinByCodeDocument = gql`
    mutation joinByCode($code: ID!, $profile: CodeProfileInput!, $isAgreeEmail: Boolean) {
  joinByCode(code: $code, profile: $profile, isAgreeEmail: $isAgreeEmail) {
    username
    token
    exp
  }
}
    `;
export type JoinByCodeMutationFn = Apollo.MutationFunction<JoinByCodeMutation, JoinByCodeMutationVariables>;

/**
 * __useJoinByCodeMutation__
 *
 * To run a mutation, you first call `useJoinByCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinByCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinByCodeMutation, { data, loading, error }] = useJoinByCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      profile: // value for 'profile'
 *      isAgreeEmail: // value for 'isAgreeEmail'
 *   },
 * });
 */
export function useJoinByCodeMutation(baseOptions?: Apollo.MutationHookOptions<JoinByCodeMutation, JoinByCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinByCodeMutation, JoinByCodeMutationVariables>(JoinByCodeDocument, options);
      }
export type JoinByCodeMutationHookResult = ReturnType<typeof useJoinByCodeMutation>;
export type JoinByCodeMutationResult = Apollo.MutationResult<JoinByCodeMutation>;
export type JoinByCodeMutationOptions = Apollo.BaseMutationOptions<JoinByCodeMutation, JoinByCodeMutationVariables>;
export const MyPapersDocument = gql`
    query myPapers {
  allPaper {
    id
    item {
      id
      type
      name
      shortName
    }
    status
    tryCount
    results
  }
}
    `;

/**
 * __useMyPapersQuery__
 *
 * To run a query within a React component, call `useMyPapersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPapersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPapersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPapersQuery(baseOptions?: Apollo.QueryHookOptions<MyPapersQuery, MyPapersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyPapersQuery, MyPapersQueryVariables>(MyPapersDocument, options);
      }
export function useMyPapersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyPapersQuery, MyPapersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyPapersQuery, MyPapersQueryVariables>(MyPapersDocument, options);
        }
export type MyPapersQueryHookResult = ReturnType<typeof useMyPapersQuery>;
export type MyPapersLazyQueryHookResult = ReturnType<typeof useMyPapersLazyQuery>;
export type MyPapersQueryResult = Apollo.QueryResult<MyPapersQuery, MyPapersQueryVariables>;
export const PaperDocument = gql`
    query paper($paperId: ID!) {
  paper(paperId: $paperId) {
    id
    item {
      id
      name
      shortName
      type
    }
    results
    status
  }
}
    `;

/**
 * __usePaperQuery__
 *
 * To run a query within a React component, call `usePaperQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function usePaperQuery(baseOptions: Apollo.QueryHookOptions<PaperQuery, PaperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaperQuery, PaperQueryVariables>(PaperDocument, options);
      }
export function usePaperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaperQuery, PaperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaperQuery, PaperQueryVariables>(PaperDocument, options);
        }
export type PaperQueryHookResult = ReturnType<typeof usePaperQuery>;
export type PaperLazyQueryHookResult = ReturnType<typeof usePaperLazyQuery>;
export type PaperQueryResult = Apollo.QueryResult<PaperQuery, PaperQueryVariables>;
export const PaperSheetDocument = gql`
    query paperSheet($paperId: ID!, $lang: String!) {
  paperSheet(paperId: $paperId, lang: $lang) {
    id
    steps {
      type
      group
      res {
        id
        desc
      }
    }
  }
}
    `;

/**
 * __usePaperSheetQuery__
 *
 * To run a query within a React component, call `usePaperSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperSheetQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePaperSheetQuery(baseOptions: Apollo.QueryHookOptions<PaperSheetQuery, PaperSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaperSheetQuery, PaperSheetQueryVariables>(PaperSheetDocument, options);
      }
export function usePaperSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaperSheetQuery, PaperSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaperSheetQuery, PaperSheetQueryVariables>(PaperSheetDocument, options);
        }
export type PaperSheetQueryHookResult = ReturnType<typeof usePaperSheetQuery>;
export type PaperSheetLazyQueryHookResult = ReturnType<typeof usePaperSheetLazyQuery>;
export type PaperSheetQueryResult = Apollo.QueryResult<PaperSheetQuery, PaperSheetQueryVariables>;
export const DinoMyPapersDocument = gql`
    query dinoMyPapers {
  dinoAllPaper {
    ...DPaperParts
  }
}
    ${DPaperPartsFragmentDoc}`;

/**
 * __useDinoMyPapersQuery__
 *
 * To run a query within a React component, call `useDinoMyPapersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDinoMyPapersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDinoMyPapersQuery({
 *   variables: {
 *   },
 * });
 */
export function useDinoMyPapersQuery(baseOptions?: Apollo.QueryHookOptions<DinoMyPapersQuery, DinoMyPapersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DinoMyPapersQuery, DinoMyPapersQueryVariables>(DinoMyPapersDocument, options);
      }
export function useDinoMyPapersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DinoMyPapersQuery, DinoMyPapersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DinoMyPapersQuery, DinoMyPapersQueryVariables>(DinoMyPapersDocument, options);
        }
export type DinoMyPapersQueryHookResult = ReturnType<typeof useDinoMyPapersQuery>;
export type DinoMyPapersLazyQueryHookResult = ReturnType<typeof useDinoMyPapersLazyQuery>;
export type DinoMyPapersQueryResult = Apollo.QueryResult<DinoMyPapersQuery, DinoMyPapersQueryVariables>;
export const DinoLoadPaperDocument = gql`
    query dinoLoadPaper($paperId: ID!, $lang: String!) {
  dinoLoadPaper(paperId: $paperId, lang: $lang) {
    title
    subtitle
    content {
      ...DinoContentParts
    }
  }
}
    ${DinoContentPartsFragmentDoc}`;

/**
 * __useDinoLoadPaperQuery__
 *
 * To run a query within a React component, call `useDinoLoadPaperQuery` and pass it any options that fit your needs.
 * When your component renders, `useDinoLoadPaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDinoLoadPaperQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useDinoLoadPaperQuery(baseOptions: Apollo.QueryHookOptions<DinoLoadPaperQuery, DinoLoadPaperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DinoLoadPaperQuery, DinoLoadPaperQueryVariables>(DinoLoadPaperDocument, options);
      }
export function useDinoLoadPaperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DinoLoadPaperQuery, DinoLoadPaperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DinoLoadPaperQuery, DinoLoadPaperQueryVariables>(DinoLoadPaperDocument, options);
        }
export type DinoLoadPaperQueryHookResult = ReturnType<typeof useDinoLoadPaperQuery>;
export type DinoLoadPaperLazyQueryHookResult = ReturnType<typeof useDinoLoadPaperLazyQuery>;
export type DinoLoadPaperQueryResult = Apollo.QueryResult<DinoLoadPaperQuery, DinoLoadPaperQueryVariables>;
export const SubmitAnswersDocument = gql`
    mutation submitAnswers($paperId: ID!, $answers: [String!]!) {
  submitAnswers(paperId: $paperId, answers: $answers) {
    ...PaperParts
  }
}
    ${PaperPartsFragmentDoc}`;
export type SubmitAnswersMutationFn = Apollo.MutationFunction<SubmitAnswersMutation, SubmitAnswersMutationVariables>;

/**
 * __useSubmitAnswersMutation__
 *
 * To run a mutation, you first call `useSubmitAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswersMutation, { data, loading, error }] = useSubmitAnswersMutation({
 *   variables: {
 *      paperId: // value for 'paperId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useSubmitAnswersMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswersMutation, SubmitAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswersMutation, SubmitAnswersMutationVariables>(SubmitAnswersDocument, options);
      }
export type SubmitAnswersMutationHookResult = ReturnType<typeof useSubmitAnswersMutation>;
export type SubmitAnswersMutationResult = Apollo.MutationResult<SubmitAnswersMutation>;
export type SubmitAnswersMutationOptions = Apollo.BaseMutationOptions<SubmitAnswersMutation, SubmitAnswersMutationVariables>;
export const DinoAllPaperDocument = gql`
    query dinoAllPaper {
  dinoAllPaper {
    ...DPaperParts
  }
}
    ${DPaperPartsFragmentDoc}`;

/**
 * __useDinoAllPaperQuery__
 *
 * To run a query within a React component, call `useDinoAllPaperQuery` and pass it any options that fit your needs.
 * When your component renders, `useDinoAllPaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDinoAllPaperQuery({
 *   variables: {
 *   },
 * });
 */
export function useDinoAllPaperQuery(baseOptions?: Apollo.QueryHookOptions<DinoAllPaperQuery, DinoAllPaperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DinoAllPaperQuery, DinoAllPaperQueryVariables>(DinoAllPaperDocument, options);
      }
export function useDinoAllPaperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DinoAllPaperQuery, DinoAllPaperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DinoAllPaperQuery, DinoAllPaperQueryVariables>(DinoAllPaperDocument, options);
        }
export type DinoAllPaperQueryHookResult = ReturnType<typeof useDinoAllPaperQuery>;
export type DinoAllPaperLazyQueryHookResult = ReturnType<typeof useDinoAllPaperLazyQuery>;
export type DinoAllPaperQueryResult = Apollo.QueryResult<DinoAllPaperQuery, DinoAllPaperQueryVariables>;
export const DinoPaperDocument = gql`
    query dinoPaper($paperId: ID!) {
  dinoPaper(paperId: $paperId) {
    ...DPaperParts
  }
}
    ${DPaperPartsFragmentDoc}`;

/**
 * __useDinoPaperQuery__
 *
 * To run a query within a React component, call `useDinoPaperQuery` and pass it any options that fit your needs.
 * When your component renders, `useDinoPaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDinoPaperQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function useDinoPaperQuery(baseOptions: Apollo.QueryHookOptions<DinoPaperQuery, DinoPaperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DinoPaperQuery, DinoPaperQueryVariables>(DinoPaperDocument, options);
      }
export function useDinoPaperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DinoPaperQuery, DinoPaperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DinoPaperQuery, DinoPaperQueryVariables>(DinoPaperDocument, options);
        }
export type DinoPaperQueryHookResult = ReturnType<typeof useDinoPaperQuery>;
export type DinoPaperLazyQueryHookResult = ReturnType<typeof useDinoPaperLazyQuery>;
export type DinoPaperQueryResult = Apollo.QueryResult<DinoPaperQuery, DinoPaperQueryVariables>;
export const DinoProcessResponseDocument = gql`
    mutation dinoProcessResponse($paperId: ID!, $answers: [String!]!) {
  dinoProcessResponse(paperId: $paperId, answers: $answers) {
    ...DPaperParts
  }
}
    ${DPaperPartsFragmentDoc}`;
export type DinoProcessResponseMutationFn = Apollo.MutationFunction<DinoProcessResponseMutation, DinoProcessResponseMutationVariables>;

/**
 * __useDinoProcessResponseMutation__
 *
 * To run a mutation, you first call `useDinoProcessResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDinoProcessResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dinoProcessResponseMutation, { data, loading, error }] = useDinoProcessResponseMutation({
 *   variables: {
 *      paperId: // value for 'paperId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useDinoProcessResponseMutation(baseOptions?: Apollo.MutationHookOptions<DinoProcessResponseMutation, DinoProcessResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DinoProcessResponseMutation, DinoProcessResponseMutationVariables>(DinoProcessResponseDocument, options);
      }
export type DinoProcessResponseMutationHookResult = ReturnType<typeof useDinoProcessResponseMutation>;
export type DinoProcessResponseMutationResult = Apollo.MutationResult<DinoProcessResponseMutation>;
export type DinoProcessResponseMutationOptions = Apollo.BaseMutationOptions<DinoProcessResponseMutation, DinoProcessResponseMutationVariables>;
export const GetResultDocument = gql`
    query getResult($paperId: ID!) {
  paper(paperId: $paperId) {
    ...PaperParts
  }
}
    ${PaperPartsFragmentDoc}`;

/**
 * __useGetResultQuery__
 *
 * To run a query within a React component, call `useGetResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function useGetResultQuery(baseOptions: Apollo.QueryHookOptions<GetResultQuery, GetResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResultQuery, GetResultQueryVariables>(GetResultDocument, options);
      }
export function useGetResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResultQuery, GetResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResultQuery, GetResultQueryVariables>(GetResultDocument, options);
        }
export type GetResultQueryHookResult = ReturnType<typeof useGetResultQuery>;
export type GetResultLazyQueryHookResult = ReturnType<typeof useGetResultLazyQuery>;
export type GetResultQueryResult = Apollo.QueryResult<GetResultQuery, GetResultQueryVariables>;
export const EcodeByPaperDocument = gql`
    query ecodeByPaper($paperId: Int!) {
  ecodeByPaper(paperId: $paperId) {
    isLeader
    leaderName
  }
}
    `;

/**
 * __useEcodeByPaperQuery__
 *
 * To run a query within a React component, call `useEcodeByPaperQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcodeByPaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcodeByPaperQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function useEcodeByPaperQuery(baseOptions: Apollo.QueryHookOptions<EcodeByPaperQuery, EcodeByPaperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcodeByPaperQuery, EcodeByPaperQueryVariables>(EcodeByPaperDocument, options);
      }
export function useEcodeByPaperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcodeByPaperQuery, EcodeByPaperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcodeByPaperQuery, EcodeByPaperQueryVariables>(EcodeByPaperDocument, options);
        }
export type EcodeByPaperQueryHookResult = ReturnType<typeof useEcodeByPaperQuery>;
export type EcodeByPaperLazyQueryHookResult = ReturnType<typeof useEcodeByPaperLazyQuery>;
export type EcodeByPaperQueryResult = Apollo.QueryResult<EcodeByPaperQuery, EcodeByPaperQueryVariables>;
export const SendReportToEmailDocument = gql`
    mutation sendReportToEmail($paperId: ID!, $lang: String!, $email: String!) {
  sendReportToEmail(paperId: $paperId, lang: $lang, email: $email)
}
    `;
export type SendReportToEmailMutationFn = Apollo.MutationFunction<SendReportToEmailMutation, SendReportToEmailMutationVariables>;

/**
 * __useSendReportToEmailMutation__
 *
 * To run a mutation, you first call `useSendReportToEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReportToEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReportToEmailMutation, { data, loading, error }] = useSendReportToEmailMutation({
 *   variables: {
 *      paperId: // value for 'paperId'
 *      lang: // value for 'lang'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendReportToEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendReportToEmailMutation, SendReportToEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReportToEmailMutation, SendReportToEmailMutationVariables>(SendReportToEmailDocument, options);
      }
export type SendReportToEmailMutationHookResult = ReturnType<typeof useSendReportToEmailMutation>;
export type SendReportToEmailMutationResult = Apollo.MutationResult<SendReportToEmailMutation>;
export type SendReportToEmailMutationOptions = Apollo.BaseMutationOptions<SendReportToEmailMutation, SendReportToEmailMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $passwd: String!) {
  managerLogin(email: $email, passwd: $passwd) {
    username
    authority
    regionCode
    token
    exp
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      passwd: // value for 'passwd'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ManagerResetPasswdDocument = gql`
    mutation managerResetPasswd($email: String!) {
  managerResetPasswd(email: $email)
}
    `;
export type ManagerResetPasswdMutationFn = Apollo.MutationFunction<ManagerResetPasswdMutation, ManagerResetPasswdMutationVariables>;

/**
 * __useManagerResetPasswdMutation__
 *
 * To run a mutation, you first call `useManagerResetPasswdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerResetPasswdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerResetPasswdMutation, { data, loading, error }] = useManagerResetPasswdMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useManagerResetPasswdMutation(baseOptions?: Apollo.MutationHookOptions<ManagerResetPasswdMutation, ManagerResetPasswdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerResetPasswdMutation, ManagerResetPasswdMutationVariables>(ManagerResetPasswdDocument, options);
      }
export type ManagerResetPasswdMutationHookResult = ReturnType<typeof useManagerResetPasswdMutation>;
export type ManagerResetPasswdMutationResult = Apollo.MutationResult<ManagerResetPasswdMutation>;
export type ManagerResetPasswdMutationOptions = Apollo.BaseMutationOptions<ManagerResetPasswdMutation, ManagerResetPasswdMutationVariables>;
export const ManagerChangePasswdDocument = gql`
    mutation managerChangePasswd($passwd: String!, $newPasswd: String!) {
  managerChangePasswd(passwd: $passwd, newPasswd: $newPasswd) {
    id
  }
}
    `;
export type ManagerChangePasswdMutationFn = Apollo.MutationFunction<ManagerChangePasswdMutation, ManagerChangePasswdMutationVariables>;

/**
 * __useManagerChangePasswdMutation__
 *
 * To run a mutation, you first call `useManagerChangePasswdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerChangePasswdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerChangePasswdMutation, { data, loading, error }] = useManagerChangePasswdMutation({
 *   variables: {
 *      passwd: // value for 'passwd'
 *      newPasswd: // value for 'newPasswd'
 *   },
 * });
 */
export function useManagerChangePasswdMutation(baseOptions?: Apollo.MutationHookOptions<ManagerChangePasswdMutation, ManagerChangePasswdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerChangePasswdMutation, ManagerChangePasswdMutationVariables>(ManagerChangePasswdDocument, options);
      }
export type ManagerChangePasswdMutationHookResult = ReturnType<typeof useManagerChangePasswdMutation>;
export type ManagerChangePasswdMutationResult = Apollo.MutationResult<ManagerChangePasswdMutation>;
export type ManagerChangePasswdMutationOptions = Apollo.BaseMutationOptions<ManagerChangePasswdMutation, ManagerChangePasswdMutationVariables>;
export const ManagerChangePasswdByTokenDocument = gql`
    mutation managerChangePasswdByToken($token: String!, $newPasswd: String!) {
  managerChangePasswdByToken(token: $token, newPasswd: $newPasswd)
}
    `;
export type ManagerChangePasswdByTokenMutationFn = Apollo.MutationFunction<ManagerChangePasswdByTokenMutation, ManagerChangePasswdByTokenMutationVariables>;

/**
 * __useManagerChangePasswdByTokenMutation__
 *
 * To run a mutation, you first call `useManagerChangePasswdByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerChangePasswdByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerChangePasswdByTokenMutation, { data, loading, error }] = useManagerChangePasswdByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPasswd: // value for 'newPasswd'
 *   },
 * });
 */
export function useManagerChangePasswdByTokenMutation(baseOptions?: Apollo.MutationHookOptions<ManagerChangePasswdByTokenMutation, ManagerChangePasswdByTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerChangePasswdByTokenMutation, ManagerChangePasswdByTokenMutationVariables>(ManagerChangePasswdByTokenDocument, options);
      }
export type ManagerChangePasswdByTokenMutationHookResult = ReturnType<typeof useManagerChangePasswdByTokenMutation>;
export type ManagerChangePasswdByTokenMutationResult = Apollo.MutationResult<ManagerChangePasswdByTokenMutation>;
export type ManagerChangePasswdByTokenMutationOptions = Apollo.BaseMutationOptions<ManagerChangePasswdByTokenMutation, ManagerChangePasswdByTokenMutationVariables>;
export const UserTokenCheckDocument = gql`
    query userTokenCheck($token: String!, $type: TokenCheckType!) {
  userTokenCheck(token: $token, type: $type) {
    email
  }
}
    `;

/**
 * __useUserTokenCheckQuery__
 *
 * To run a query within a React component, call `useUserTokenCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTokenCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTokenCheckQuery({
 *   variables: {
 *      token: // value for 'token'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUserTokenCheckQuery(baseOptions: Apollo.QueryHookOptions<UserTokenCheckQuery, UserTokenCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTokenCheckQuery, UserTokenCheckQueryVariables>(UserTokenCheckDocument, options);
      }
export function useUserTokenCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTokenCheckQuery, UserTokenCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTokenCheckQuery, UserTokenCheckQueryVariables>(UserTokenCheckDocument, options);
        }
export type UserTokenCheckQueryHookResult = ReturnType<typeof useUserTokenCheckQuery>;
export type UserTokenCheckLazyQueryHookResult = ReturnType<typeof useUserTokenCheckLazyQuery>;
export type UserTokenCheckQueryResult = Apollo.QueryResult<UserTokenCheckQuery, UserTokenCheckQueryVariables>;
export const SessionDocument = gql`
    query session($id: Int!) {
  session(sessionId: $id) {
    ...SessionParts
  }
}
    ${SessionPartsFragmentDoc}`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionQuery(baseOptions: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const CreateSessionDocument = gql`
    mutation createSession($input: CreateSessionInput!) {
  createSession(input: $input)
}
    `;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const StartSessionDocument = gql`
    mutation startSession($id: ID!) {
  startSession(sessId: $id) {
    id
    status
  }
}
    `;
export type StartSessionMutationFn = Apollo.MutationFunction<StartSessionMutation, StartSessionMutationVariables>;

/**
 * __useStartSessionMutation__
 *
 * To run a mutation, you first call `useStartSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSessionMutation, { data, loading, error }] = useStartSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartSessionMutation, StartSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSessionMutation, StartSessionMutationVariables>(StartSessionDocument, options);
      }
export type StartSessionMutationHookResult = ReturnType<typeof useStartSessionMutation>;
export type StartSessionMutationResult = Apollo.MutationResult<StartSessionMutation>;
export type StartSessionMutationOptions = Apollo.BaseMutationOptions<StartSessionMutation, StartSessionMutationVariables>;
export const ActivateSessionDocument = gql`
    mutation activateSession($id: ID!) {
  activateSession(sessId: $id) {
    id
    status
  }
}
    `;
export type ActivateSessionMutationFn = Apollo.MutationFunction<ActivateSessionMutation, ActivateSessionMutationVariables>;

/**
 * __useActivateSessionMutation__
 *
 * To run a mutation, you first call `useActivateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSessionMutation, { data, loading, error }] = useActivateSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSessionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSessionMutation, ActivateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSessionMutation, ActivateSessionMutationVariables>(ActivateSessionDocument, options);
      }
export type ActivateSessionMutationHookResult = ReturnType<typeof useActivateSessionMutation>;
export type ActivateSessionMutationResult = Apollo.MutationResult<ActivateSessionMutation>;
export type ActivateSessionMutationOptions = Apollo.BaseMutationOptions<ActivateSessionMutation, ActivateSessionMutationVariables>;
export const FinalizeSessionDocument = gql`
    mutation finalizeSession($id: ID!) {
  finalizeSession(sessId: $id) {
    id
    status
  }
}
    `;
export type FinalizeSessionMutationFn = Apollo.MutationFunction<FinalizeSessionMutation, FinalizeSessionMutationVariables>;

/**
 * __useFinalizeSessionMutation__
 *
 * To run a mutation, you first call `useFinalizeSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeSessionMutation, { data, loading, error }] = useFinalizeSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizeSessionMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeSessionMutation, FinalizeSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeSessionMutation, FinalizeSessionMutationVariables>(FinalizeSessionDocument, options);
      }
export type FinalizeSessionMutationHookResult = ReturnType<typeof useFinalizeSessionMutation>;
export type FinalizeSessionMutationResult = Apollo.MutationResult<FinalizeSessionMutation>;
export type FinalizeSessionMutationOptions = Apollo.BaseMutationOptions<FinalizeSessionMutation, FinalizeSessionMutationVariables>;
export const BatchUpdateECodeProfileDocument = gql`
    mutation batchUpdateECodeProfile($id: ID!, $profiles: [UploadProfileInput!]!) {
  batchUpdateECodeProfile(sessId: $id, profiles: $profiles)
}
    `;
export type BatchUpdateECodeProfileMutationFn = Apollo.MutationFunction<BatchUpdateECodeProfileMutation, BatchUpdateECodeProfileMutationVariables>;

/**
 * __useBatchUpdateECodeProfileMutation__
 *
 * To run a mutation, you first call `useBatchUpdateECodeProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpdateECodeProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpdateECodeProfileMutation, { data, loading, error }] = useBatchUpdateECodeProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profiles: // value for 'profiles'
 *   },
 * });
 */
export function useBatchUpdateECodeProfileMutation(baseOptions?: Apollo.MutationHookOptions<BatchUpdateECodeProfileMutation, BatchUpdateECodeProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchUpdateECodeProfileMutation, BatchUpdateECodeProfileMutationVariables>(BatchUpdateECodeProfileDocument, options);
      }
export type BatchUpdateECodeProfileMutationHookResult = ReturnType<typeof useBatchUpdateECodeProfileMutation>;
export type BatchUpdateECodeProfileMutationResult = Apollo.MutationResult<BatchUpdateECodeProfileMutation>;
export type BatchUpdateECodeProfileMutationOptions = Apollo.BaseMutationOptions<BatchUpdateECodeProfileMutation, BatchUpdateECodeProfileMutationVariables>;
export const UploadProfileDocument = gql`
    mutation uploadProfile($id: ID!, $profiles: [UploadProfileInput!]!) {
  batchUpdateECodeProfile(sessId: $id, profiles: $profiles)
}
    `;
export type UploadProfileMutationFn = Apollo.MutationFunction<UploadProfileMutation, UploadProfileMutationVariables>;

/**
 * __useUploadProfileMutation__
 *
 * To run a mutation, you first call `useUploadProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfileMutation, { data, loading, error }] = useUploadProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profiles: // value for 'profiles'
 *   },
 * });
 */
export function useUploadProfileMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfileMutation, UploadProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProfileMutation, UploadProfileMutationVariables>(UploadProfileDocument, options);
      }
export type UploadProfileMutationHookResult = ReturnType<typeof useUploadProfileMutation>;
export type UploadProfileMutationResult = Apollo.MutationResult<UploadProfileMutation>;
export type UploadProfileMutationOptions = Apollo.BaseMutationOptions<UploadProfileMutation, UploadProfileMutationVariables>;
export const EcodesBySessionDocument = gql`
    query ecodesBySession($sessionId: Int!) {
  ecodesBySession(sessionId: $sessionId) {
    ...NewECodeParts
    assessors {
      ...NewECodeParts
    }
  }
}
    ${NewECodePartsFragmentDoc}`;

/**
 * __useEcodesBySessionQuery__
 *
 * To run a query within a React component, call `useEcodesBySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcodesBySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcodesBySessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useEcodesBySessionQuery(baseOptions: Apollo.QueryHookOptions<EcodesBySessionQuery, EcodesBySessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcodesBySessionQuery, EcodesBySessionQueryVariables>(EcodesBySessionDocument, options);
      }
export function useEcodesBySessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcodesBySessionQuery, EcodesBySessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcodesBySessionQuery, EcodesBySessionQueryVariables>(EcodesBySessionDocument, options);
        }
export type EcodesBySessionQueryHookResult = ReturnType<typeof useEcodesBySessionQuery>;
export type EcodesBySessionLazyQueryHookResult = ReturnType<typeof useEcodesBySessionLazyQuery>;
export type EcodesBySessionQueryResult = Apollo.QueryResult<EcodesBySessionQuery, EcodesBySessionQueryVariables>;
export const DeletedEcodesBySessionDocument = gql`
    query deletedEcodesBySession($sessionId: Int!) {
  deletedEcodesBySession(sessionId: $sessionId) {
    ...NewECodeParts
    deletedAssessors {
      ...NewECodeParts
    }
  }
}
    ${NewECodePartsFragmentDoc}`;

/**
 * __useDeletedEcodesBySessionQuery__
 *
 * To run a query within a React component, call `useDeletedEcodesBySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedEcodesBySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedEcodesBySessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDeletedEcodesBySessionQuery(baseOptions: Apollo.QueryHookOptions<DeletedEcodesBySessionQuery, DeletedEcodesBySessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedEcodesBySessionQuery, DeletedEcodesBySessionQueryVariables>(DeletedEcodesBySessionDocument, options);
      }
export function useDeletedEcodesBySessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedEcodesBySessionQuery, DeletedEcodesBySessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedEcodesBySessionQuery, DeletedEcodesBySessionQueryVariables>(DeletedEcodesBySessionDocument, options);
        }
export type DeletedEcodesBySessionQueryHookResult = ReturnType<typeof useDeletedEcodesBySessionQuery>;
export type DeletedEcodesBySessionLazyQueryHookResult = ReturnType<typeof useDeletedEcodesBySessionLazyQuery>;
export type DeletedEcodesBySessionQueryResult = Apollo.QueryResult<DeletedEcodesBySessionQuery, DeletedEcodesBySessionQueryVariables>;
export const PaperViewsBySessionDocument = gql`
    query paperViewsBySession($sessId: ID!, $itemId: String!, $offset: Int, $limit: Int) {
  paperViewsBySession(sessId: $sessId, itemId: $itemId, offset: $offset, limit: $limit) {
    total
    fields
    results {
      id
      status
      nid
      name
      validation
      values
      email
      emailStatus
      emailSentDate
      ecId
      leaderEcId
    }
  }
}
    `;

/**
 * __usePaperViewsBySessionQuery__
 *
 * To run a query within a React component, call `usePaperViewsBySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperViewsBySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperViewsBySessionQuery({
 *   variables: {
 *      sessId: // value for 'sessId'
 *      itemId: // value for 'itemId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePaperViewsBySessionQuery(baseOptions: Apollo.QueryHookOptions<PaperViewsBySessionQuery, PaperViewsBySessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaperViewsBySessionQuery, PaperViewsBySessionQueryVariables>(PaperViewsBySessionDocument, options);
      }
export function usePaperViewsBySessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaperViewsBySessionQuery, PaperViewsBySessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaperViewsBySessionQuery, PaperViewsBySessionQueryVariables>(PaperViewsBySessionDocument, options);
        }
export type PaperViewsBySessionQueryHookResult = ReturnType<typeof usePaperViewsBySessionQuery>;
export type PaperViewsBySessionLazyQueryHookResult = ReturnType<typeof usePaperViewsBySessionLazyQuery>;
export type PaperViewsBySessionQueryResult = Apollo.QueryResult<PaperViewsBySessionQuery, PaperViewsBySessionQueryVariables>;
export const DinoPapersByEcIdDocument = gql`
    query dinoPapersByEcId($ecId: Int!) {
  dinoPapers(filter: {ecId: $ecId}) {
    id
    item {
      id
      title
      subtitle
    }
    statusCd
    results
  }
}
    `;

/**
 * __useDinoPapersByEcIdQuery__
 *
 * To run a query within a React component, call `useDinoPapersByEcIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDinoPapersByEcIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDinoPapersByEcIdQuery({
 *   variables: {
 *      ecId: // value for 'ecId'
 *   },
 * });
 */
export function useDinoPapersByEcIdQuery(baseOptions: Apollo.QueryHookOptions<DinoPapersByEcIdQuery, DinoPapersByEcIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DinoPapersByEcIdQuery, DinoPapersByEcIdQueryVariables>(DinoPapersByEcIdDocument, options);
      }
export function useDinoPapersByEcIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DinoPapersByEcIdQuery, DinoPapersByEcIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DinoPapersByEcIdQuery, DinoPapersByEcIdQueryVariables>(DinoPapersByEcIdDocument, options);
        }
export type DinoPapersByEcIdQueryHookResult = ReturnType<typeof useDinoPapersByEcIdQuery>;
export type DinoPapersByEcIdLazyQueryHookResult = ReturnType<typeof useDinoPapersByEcIdLazyQuery>;
export type DinoPapersByEcIdQueryResult = Apollo.QueryResult<DinoPapersByEcIdQuery, DinoPapersByEcIdQueryVariables>;
export const GenerateReportDocument = gql`
    mutation generateReport($paperId: ID!, $lang: String!) {
  generateReport(paperId: $paperId, lang: $lang)
}
    `;
export type GenerateReportMutationFn = Apollo.MutationFunction<GenerateReportMutation, GenerateReportMutationVariables>;

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      paperId: // value for 'paperId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGenerateReportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateReportMutation, GenerateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GenerateReportDocument, options);
      }
export type GenerateReportMutationHookResult = ReturnType<typeof useGenerateReportMutation>;
export type GenerateReportMutationResult = Apollo.MutationResult<GenerateReportMutation>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<GenerateReportMutation, GenerateReportMutationVariables>;
export const UpdateLeaderDocument = gql`
    mutation updateLeader($id: ID!, $sessId: ID!, $isLeader: Boolean!) {
  updateLeader(ec_id: $id, sess_id: $sessId, is_leader: $isLeader)
}
    `;
export type UpdateLeaderMutationFn = Apollo.MutationFunction<UpdateLeaderMutation, UpdateLeaderMutationVariables>;

/**
 * __useUpdateLeaderMutation__
 *
 * To run a mutation, you first call `useUpdateLeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaderMutation, { data, loading, error }] = useUpdateLeaderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sessId: // value for 'sessId'
 *      isLeader: // value for 'isLeader'
 *   },
 * });
 */
export function useUpdateLeaderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeaderMutation, UpdateLeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeaderMutation, UpdateLeaderMutationVariables>(UpdateLeaderDocument, options);
      }
export type UpdateLeaderMutationHookResult = ReturnType<typeof useUpdateLeaderMutation>;
export type UpdateLeaderMutationResult = Apollo.MutationResult<UpdateLeaderMutation>;
export type UpdateLeaderMutationOptions = Apollo.BaseMutationOptions<UpdateLeaderMutation, UpdateLeaderMutationVariables>;
export const DeleteSessionDocument = gql`
    mutation deleteSession($sessionIds: [Int!]!) {
  deleteSession(sessionIds: $sessionIds)
}
    `;
export type DeleteSessionMutationFn = Apollo.MutationFunction<DeleteSessionMutation, DeleteSessionMutationVariables>;

/**
 * __useDeleteSessionMutation__
 *
 * To run a mutation, you first call `useDeleteSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionMutation, { data, loading, error }] = useDeleteSessionMutation({
 *   variables: {
 *      sessionIds: // value for 'sessionIds'
 *   },
 * });
 */
export function useDeleteSessionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSessionMutation, DeleteSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSessionMutation, DeleteSessionMutationVariables>(DeleteSessionDocument, options);
      }
export type DeleteSessionMutationHookResult = ReturnType<typeof useDeleteSessionMutation>;
export type DeleteSessionMutationResult = Apollo.MutationResult<DeleteSessionMutation>;
export type DeleteSessionMutationOptions = Apollo.BaseMutationOptions<DeleteSessionMutation, DeleteSessionMutationVariables>;
export const ModifySessionTitleDocument = gql`
    mutation modifySessionTitle($sessionId: Int!, $title: String!) {
  modifySessionTitle(sessionId: $sessionId, title: $title)
}
    `;
export type ModifySessionTitleMutationFn = Apollo.MutationFunction<ModifySessionTitleMutation, ModifySessionTitleMutationVariables>;

/**
 * __useModifySessionTitleMutation__
 *
 * To run a mutation, you first call `useModifySessionTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySessionTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySessionTitleMutation, { data, loading, error }] = useModifySessionTitleMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useModifySessionTitleMutation(baseOptions?: Apollo.MutationHookOptions<ModifySessionTitleMutation, ModifySessionTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifySessionTitleMutation, ModifySessionTitleMutationVariables>(ModifySessionTitleDocument, options);
      }
export type ModifySessionTitleMutationHookResult = ReturnType<typeof useModifySessionTitleMutation>;
export type ModifySessionTitleMutationResult = Apollo.MutationResult<ModifySessionTitleMutation>;
export type ModifySessionTitleMutationOptions = Apollo.BaseMutationOptions<ModifySessionTitleMutation, ModifySessionTitleMutationVariables>;
export const RestoreSessionDocument = gql`
    mutation restoreSession($sessionIds: [Int!]!) {
  restoreSession(sessionIds: $sessionIds)
}
    `;
export type RestoreSessionMutationFn = Apollo.MutationFunction<RestoreSessionMutation, RestoreSessionMutationVariables>;

/**
 * __useRestoreSessionMutation__
 *
 * To run a mutation, you first call `useRestoreSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSessionMutation, { data, loading, error }] = useRestoreSessionMutation({
 *   variables: {
 *      sessionIds: // value for 'sessionIds'
 *   },
 * });
 */
export function useRestoreSessionMutation(baseOptions?: Apollo.MutationHookOptions<RestoreSessionMutation, RestoreSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreSessionMutation, RestoreSessionMutationVariables>(RestoreSessionDocument, options);
      }
export type RestoreSessionMutationHookResult = ReturnType<typeof useRestoreSessionMutation>;
export type RestoreSessionMutationResult = Apollo.MutationResult<RestoreSessionMutation>;
export type RestoreSessionMutationOptions = Apollo.BaseMutationOptions<RestoreSessionMutation, RestoreSessionMutationVariables>;
export const CreateEmptyEcodeDocument = gql`
    mutation createEmptyEcode($sessId: ID!, $leaderEcId: Int) {
  createEmptyEcode(sessId: $sessId, leaderEcId: $leaderEcId)
}
    `;
export type CreateEmptyEcodeMutationFn = Apollo.MutationFunction<CreateEmptyEcodeMutation, CreateEmptyEcodeMutationVariables>;

/**
 * __useCreateEmptyEcodeMutation__
 *
 * To run a mutation, you first call `useCreateEmptyEcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyEcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyEcodeMutation, { data, loading, error }] = useCreateEmptyEcodeMutation({
 *   variables: {
 *      sessId: // value for 'sessId'
 *      leaderEcId: // value for 'leaderEcId'
 *   },
 * });
 */
export function useCreateEmptyEcodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmptyEcodeMutation, CreateEmptyEcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmptyEcodeMutation, CreateEmptyEcodeMutationVariables>(CreateEmptyEcodeDocument, options);
      }
export type CreateEmptyEcodeMutationHookResult = ReturnType<typeof useCreateEmptyEcodeMutation>;
export type CreateEmptyEcodeMutationResult = Apollo.MutationResult<CreateEmptyEcodeMutation>;
export type CreateEmptyEcodeMutationOptions = Apollo.BaseMutationOptions<CreateEmptyEcodeMutation, CreateEmptyEcodeMutationVariables>;
export const CreateEcodesDocument = gql`
    mutation createEcodes($sessId: ID!, $profiles: [CodeProfileInput!]!) {
  createEcodes(sessId: $sessId, profiles: $profiles)
}
    `;
export type CreateEcodesMutationFn = Apollo.MutationFunction<CreateEcodesMutation, CreateEcodesMutationVariables>;

/**
 * __useCreateEcodesMutation__
 *
 * To run a mutation, you first call `useCreateEcodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEcodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEcodesMutation, { data, loading, error }] = useCreateEcodesMutation({
 *   variables: {
 *      sessId: // value for 'sessId'
 *      profiles: // value for 'profiles'
 *   },
 * });
 */
export function useCreateEcodesMutation(baseOptions?: Apollo.MutationHookOptions<CreateEcodesMutation, CreateEcodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEcodesMutation, CreateEcodesMutationVariables>(CreateEcodesDocument, options);
      }
export type CreateEcodesMutationHookResult = ReturnType<typeof useCreateEcodesMutation>;
export type CreateEcodesMutationResult = Apollo.MutationResult<CreateEcodesMutation>;
export type CreateEcodesMutationOptions = Apollo.BaseMutationOptions<CreateEcodesMutation, CreateEcodesMutationVariables>;
export const UpdateEcodeDocument = gql`
    mutation updateEcode($sessId: ID!, $ecId: ID!, $changed: EcodeField) {
  updateEcode(sessId: $sessId, ecId: $ecId, changed: $changed)
}
    `;
export type UpdateEcodeMutationFn = Apollo.MutationFunction<UpdateEcodeMutation, UpdateEcodeMutationVariables>;

/**
 * __useUpdateEcodeMutation__
 *
 * To run a mutation, you first call `useUpdateEcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEcodeMutation, { data, loading, error }] = useUpdateEcodeMutation({
 *   variables: {
 *      sessId: // value for 'sessId'
 *      ecId: // value for 'ecId'
 *      changed: // value for 'changed'
 *   },
 * });
 */
export function useUpdateEcodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEcodeMutation, UpdateEcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEcodeMutation, UpdateEcodeMutationVariables>(UpdateEcodeDocument, options);
      }
export type UpdateEcodeMutationHookResult = ReturnType<typeof useUpdateEcodeMutation>;
export type UpdateEcodeMutationResult = Apollo.MutationResult<UpdateEcodeMutation>;
export type UpdateEcodeMutationOptions = Apollo.BaseMutationOptions<UpdateEcodeMutation, UpdateEcodeMutationVariables>;
export const DeleteEcodeDocument = gql`
    mutation deleteEcode($ecId: ID!, $forceDelete: Boolean) {
  deleteEcode(ecId: $ecId, forceDelete: $forceDelete)
}
    `;
export type DeleteEcodeMutationFn = Apollo.MutationFunction<DeleteEcodeMutation, DeleteEcodeMutationVariables>;

/**
 * __useDeleteEcodeMutation__
 *
 * To run a mutation, you first call `useDeleteEcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEcodeMutation, { data, loading, error }] = useDeleteEcodeMutation({
 *   variables: {
 *      ecId: // value for 'ecId'
 *      forceDelete: // value for 'forceDelete'
 *   },
 * });
 */
export function useDeleteEcodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEcodeMutation, DeleteEcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEcodeMutation, DeleteEcodeMutationVariables>(DeleteEcodeDocument, options);
      }
export type DeleteEcodeMutationHookResult = ReturnType<typeof useDeleteEcodeMutation>;
export type DeleteEcodeMutationResult = Apollo.MutationResult<DeleteEcodeMutation>;
export type DeleteEcodeMutationOptions = Apollo.BaseMutationOptions<DeleteEcodeMutation, DeleteEcodeMutationVariables>;
export const DeleteEcodesDocument = gql`
    mutation deleteEcodes($ecIds: [ID!]!, $forceDelete: Boolean) {
  deleteEcodes(ecIds: $ecIds, forceDelete: $forceDelete)
}
    `;
export type DeleteEcodesMutationFn = Apollo.MutationFunction<DeleteEcodesMutation, DeleteEcodesMutationVariables>;

/**
 * __useDeleteEcodesMutation__
 *
 * To run a mutation, you first call `useDeleteEcodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEcodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEcodesMutation, { data, loading, error }] = useDeleteEcodesMutation({
 *   variables: {
 *      ecIds: // value for 'ecIds'
 *      forceDelete: // value for 'forceDelete'
 *   },
 * });
 */
export function useDeleteEcodesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEcodesMutation, DeleteEcodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEcodesMutation, DeleteEcodesMutationVariables>(DeleteEcodesDocument, options);
      }
export type DeleteEcodesMutationHookResult = ReturnType<typeof useDeleteEcodesMutation>;
export type DeleteEcodesMutationResult = Apollo.MutationResult<DeleteEcodesMutation>;
export type DeleteEcodesMutationOptions = Apollo.BaseMutationOptions<DeleteEcodesMutation, DeleteEcodesMutationVariables>;
export const SendReportToEmailAllDocument = gql`
    mutation sendReportToEmailAll($sessId: ID!, $lang: String!, $itemId: String!) {
  sendReportToEmailAll(sessId: $sessId, lang: $lang, itemId: $itemId)
}
    `;
export type SendReportToEmailAllMutationFn = Apollo.MutationFunction<SendReportToEmailAllMutation, SendReportToEmailAllMutationVariables>;

/**
 * __useSendReportToEmailAllMutation__
 *
 * To run a mutation, you first call `useSendReportToEmailAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReportToEmailAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReportToEmailAllMutation, { data, loading, error }] = useSendReportToEmailAllMutation({
 *   variables: {
 *      sessId: // value for 'sessId'
 *      lang: // value for 'lang'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useSendReportToEmailAllMutation(baseOptions?: Apollo.MutationHookOptions<SendReportToEmailAllMutation, SendReportToEmailAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReportToEmailAllMutation, SendReportToEmailAllMutationVariables>(SendReportToEmailAllDocument, options);
      }
export type SendReportToEmailAllMutationHookResult = ReturnType<typeof useSendReportToEmailAllMutation>;
export type SendReportToEmailAllMutationResult = Apollo.MutationResult<SendReportToEmailAllMutation>;
export type SendReportToEmailAllMutationOptions = Apollo.BaseMutationOptions<SendReportToEmailAllMutation, SendReportToEmailAllMutationVariables>;
export const GroupsDocument = gql`
    query groups($criteria: GetGroupsCriteria!) {
  groups(criteria: $criteria) {
    groupId
    groupName
    regionCode
    region {
      ...RegionPart
    }
    assessments {
      assessment
    }
    updatedAt
    users {
      username
    }
    managerNames
  }
  groupCount(criteria: $criteria)
  regions {
    ...RegionPart
  }
  items {
    ...NewItemPart
  }
}
    ${RegionPartFragmentDoc}
${NewItemPartFragmentDoc}`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const CreateGroupDocument = gql`
    mutation createGroup($group: CreateGroupInput!) {
  createGroup(group: $group) {
    groupName
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const ModifyGroupDocument = gql`
    mutation modifyGroup($group: ModifyGroupInput!) {
  modifyGroup(group: $group) {
    groupId
  }
}
    `;
export type ModifyGroupMutationFn = Apollo.MutationFunction<ModifyGroupMutation, ModifyGroupMutationVariables>;

/**
 * __useModifyGroupMutation__
 *
 * To run a mutation, you first call `useModifyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyGroupMutation, { data, loading, error }] = useModifyGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useModifyGroupMutation(baseOptions?: Apollo.MutationHookOptions<ModifyGroupMutation, ModifyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyGroupMutation, ModifyGroupMutationVariables>(ModifyGroupDocument, options);
      }
export type ModifyGroupMutationHookResult = ReturnType<typeof useModifyGroupMutation>;
export type ModifyGroupMutationResult = Apollo.MutationResult<ModifyGroupMutation>;
export type ModifyGroupMutationOptions = Apollo.BaseMutationOptions<ModifyGroupMutation, ModifyGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($groupId: Int!) {
  deleteGroup(groupId: $groupId)
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($userInput: CreateUserInput!) {
  createUser(userInput: $userInput) {
    uid
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ModifyUserDocument = gql`
    mutation modifyUser($userInput: ModifyUserInput!) {
  modifyUser(userInput: $userInput) {
    uid
  }
}
    `;
export type ModifyUserMutationFn = Apollo.MutationFunction<ModifyUserMutation, ModifyUserMutationVariables>;

/**
 * __useModifyUserMutation__
 *
 * To run a mutation, you first call `useModifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyUserMutation, { data, loading, error }] = useModifyUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useModifyUserMutation(baseOptions?: Apollo.MutationHookOptions<ModifyUserMutation, ModifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyUserMutation, ModifyUserMutationVariables>(ModifyUserDocument, options);
      }
export type ModifyUserMutationHookResult = ReturnType<typeof useModifyUserMutation>;
export type ModifyUserMutationResult = Apollo.MutationResult<ModifyUserMutation>;
export type ModifyUserMutationOptions = Apollo.BaseMutationOptions<ModifyUserMutation, ModifyUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($uid: Int!) {
  deleteUser(uid: $uid)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UsersDocument = gql`
    query users($criteria: GetUsersCriteria!, $regionCode: String) {
  users(criteria: $criteria) {
    ...UserPart
  }
  userCount(criteria: $criteria)
  regions {
    ...RegionPart
  }
  authorities
  allGroups(regionCode: $regionCode) {
    groupId
    groupName
  }
}
    ${UserPartFragmentDoc}
${RegionPartFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *      regionCode: // value for 'regionCode'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const PaperStatusDocument = gql`
    query paperStatus($groupId: Int) {
  paperStatus(groupId: $groupId) {
    item {
      itemId
      name
      shortName
    }
    paperTotal
    paperUsed
    paperAvailable
  }
}
    `;

/**
 * __usePaperStatusQuery__
 *
 * To run a query within a React component, call `usePaperStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperStatusQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function usePaperStatusQuery(baseOptions?: Apollo.QueryHookOptions<PaperStatusQuery, PaperStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaperStatusQuery, PaperStatusQueryVariables>(PaperStatusDocument, options);
      }
export function usePaperStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaperStatusQuery, PaperStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaperStatusQuery, PaperStatusQueryVariables>(PaperStatusDocument, options);
        }
export type PaperStatusQueryHookResult = ReturnType<typeof usePaperStatusQuery>;
export type PaperStatusLazyQueryHookResult = ReturnType<typeof usePaperStatusLazyQuery>;
export type PaperStatusQueryResult = Apollo.QueryResult<PaperStatusQuery, PaperStatusQueryVariables>;
export const CreatePapersDocument = gql`
    mutation createPapers($input: CreatePapersInput!) {
  createPapers(input: $input)
}
    `;
export type CreatePapersMutationFn = Apollo.MutationFunction<CreatePapersMutation, CreatePapersMutationVariables>;

/**
 * __useCreatePapersMutation__
 *
 * To run a mutation, you first call `useCreatePapersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePapersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPapersMutation, { data, loading, error }] = useCreatePapersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePapersMutation(baseOptions?: Apollo.MutationHookOptions<CreatePapersMutation, CreatePapersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePapersMutation, CreatePapersMutationVariables>(CreatePapersDocument, options);
      }
export type CreatePapersMutationHookResult = ReturnType<typeof useCreatePapersMutation>;
export type CreatePapersMutationResult = Apollo.MutationResult<CreatePapersMutation>;
export type CreatePapersMutationOptions = Apollo.BaseMutationOptions<CreatePapersMutation, CreatePapersMutationVariables>;
export const DeletePapersDocument = gql`
    mutation deletePapers($input: CreatePapersInput!) {
  deletePapers(input: $input)
}
    `;
export type DeletePapersMutationFn = Apollo.MutationFunction<DeletePapersMutation, DeletePapersMutationVariables>;

/**
 * __useDeletePapersMutation__
 *
 * To run a mutation, you first call `useDeletePapersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePapersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePapersMutation, { data, loading, error }] = useDeletePapersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePapersMutation(baseOptions?: Apollo.MutationHookOptions<DeletePapersMutation, DeletePapersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePapersMutation, DeletePapersMutationVariables>(DeletePapersDocument, options);
      }
export type DeletePapersMutationHookResult = ReturnType<typeof useDeletePapersMutation>;
export type DeletePapersMutationResult = Apollo.MutationResult<DeletePapersMutation>;
export type DeletePapersMutationOptions = Apollo.BaseMutationOptions<DeletePapersMutation, DeletePapersMutationVariables>;
export const ModifyPaperReceiveEmailDocument = gql`
    mutation modifyPaperReceiveEmail($input: ModifyPaperReceiveEmailInput!) {
  modifyPaperReceiveEmail(input: $input)
}
    `;
export type ModifyPaperReceiveEmailMutationFn = Apollo.MutationFunction<ModifyPaperReceiveEmailMutation, ModifyPaperReceiveEmailMutationVariables>;

/**
 * __useModifyPaperReceiveEmailMutation__
 *
 * To run a mutation, you first call `useModifyPaperReceiveEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPaperReceiveEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPaperReceiveEmailMutation, { data, loading, error }] = useModifyPaperReceiveEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyPaperReceiveEmailMutation(baseOptions?: Apollo.MutationHookOptions<ModifyPaperReceiveEmailMutation, ModifyPaperReceiveEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyPaperReceiveEmailMutation, ModifyPaperReceiveEmailMutationVariables>(ModifyPaperReceiveEmailDocument, options);
      }
export type ModifyPaperReceiveEmailMutationHookResult = ReturnType<typeof useModifyPaperReceiveEmailMutation>;
export type ModifyPaperReceiveEmailMutationResult = Apollo.MutationResult<ModifyPaperReceiveEmailMutation>;
export type ModifyPaperReceiveEmailMutationOptions = Apollo.BaseMutationOptions<ModifyPaperReceiveEmailMutation, ModifyPaperReceiveEmailMutationVariables>;
export const LayoutMetasDocument = gql`
    query layoutMetas($regionCode: String!, $criteria: GetOrdersCriteria!) {
  allGroups(regionCode: $regionCode) {
    groupId
    groupName
  }
  regions {
    ...RegionPart
  }
  orderCount(criteria: $criteria)
}
    ${RegionPartFragmentDoc}`;

/**
 * __useLayoutMetasQuery__
 *
 * To run a query within a React component, call `useLayoutMetasQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutMetasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutMetasQuery({
 *   variables: {
 *      regionCode: // value for 'regionCode'
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useLayoutMetasQuery(baseOptions: Apollo.QueryHookOptions<LayoutMetasQuery, LayoutMetasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutMetasQuery, LayoutMetasQueryVariables>(LayoutMetasDocument, options);
      }
export function useLayoutMetasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutMetasQuery, LayoutMetasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutMetasQuery, LayoutMetasQueryVariables>(LayoutMetasDocument, options);
        }
export type LayoutMetasQueryHookResult = ReturnType<typeof useLayoutMetasQuery>;
export type LayoutMetasLazyQueryHookResult = ReturnType<typeof useLayoutMetasLazyQuery>;
export type LayoutMetasQueryResult = Apollo.QueryResult<LayoutMetasQuery, LayoutMetasQueryVariables>;
export const OrdersDocument = gql`
    query orders($criteria: GetOrdersCriteria!) {
  orders(criteria: $criteria) {
    orders {
      orderId
      itemId
      status
      paymentMethod
      qty
      unitValue
      supplyValue
      tax
      totalAmount
      createdAt
      updatedAt
      group {
        groupName
      }
      user {
        username
      }
      item {
        name
        shortName
      }
    }
    totalCount
  }
  items {
    ...NewItemPart
  }
}
    ${NewItemPartFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: CreateOrderInput!) {
  createOrder(input: $input)
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const ModifyOrderDocument = gql`
    mutation modifyOrder($input: ModifyOrderInput!) {
  modifyOrder(input: $input)
}
    `;
export type ModifyOrderMutationFn = Apollo.MutationFunction<ModifyOrderMutation, ModifyOrderMutationVariables>;

/**
 * __useModifyOrderMutation__
 *
 * To run a mutation, you first call `useModifyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrderMutation, { data, loading, error }] = useModifyOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrderMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrderMutation, ModifyOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrderMutation, ModifyOrderMutationVariables>(ModifyOrderDocument, options);
      }
export type ModifyOrderMutationHookResult = ReturnType<typeof useModifyOrderMutation>;
export type ModifyOrderMutationResult = Apollo.MutationResult<ModifyOrderMutation>;
export type ModifyOrderMutationOptions = Apollo.BaseMutationOptions<ModifyOrderMutation, ModifyOrderMutationVariables>;
export const CancelOrderDocument = gql`
    mutation cancelOrder($orderId: Int!) {
  cancelOrder(orderId: $orderId)
}
    `;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const ConfirmOrderDocument = gql`
    mutation confirmOrder($orderIds: [Int!]!) {
  confirmOrder(orderIds: $orderIds)
}
    `;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<ConfirmOrderMutation, ConfirmOrderMutationVariables>;

/**
 * __useConfirmOrderMutation__
 *
 * To run a mutation, you first call `useConfirmOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderMutation, { data, loading, error }] = useConfirmOrderMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useConfirmOrderMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument, options);
      }
export type ConfirmOrderMutationHookResult = ReturnType<typeof useConfirmOrderMutation>;
export type ConfirmOrderMutationResult = Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>;
export const SessionEditorDocument = gql`
    query sessionEditor($regionCode: String!) {
  allGroups(regionCode: $regionCode) {
    groupId
    groupName
    assessments {
      assessment
      item {
        ...NewItemPart
      }
    }
  }
  items {
    ...NewItemPart
  }
}
    ${NewItemPartFragmentDoc}`;

/**
 * __useSessionEditorQuery__
 *
 * To run a query within a React component, call `useSessionEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionEditorQuery({
 *   variables: {
 *      regionCode: // value for 'regionCode'
 *   },
 * });
 */
export function useSessionEditorQuery(baseOptions: Apollo.QueryHookOptions<SessionEditorQuery, SessionEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionEditorQuery, SessionEditorQueryVariables>(SessionEditorDocument, options);
      }
export function useSessionEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionEditorQuery, SessionEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionEditorQuery, SessionEditorQueryVariables>(SessionEditorDocument, options);
        }
export type SessionEditorQueryHookResult = ReturnType<typeof useSessionEditorQuery>;
export type SessionEditorLazyQueryHookResult = ReturnType<typeof useSessionEditorLazyQuery>;
export type SessionEditorQueryResult = Apollo.QueryResult<SessionEditorQuery, SessionEditorQueryVariables>;
export const SessionsDocument = gql`
    query sessions($criteria: GetSessionsCriteria!) {
  sessions(criteria: $criteria) {
    sessions {
      sessionId
      groupId
      title
      status
      joinMethod
      ecode
      client
      trainer
      date
      writer
      createdAt
      group {
        groupName
      }
      paperCount
      itemIds
    }
    totalCount
  }
}
    `;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useSessionsQuery(baseOptions: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, options);
      }
export function useSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, options);
        }
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<typeof useSessionsLazyQuery>;
export type SessionsQueryResult = Apollo.QueryResult<SessionsQuery, SessionsQueryVariables>;
export const DeletedSessionsDocument = gql`
    query deletedSessions($criteria: GetSessionsCriteria!) {
  deletedSessions(criteria: $criteria) {
    sessions {
      sessionId
      groupId
      title
      status
      joinMethod
      ecode
      client
      trainer
      date
      writer
      createdAt
      group {
        groupName
      }
      paperCount
      itemIds
    }
    totalCount
  }
}
    `;

/**
 * __useDeletedSessionsQuery__
 *
 * To run a query within a React component, call `useDeletedSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedSessionsQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useDeletedSessionsQuery(baseOptions: Apollo.QueryHookOptions<DeletedSessionsQuery, DeletedSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedSessionsQuery, DeletedSessionsQueryVariables>(DeletedSessionsDocument, options);
      }
export function useDeletedSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedSessionsQuery, DeletedSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedSessionsQuery, DeletedSessionsQueryVariables>(DeletedSessionsDocument, options);
        }
export type DeletedSessionsQueryHookResult = ReturnType<typeof useDeletedSessionsQuery>;
export type DeletedSessionsLazyQueryHookResult = ReturnType<typeof useDeletedSessionsLazyQuery>;
export type DeletedSessionsQueryResult = Apollo.QueryResult<DeletedSessionsQuery, DeletedSessionsQueryVariables>;
export const SendSmsByEcodeDocument = gql`
    mutation sendSmsByEcode($ecodeIds: [Int!]!) {
  sendSmsByEcode(ecodeIds: $ecodeIds)
}
    `;
export type SendSmsByEcodeMutationFn = Apollo.MutationFunction<SendSmsByEcodeMutation, SendSmsByEcodeMutationVariables>;

/**
 * __useSendSmsByEcodeMutation__
 *
 * To run a mutation, you first call `useSendSmsByEcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsByEcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsByEcodeMutation, { data, loading, error }] = useSendSmsByEcodeMutation({
 *   variables: {
 *      ecodeIds: // value for 'ecodeIds'
 *   },
 * });
 */
export function useSendSmsByEcodeMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsByEcodeMutation, SendSmsByEcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsByEcodeMutation, SendSmsByEcodeMutationVariables>(SendSmsByEcodeDocument, options);
      }
export type SendSmsByEcodeMutationHookResult = ReturnType<typeof useSendSmsByEcodeMutation>;
export type SendSmsByEcodeMutationResult = Apollo.MutationResult<SendSmsByEcodeMutation>;
export type SendSmsByEcodeMutationOptions = Apollo.BaseMutationOptions<SendSmsByEcodeMutation, SendSmsByEcodeMutationVariables>;
export const SendSmsBySessionDocument = gql`
    mutation sendSmsBySession($sessionId: Int!) {
  sendSmsBySession(sessionId: $sessionId)
}
    `;
export type SendSmsBySessionMutationFn = Apollo.MutationFunction<SendSmsBySessionMutation, SendSmsBySessionMutationVariables>;

/**
 * __useSendSmsBySessionMutation__
 *
 * To run a mutation, you first call `useSendSmsBySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsBySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsBySessionMutation, { data, loading, error }] = useSendSmsBySessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSendSmsBySessionMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsBySessionMutation, SendSmsBySessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsBySessionMutation, SendSmsBySessionMutationVariables>(SendSmsBySessionDocument, options);
      }
export type SendSmsBySessionMutationHookResult = ReturnType<typeof useSendSmsBySessionMutation>;
export type SendSmsBySessionMutationResult = Apollo.MutationResult<SendSmsBySessionMutation>;
export type SendSmsBySessionMutationOptions = Apollo.BaseMutationOptions<SendSmsBySessionMutation, SendSmsBySessionMutationVariables>;