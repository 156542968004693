import React from "react"
import { Button, Box, Text } from "grommet"

export type CircleButtonStatus = "NONE" | "SELECTED" | "DIMMED"

interface CircleButtonProps {
  label: string
  status: CircleButtonStatus
  onClick: () => void
}

const BG_COLOR = {
  NONE: "#AAAAAA",
  SELECTED: "#FD343E",
  DIMMED: "#E3E3E3",
}

export const CircleButton: React.FC<CircleButtonProps> = ({ label, status, onClick }) => {
  const r = "45px"
  return (
    <Button plain focusIndicator={false} onClick={onClick}>
      <Box round="50%" background={BG_COLOR[status]} width={r} height={r} justify="center" align="center">
        <Text size="20px" color="#fff" weight="bold">
          {label}
        </Text>
      </Box>
    </Button>
  )
}
