import { Box, Image } from "grommet"
import Markdown from "markdown-to-jsx"
import React from "react"

const title = ({ props, children }) => (
  <div style={{ fontSize: "22px", fontWeight: "bold", textAlign: "center" }} {...props}>
    {children}
  </div>
)

export interface GuideFrameProps {
  hide?: boolean
  texts?: string
  image?: string
}

export const GuideFrame: React.FC<GuideFrameProps> = ({ hide = false, texts, image }) => {
  if (hide) {
    return null
  }

  return (
    <Box fill background="brand" flex>
      <Box
        align="center"
        pad={{ horizontal: "24px" }}
        fill="horizontal"
        animation={{ duration: 500, type: "slideLeft", size: "large" }}
      >
        <Box>
          <Markdown options={{ overrides: { title } }} style={{ textAlign: "center" }}>
            {texts}
          </Markdown>
          {/* {texts[1] && (
            <Text size="18px" margin={{ top: "14px" }} color="#B8DFFF">
              {texts[1]}
            </Text>
          )} */}
        </Box>
      </Box>
      <Box
        pad={{ left: "30px", right: "30px" }}
        width="medium"
        align="center"
        alignSelf="center"
        margin={{ top: "auto" }}
        // animation={{ duration: 500, type: "fadeIn", size: "large" }}
      >
        <Image fit="contain" fill="horizontal" src={image} />
      </Box>
    </Box>
  )
}
