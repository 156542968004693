import React from "react"
import { Box } from "grommet"
import { ItemPartsFragment, ItemPartsFragmentDoc } from "../../../generated-types"
import { useDinoContext } from "../components/DinoContext"
import { useApolloClient } from "@apollo/client"
import { useParams } from "react-router"
import { QuestionLabel } from "../components/QuestionLabel"
import { PillAnswerButton } from "../components/PillAnswerButton"
import { NavigationControl } from "../components/NavigationControl"
import { useTranslation } from "react-i18next"

export const OnePickWithQuestion = () => {
  const { pgmId, contentId, itemId } = useParams<any>()
  const { t } = useTranslation()
  const client = useApolloClient()
  const dino = useDinoContext()
  const questionData = client.readFragment<ItemPartsFragment>({
    fragment: ItemPartsFragmentDoc,
    id: `DinoContentItem:${pgmId}:${contentId}:${itemId}`,
  })
  return (
    <>
      <QuestionLabel waiting={dino?.waiting} question={t(questionData?.texts[0])} />
      <Box fill="horizontal" gap="small" margin={{ top: "20px" }}>
        {questionData?.values?.map((v, i) => (
          <PillAnswerButton
            key={i}
            value={v.key}
            text={t(v.text)}
            onClick={() => {
              dino.putResponse({ [v.key]: 1 })
              dino.goNext(true)
            }}
          />
        ))}
      </Box>
      <NavigationControl />
    </>
  )
}
