import React from "react"
import { Box, Text, Button } from "grommet"
import { MainLayout } from "../components/layout/MainLayout"
import { StatusCritical } from "grommet-icons"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

export default () => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <MainLayout>
      <Box gap="medium" justify="center">
        <Box alignSelf="center">
          <StatusCritical size="xlarge" color="status-error" />
        </Box>
        <Text alignSelf="center" weight="bold">
          Cannot find page (404)
        </Text>
        <Button label={t("button.back")} onClick={() => history.goBack()} />
      </Box>
    </MainLayout>
  )
}
