import React, { useContext } from "react"
import { useHistory } from "react-router"
import { useEffect } from "react"
import { LoginContext } from "../components/context/LoginContext"

const LOGIN_MAIN_PAGE = "/my"

export default () => {
  const history = useHistory()
  const { isLogined } = useContext(LoginContext)

  useEffect(() => {
    if (isLogined()) {
      history.replace(LOGIN_MAIN_PAGE)
    } else {
      history.replace("/login")
    }
  }, [])

  return null
}
