import { Box, Text } from "grommet"
import React, { useState } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useDinoPaperQuery } from "../../../generated-types"
import { ResultLayout } from "./ResultLayout"
import ResultL from "../../../imgs/4mat_hmi_result_l.svg"
import ResultR from "../../../imgs/4mat_hmi_result_r.svg"
import ResultA from "../../../imgs/4mat_hmi_result_a.svg"
import { FMatDesc } from "./FMatDesc"
import { isSupportItem } from "../report/ReportUtil"

export default () => {
  const { paperId } = useParams<any>()
  const { data } = useDinoPaperQuery({ variables: { paperId } })
  const { t } = useTranslation()
  const [showSendReport, setShowSendReport] = useState(false)
  const ResultImg = useMemo(() => {
    if (!data) return
    setShowSendReport(isSupportItem(data.dinoPaper.item.id))

    const [v] = data.dinoPaper.results
    if (v > 0) {
      return ResultR
    } else if (v < 0) {
      return ResultL
    } else {
      return ResultA
    }
  }, [data])

  return (
    <ResultLayout
      disableDownload
      showSendReport
      extraContent={<FMatDesc pgmId={data?.dinoPaper?.item?.id} value={data?.dinoPaper?.results} />}
    >
      {({ width, refStage }) => (
        <>
          <Box
            round="18px"
            width={width + "px"}
            height={width + "px"}
            justify="center"
            align="center"
            background="#ededed"
          >
            {ResultImg && <img src={ResultImg} width={width - 30 + "px"} height={width - 30 + "px"} />}
          </Box>
          <Box margin={{ top: "15px" }} gap="small">
            <Text size="22px" weight="bold">
              {t("hmi_score")}: {data?.dinoPaper?.results[0]}
            </Text>
          </Box>
        </>
      )}
    </ResultLayout>
  )
}
