import React from "react"
import { Box, Button } from "grommet"
import { useTranslation } from "react-i18next"

export type ProfilePageProps = {
  onDone: () => void
}
export const ProfilePage: React.FC<ProfilePageProps> = ({ onDone }) => {
  const { t } = useTranslation()
  return (
    <Box width="medium" align="center" pad="medium" gap="medium" margin={{ top: "large" }}>
      프로필 화면
      <Button label={t("button.confirm")} onClick={() => onDone()} />
    </Box>
  )
}
