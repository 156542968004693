export type ButtonPanelProps = {
  answers: string[]
  score: number[]
  onUpdateScore: (score: number[]) => void
}

export const PRESET_COLOR = {
  4: "#073596",
  3: "#274C9E",
  2: "#4F6CA7",
  1: "#697DAA",
}
