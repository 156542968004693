import React, { useContext, useMemo, useState } from "react"
import i18n from "../../i18n"

type LanguageContextType = {
  getLocales(): Promise<string[]>
  changeLanguage(lang: string): void
  lang: string
}

type GetLocalesResponse = {
  locales: string[]
}

export const LanguageContext = React.createContext<LanguageContextType | undefined>(undefined)
LanguageContext.displayName = "LanguageContext"

export const LanguageContextProvider: React.FC = (props) => {
  const [lang, setLang] = useState(i18n.language)
  const ctx = useMemo(
    () => ({
      getLocales: async () => {
        const response = await fetch(`${process.env.REACT_APP_PNM_I18N_URL}/api/locales`)
        const { locales } = (await response.json()) as GetLocalesResponse
        return locales
      },
      changeLanguage(lang: string) {
        i18n.changeLanguage(lang)
      },
      lang,
    }),
    [lang]
  )

  i18n.on("languageChanged", setLang)

  return <LanguageContext.Provider value={ctx}>{props.children}</LanguageContext.Provider>
}

export const useLanguageContext = () => useContext(LanguageContext)
