import React from "react"
import { Checkmark } from "grommet-icons"
import { useDinoContext } from "./DinoContext"
import { Box, Button, Stack, Text } from "grommet"

const PRESET = ["#EDEDED", "#E64A4B"]

export interface PillAnswerButtonProps {
  text: string
  value: string
  onClick: () => void
}
export const PillAnswerButton: React.FC<PillAnswerButtonProps> = ({ text, value, onClick }) => {
  const { waiting, response } = useDinoContext()
  const status = response && response[value] ? 1 : 0

  return (
    <Button plain onClick={onClick} disabled={waiting && !status} focusIndicator={false}>
      <Box
        fill="horizontal"
        height="70px"
        pad="middle"
        border={{ size: "2px", color: PRESET[status] }}
        round="35px"
        justify="center"
      >
        <Stack anchor="right" fill>
          <Box fill justify="center" pad={{ left: "25px", right: "50px" }} style={{ wordBreak: "keep-all" }}>
            <Text style={{ fontWeight: "bold" }} alignSelf="start">
              {text}
            </Text>
          </Box>
          <Box
            background={PRESET[status]}
            pad="5px"
            round="full"
            width="35px"
            align="center"
            margin={{ right: "15px" }}
          >
            <Checkmark color={status ? "white" : PRESET[0]} />
          </Box>
        </Stack>
      </Box>
    </Button>
  )
}
