import { Box, Heading, RadioButtonGroup, Text } from "grommet"
import { Radial, RadialSelected } from "grommet-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import Copyright from "../../components/layout/Copyright"
import { DiagControlBar } from "../../components/DiagControlBar"
import DiagLayout from "../../components/DiagLayout"
import { DiagProgressBar } from "../../components/DiagProgressBar"

type FMatAnswerHmiPageProps = {
  index: number
  total: number
  question?: string
  answers: string[]
  score: number[] | null
  hasPrevious?: boolean
  hasNext?: boolean
  waiting?: boolean
  onUpdateScore: (seq: number[], done: boolean) => void
  onPrevious: () => void
  onNext: () => void
}

const ButtonStylePreset = {
  "0": { size: "1.5em", label: "label.hmi.pt2" },
  "1": { size: "1.3em", label: "label.hmi.pt1" },
  "2": { size: "1.0em", label: "label.hmi.pt0" },
  "3": { size: "1.3em", label: "label.hmi.pt1" },
  "4": { size: "1.5em", label: "label.hmi.pt2" },
}

export const FMatAnswerHmiPage: React.FC<FMatAnswerHmiPageProps> = ({
  index,
  total,
  answers,
  score = [null, null, null, null, null],
  onUpdateScore,
  waiting,
  hasPrevious = false,
  hasNext,
  onPrevious,
  onNext,
}) => {
  const { t } = useTranslation()
  const ctrlProps = {
    hasPrevious,
    hasNext,
    waiting,
    onPrevious,
    onNext,
  }
  const [keyword1, keyword2] = answers

  return (
    <DiagLayout>
      <Box width="medium" align="center">
        <Heading level={2} margin={{ bottom: "small" }}>
          4MAT
        </Heading>
      </Box>

      <DiagProgressBar index={index} total={total} />

      <DiagControlBar {...ctrlProps} />

      <Box fill="horizontal" gap="small" margin={{ top: "0.5em", bottom: "1em" }}>
        <Box direction="row" justify="between" height="50px" margin={{ bottom: "0.5em" }}>
          <Box justify="end">
            <Text size="medium" weight="bold">
              {keyword1}
            </Text>
          </Box>
          <Box justify="end">
            <Text size="medium" weight="bold" textAlign="end">
              {keyword2}
            </Text>
          </Box>
        </Box>

        <RadioButtonGroup
          name="radio"
          direction="row"
          justify="between"
          options={["0", "1", "2", "3", "4"]}
          disabled={waiting}
          value={"" + score.indexOf(1)}
          onChange={(event) => {
            const answer = [null, null, null, null, null]
            answer[event.target.value] = 1
            onUpdateScore(answer, true)
          }}
        >
          {(option, { checked }) => {
            const Icon = checked ? RadialSelected : Radial
            const preset = ButtonStylePreset[option]

            return (
              <Box gap="5px" align="center" basis="auto">
                <Box height="1.5em" justify="center">
                  <Icon size={preset.size} />
                </Box>
                <Box height="22px">
                  <Text size="xsmall">{t(preset.label)}</Text>
                </Box>
              </Box>
            )
          }}
        </RadioButtonGroup>
      </Box>
      <Copyright />
    </DiagLayout>
  )
}

export default FMatAnswerHmiPage
