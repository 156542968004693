import React from "react"
import { useApolloClient } from "@apollo/client"
import { useContext } from "react"

type LoginContextType = {
  login: (name: string, token: string, exp: number) => void
  logout: () => void
  isLogined: () => boolean
}

export const LoginContext = React.createContext<LoginContextType | undefined>(undefined)
LoginContext.displayName = "LoginContext"

export const LoginContextProvider: React.FC = (props) => {
  const client = useApolloClient()
  const ctx = {
    login: (name: string, token: string, exp: number) => {
      sessionStorage.setItem("token", token)
      sessionStorage.setItem("exp", "" + exp)
      sessionStorage.setItem("username", name)
    },
    logout: () => {
      sessionStorage.removeItem("token")
      sessionStorage.removeItem("exp")
      sessionStorage.removeItem("username")
      // graphql cache invalidate
      client.cache.reset()
    },
    isLogined: () => sessionStorage.getItem("token") !== null,
  }
  return <LoginContext.Provider value={ctx}>{props.children}</LoginContext.Provider>
}

export const useLoginContext = () => useContext(LoginContext)
