import React from "react"
import { grommet, Grommet, GrommetProps, ThemeType } from "grommet"
import { deepMerge } from "grommet/utils"

export const grommetTheme: ThemeType = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#00739D",
    },
    focus: {
      border: {
        color: "#00739D",
      },
    },
  },
  table: {
    header: {
      align: "center",
    },
    body: {
      align: "center",
    },
  },
  formField: {
    round: "xsmall",
    label: { margin: { vertical: "xxsmall" } },
    border: {
      position: "inner",
      side: "all",
      color: "#000",
    },
  },
  button: {
    border: {
      radius: "36px",
    },
    padding: {
      vertical: "10px",
      horizontal: "30px",
    },
  },
} as ThemeType)

export const GrommetRoot: React.FC<GrommetProps> = (props) => {
  // full=true 여야 justify를 활용할 수 있음. 그러나 full을 넣으면 드래그가 되는 페이지가 나오지 않음... 이 아니로 overflow='scroll' 설정하면 된다.
  return (
    <Grommet theme={grommetTheme} {...props}>
      {props.children}
    </Grommet>
  )
}
