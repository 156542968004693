import React from "react"
import { Button, Box, Text } from "grommet"
import _ from "lodash"
import { ButtonPanelProps, PRESET_COLOR } from "./ButtonPanel"

export type AdvancedAnswerButtonProps = {
  answer: string
  pt: number | null
  onChange: (pt: number) => void
  score: number[]
}
export const AdvancedAnswerButton: React.FC<AdvancedAnswerButtonProps> = ({ answer, pt, score, onChange }) => {
  const alreadyChoosed = _.compact(score)

  return (
    <Box
      fill="horizontal"
      pad={{ left: "0.5em", top: "0.2em", bottom: "0.2em", right: "0.5em" }}
      border={{ side: "left", size: "4px" }}
      gap="0.3em"
    >
      <Text textAlign="start" style={{ fontWeight: "bold" }}>
        {answer}
      </Text>
      <Box fill="horizontal" direction="row" justify="between" gap="xsmall" flex>
        {_.range(0, 4).map(i => (
          <Button plain onClick={() => onChange(i + 1)} focusIndicator={false}>
            <Box
              round="10px"
              border={{
                size: "3px",
                color: pt
                  ? pt === i + 1
                    ? "brand"
                    : "light-4"
                  : alreadyChoosed.includes(i + 1)
                  ? "light-4"
                  : undefined,
              }}
              pad="small"
              width="3.5em"
              align="center"
              background={pt ? (pt === i + 1 ? PRESET_COLOR[i + 1] : "white") : "white"}
              color="white"
            >
              <Text
                style={{ fontWeight: "bold" }}
                color={
                  pt ? (pt === i + 1 ? undefined : "light-5") : alreadyChoosed.includes(i + 1) ? "light-5" : undefined
                }
              >
                {i + 1}
              </Text>
            </Box>
          </Button>
        ))}
      </Box>
    </Box>
  )
}
export const MetaButtonPanel: React.FC<ButtonPanelProps> = props => {
  const { answers, score, onUpdateScore } = props

  const onChooseAnswer = (num: number, pt: number) => {
    const newScore = [...score]

    const samePtIdx = newScore.indexOf(pt)
    if (samePtIdx >= 0) newScore[samePtIdx] = null
    newScore[num] = pt

    onUpdateScore(newScore)
  }
  return (
    <Box fill="horizontal" gap="small">
      {answers.map((a, i) => (
        <AdvancedAnswerButton key={i} answer={a} pt={score[i]} score={score} onChange={pt => onChooseAnswer(i, pt)} />
      ))}
    </Box>
  )
}
