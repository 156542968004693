import React, { useMemo } from "react"
import { useParams } from "react-router"
import { useDinoPaperQuery } from "../../../generated-types"
import { ResultLayout } from "./ResultLayout"
import { useTranslation } from "react-i18next"
import Markdown from "markdown-to-jsx"
import imgType1 from "../../../imgs/IDI_type1.png"
import imgType2 from "../../../imgs/IDI_type2.png"
import imgType3 from "../../../imgs/IDI_type3.png"
import imgType4 from "../../../imgs/IDI_type4.png"

const types = {
  type1: {
    key: "TYPE1",
    img: imgType1,
  },
  type2: {
    key: "TYPE2",
    img: imgType2,
  },
  type3: {
    key: "TYPE3",
    img: imgType3,
  },
  type4: {
    key: "TYPE4",
    img: imgType4,
  },
}

export default () => {
  const { paperId } = useParams<any>()
  const { t } = useTranslation()
  const { data } = useDinoPaperQuery({ variables: { paperId } })

  const type = useMemo(() => {
    if (data) {
      const { results } = data.dinoPaper
      switch (results[2]) {
        case 1:
          return types.type1
        case 2:
          return types.type2
        case 3:
          return types.type3
        case 4:
          return types.type4
      }
    }
  }, [data])

  return (
    <ResultLayout>
      {({ width, refStage }) => (
        <>
          {type !== undefined && (
            <>
              <img src={type.img} style={{ width: "90%", maxWidth: "500px" }} />
              <Markdown style={{ textAlign: "center" }}>{t(`FMAT_LBI_FS.${type.key}.TITLE`)}</Markdown>
              <Markdown style={{ width: "95%" }}>{t(`FMAT_LBI_FS.${type.key}.DESC`)}</Markdown>
            </>
          )}
        </>
      )}
    </ResultLayout>
  )
}
