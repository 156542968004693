import { Box } from "grommet"
import React from "react"
import Copyright from "./layout/Copyright"
import { GrommetRoot } from "./layout/GrommetRoot"

type LayoutProps = {
  layerContent?: React.ReactNode
}
export const DiagLayout: React.FC<LayoutProps> = (props) => {
  const { layerContent } = props
  return (
    <GrommetRoot>
      <style>{"body { background-color: #00739D; margin-bottom: 20px}"}</style>
      <Box
        fill
        align="center"
        justify="start"
        overflow="scroll"
        background={{ color: "neutral-3" }}
        pad={{ top: "large", left: "medium", right: "medium", bottom: "large" }}
      >
        <Box
          width="large"
          align="center"
          background={{ color: "white" }}
          round="large"
          pad={{ top: "medium", left: "0.9em", right: "0.9em", bottom: "large" }}
          gap="small"
        >
          {props.children}
        </Box>
        <Copyright />
      </Box>
      {layerContent}
    </GrommetRoot>
  )
}

export default DiagLayout
