import React from "react"
import { Route, Switch, useParams } from "react-router-dom"
import DinoEntryPage from "./DinoEntryPage"
import { Intro } from "./Intro"
import QuestionnairePage from "./QuestionnairePage"
import { DinoContextProvider } from "./components/DinoContext"
import Error404 from "../Error404"
import FiveLLResult from "./result/FiveLLResult"
import FMatQuadResult from "./result/FMatQuadResult"
import { useLanguageContext } from "../../components/context/LanguageContext"
import FMatHmiResult from "./result/FMatHmiResult"
import FMatTsiResult from "./result/FMatTsiResult"
import { FiveLLQuadResult } from "./result/FiveLLQuadResult"
import FMatLbi360Result from "./result/FMatLbi360Result"
import { ResultReportPage } from "./report/ResultReportPage"
import FMatLbiFastScanResult from "./result/FMatLbiFastScanResult"

export default () => {
  const { paperId } = useParams<any>()

  let { lang } = useLanguageContext()
  if (!["en", "ko"].includes(lang)) lang = "en"

  return (
    <DinoContextProvider paperId={paperId} lang={lang}>
      <Switch>
        <Route path="/papers/:paperId" exact component={DinoEntryPage} />
        <Route path="/papers/:paperId/:pgmId/:contentId/intro" exact component={Intro} />
        <Route path="/papers/:paperId/:pgmId/:contentId/:itemId" exact component={QuestionnairePage} />
        <Route path="/papers/:paperId/FMAT_LTM/result" exact component={FMatQuadResult} />
        <Route path="/papers/:paperId/FMAT_TSI/result" exact component={FMatTsiResult} />
        <Route path="/papers/:paperId/FMAT_TRSI/result" exact component={FMatTsiResult} />
        <Route path="/papers/:paperId/FMAT_SLP/result" exact component={FMatQuadResult} />
        <Route path="/papers/:paperId/FMAT_LBI/result" exact component={FMatQuadResult} />
        <Route path="/papers/:paperId/FMAT_LBI360/result" exact component={FMatLbi360Result} />
        <Route path="/papers/:paperId/FMAT_LBI_FS/result" exact component={FMatLbiFastScanResult} />
        <Route path="/papers/:paperId/FMAT_HMI/result" exact component={FMatHmiResult} />
        <Route path="/papers/:paperId/5LL_MC/result" exact component={FiveLLResult} />
        <Route path="/papers/:paperId/5LL_PSCL/result" exact component={FiveLLResult} />
        <Route path="/papers/:paperId/5LL_UC/result" exact component={FiveLLResult} />
        <Route path="/papers/:paperId/5LL_HS/result" exact component={FiveLLResult} />
        <Route path="/papers/:paperId/5LL_LS/result" exact component={FiveLLResult} />
        <Route path="/papers/:paperId/5LL_FA/result" exact component={FiveLLResult} />
        <Route path="/papers/:paperId/5LL_CO/result" exact component={FiveLLQuadResult} />
        <Route path="/papers/:paperId/:pgmId/report" exact component={ResultReportPage} />

        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </DinoContextProvider>
  )
}
