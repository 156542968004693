import { Box, Button, Heading, Text } from "grommet"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import Copyright from "../../components/layout/Copyright"
import DiagLayout from "../../components/DiagLayout"

type ResultHmiPageProps = {
  title: string
  results: string[]
  onClose?: () => void
}

export default (({ title, results, onClose }) => {
  const map = useMemo(
    () =>
      results.reduce((a, e) => {
        const [k, v] = e.split("=")
        a[k] = v
        return a
      }, {}) as any,
    [results]
  )
  console.log(map)

  const { t } = useTranslation()
  const history = useHistory()
  if (!onClose) {
    onClose = () => history.push("/my")
  }
  return (
    <DiagLayout>
      <Heading level={2} style={{ marginBottom: "0.2em" }}>
        RESULT
      </Heading>
      <Text style={{ margin: 0 }}>{title}</Text>

      <Box gap="xsmall" margin={{ top: "2em", bottom: "1.2em" }}>
        <Box
          pad={{ horizontal: "50px", vertical: "20px" }}
          round="large"
          border={{ side: "all", size: "3px" }}
          gap="0.7em"
        >
          <Box align="center">Total</Box>
          <Box align="center">
            <Text size="4em" weight="bold">
              {map.B}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box fill="horizontal">
        <Button label={t("button.confirm")} primary onClick={() => onClose()} margin={{ top: "medium" }} />
      </Box>
      <Copyright />
    </DiagLayout>
  )
}) as React.FC<ResultHmiPageProps>
