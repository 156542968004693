import { Box, Button, Heading, Text } from "grommet"
import React, { MutableRefObject, ReactElement, ReactNode, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Konva from "konva"
import { useHistory, useParams } from "react-router"
import DiagLayout from "../../../components/DiagLayout"
import { useDinoPaperQuery } from "../../../generated-types"
import { FormDown, FormUp } from "grommet-icons"

const GRAPH_WIDTH = 330

const ExtraContentWrapper = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Box fill="horizontal" align="center">
      <Box>
        <Button
          plain
          onClick={() => {
            setCollapsed(!collapsed)
          }}
        >
          {collapsed ? <FormUp size="48px" color="#d6544d" /> : <FormDown size="48px" color="#d6544d" />}
        </Button>
      </Box>
      {children && collapsed && (
        <Box width="100%" align="center" animation="fadeIn">
          {children}
        </Box>
      )}
    </Box>
  )
}

export interface ResultLayoutProps {
  children: (props: { width: number; refStage: MutableRefObject<Konva.Stage> }) => ReactNode
  disableDownload?: boolean
  showSendReport?: boolean
  extraContent?: ReactElement
}

export const ResultLayout: React.FC<ResultLayoutProps> = ({
  disableDownload = true,
  showSendReport = false,
  extraContent,
  children,
}) => {
  const { paperId } = useParams<any>()
  const { data } = useDinoPaperQuery({ variables: { paperId } })
  const { t } = useTranslation()
  const history = useHistory()
  const refStage = useRef<Konva.Stage>()

  return (
    <DiagLayout>
      <Heading level={2} style={{ marginBottom: "0.4em" }}>
        {t("result")}
      </Heading>
      <Text size="21px" color="#777777" style={{ margin: 0 }}>
        {t(data?.dinoPaper?.item?.subtitle) || ""}
      </Text>
      <Box align="center" margin={{ top: "10px", bottom: "10px" }}>
        {children({ width: GRAPH_WIDTH, refStage })}
      </Box>
      <Box
        fill="horizontal"
        margin={{ top: "medium", bottom: "medium" }}
        gap="small"
        direction="row"
        align="center"
        justify="center"
      >
        {!disableDownload && (
          <Button
            label={t("button.download")}
            onClick={() => {
              const dataUrl = refStage.current.getStage().toDataURL({ pixelRatio: 2 })
              let link = document.createElement("a")
              link.download = "Result.png"
              link.href = dataUrl
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }}
            color="#d6544d"
          />
        )}

        {showSendReport && (
          <Button
            label={t("button.send-report")}
            onClick={() => history.push(`/papers/${paperId}/${data?.dinoPaper?.item?.id}/report`)}
            color="#d6544d"
          />
        )}

        <Button
          // fill="horizontal"
          label={t("button.confirm")}
          primary
          onClick={() => history.push("/my")}
          color="#d6544d"
          alignSelf="center"
        />
      </Box>
      {extraContent && <ExtraContentWrapper>{extraContent}</ExtraContentWrapper>}
    </DiagLayout>
  )
}
