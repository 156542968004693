import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { MyPageController } from "./MyPage"
import { DiagRedirector, FMatControlPage } from "./diag"
import { PaperResultPage } from "./paper"
import Error404 from "./Error404"
import InputCodePage from "./login/InputCode"
import InputProfilePage from "./login/InputProfile"
import RootRedirector from "./RootRedirector"
import CodeAuthRedirector from "./login/CodeAuthRedirector"
import DinoRouter from "./dino/DinoRouter"
import MyPage from "./dino/MyPage"

const routes = [
  { exact: true, path: "/", component: RootRedirector },
  { exact: true, path: "/login", component: InputCodePage },
  { exact: true, path: "/login/:code", component: CodeAuthRedirector },
  { exact: true, path: "/login/:code/profile", component: InputProfilePage },
  { exact: true, path: "/my_old", component: MyPageController },
  { exact: true, path: "/my", component: MyPage },
  {
    exact: true,
    path: "/paper/:paperId",
    component: DiagRedirector,
  },
  {
    strict: true,
    path: "/paper/:paperId/diag",
    component: FMatControlPage,
  },
  { path: "/paper/:paperId/result", component: PaperResultPage },
  {
    path: "/papers/:paperId",
    component: DinoRouter,
  },
  { path: "/public/:rcode/papers/:paperId/result", component: PaperResultPage },
] as any[]

export default () => (
  <Router>
    <Switch>
      {routes.map((route, i) => (
        <Route key={i} {...route} />
      ))}
      <Route path="*">
        <Error404 />
      </Route>
    </Switch>
  </Router>
)
