import { Box, Heading } from "grommet"
import React, { createElement, useEffect, useMemo } from "react"
import { useHistory, useParams } from "react-router"
import { ToastsStore } from "react-toasts"
import { DinoContentPartsFragment, DinoContentPartsFragmentDoc, useDinoPaperQuery } from "../../generated-types"
import { DiagProgressBar } from "./components/DiagProgressBar"
import { DinoLayout } from "./components/Layout"
import { OnePick } from "./picker/OnePick"
import { OnePickWithQuestion } from "./picker/OnePickWithQuestion"
import { SequencePick } from "./picker/SequencePick"
import { SlidePick } from "./picker/SlidePick"
import { useTranslation } from "react-i18next"

export default () => {
  const { paperId, pgmId, contentId, itemId } = useParams<any>()
  const { t } = useTranslation()
  const history = useHistory()
  const { data: paperData, client } = useDinoPaperQuery({ variables: { paperId }, fetchPolicy: "cache-only" })
  const contentData = client.readFragment<DinoContentPartsFragment>({
    fragment: DinoContentPartsFragmentDoc,
    fragmentName: "DinoContentParts",
    id: `DinoContent:${pgmId}:${contentId}`,
  })

  // console.log(contentData)
  // const questionData = client.readFragment<ItemPartsFragment>({
  //   fragment: ItemPartsFragmentDoc,
  //   id: `DinoContentItem:${pgmId}:${contentId}:${itemId}`,
  // })
  // const introData = client.readFragment<IntroPartsFragment>({
  //   fragment: IntroPartsFragmentDoc,
  //   id: `DinoContentIntro:${pgmId}:${contentId}:INTRO`,
  // })

  useEffect(() => {
    if (!paperData || !contentData) {
      ToastsStore.warning("Illegal Access")
      history.push(`/papers/${paperId}`)
    }
  }, [paperData, contentData])

  const Picker = useMemo(() => {
    if (!contentData) return null
    switch (contentData.itemView) {
      case "OnePick":
        return OnePick
      case "SequencePick":
        return SequencePick
      case "OnePickWithQuestion":
        return OnePickWithQuestion
      case "SlidePick":
        return SlidePick
    }
    return null
  }, [contentData])

  return (
    <DinoLayout>
      <Box width="medium" align="center">
        <Heading level={2} margin={{ bottom: "small" }}>
          {t(contentData?.intro?.texts[0])}
          {contentData?.intro?.texts[3] ? ` - ${t(contentData?.intro?.texts[3])}` : ""}
        </Heading>
      </Box>

      <DiagProgressBar color="#7CbCE4" />

      <Box fill="horizontal" gap="small" margin={{ top: "20px" }} style={{ maxWidth: "600px" }}>
        {Picker && createElement(Picker)}
      </Box>
    </DinoLayout>
  )
}
