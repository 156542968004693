import React from "react"
import _ from "lodash"
import { Button, Box, Stack, Text } from "grommet"
import { ButtonPanelProps, PRESET_COLOR } from "./ButtonPanel"

const getNextScore = (score: Array<number | null>): number => _.chain(score).min().defaultTo(5).subtract(1).value()

export type AnswerButtonProps = {
  answer: string
  status: number
  onClick?: (e) => void
}
export const AnswerButton: React.FC<AnswerButtonProps> = ({ answer, status, onClick }) => {
  const selected = status !== null
  return (
    <Button plain onClick={(e) => onClick(e)} disabled={selected} focusIndicator={false}>
      <Box
        fill="horizontal"
        height="70px"
        pad="middle"
        border={{ size: "2px", color: "brand" }}
        round="medium"
        justify="center"
      >
        <Stack anchor="right" fill={true}>
          <Box fill justify="center" pad={{ left: "20px", right: "50px" }} style={{ wordBreak: "keep-all" }}>
            <Text style={{ fontWeight: "bold" }} alignSelf="start">
              {answer}
            </Text>
          </Box>
          <Box
            background={status ? PRESET_COLOR[status] : "light-3"}
            pad="5px"
            round="full"
            width="35px"
            align="center"
            margin={{ right: "10px" }}
          >
            <Text>{status || "-"}</Text>
          </Box>
        </Stack>
      </Box>
    </Button>
  )
}

export const FlowButtonPanel: React.FC<ButtonPanelProps> = (props) => {
  const { answers, score, onUpdateScore } = props
  const onChooseAnswer = (num: number) => {
    const nextScore = getNextScore(score)
    if (nextScore === 0) return
    if (score[num - 1] !== null) return

    const newScore = [...score]
    newScore[num - 1] = nextScore

    // if (nextScore - 1 === 1) {
    //   const last = _.indexOf(newScore, null)
    //   newScore[last] = 1
    // }

    onUpdateScore(newScore)
  }

  return (
    <Box fill="horizontal" gap="small">
      {answers.map((a, i) => (
        <AnswerButton key={i} answer={a} status={score[i]} onClick={() => onChooseAnswer(i + 1)} />
      ))}
    </Box>
  )
}
