import React from "react"
import { ApolloError } from "@apollo/client"

type UIContextType = {
  loading: boolean
  message: { content: { code?: string; message?: string }[]; style: "OK" | "WARN" | "ERROR"; ts: number } | null
  // clearMessageBar: () => void
  setLoading: (loading: boolean) => void
  popupError: (err: string | ApolloError) => void
}

export const UIContext = React.createContext<UIContextType | undefined>(undefined)
UIContext.displayName = "UIContext"

export type UIContextProviderProps = {
  handler: () => void
}
export const UIContextProvider: React.FC<UIContextProviderProps> = (props) => {
  const [message, setMessage] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const popupError = React.useCallback(
    (err) => {
      if (!err) return

      let content
      if (err instanceof ApolloError) {
        if (err.graphQLErrors.length === 0) content = [{ message: err.message }]
        else
          content = err.graphQLErrors.map(({ message, extensions }) => {
            return {
              message,
              code: extensions.code,
            }
          })
      }

      setMessage({ content, style: "ERROR", ts: +new Date() })
    },
    [setMessage]
  )

  const ctx = {
    loading,
    message,
    setLoading,
    popupError,
  }

  React.useEffect(() => {
    if (!message) return
    // console.log("hajime")
    const time = setInterval(() => {
      // console.log(message)

      // console.log(+new Date() - message.ts)
      if (+new Date() - message.ts > 3000) setMessage(null)
    }, 1000)
    return () => {
      clearInterval(time)
    }
  }, [message])

  return <UIContext.Provider value={ctx}>{props.children}</UIContext.Provider>
}

export const useUIContext = () => React.useContext<UIContextType>(UIContext)
