import React from "react"
import { Box, Text } from "grommet"
import { useTranslation } from "react-i18next"

export const PageHeader = ({ texts }) => {
  const { t } = useTranslation()
  return (
    <Box fill="horizontal" margin={{ bottom: "25px" }}>
      <Text size="1.5em" weight="bold" color="#88b8e1">
        {t(texts[0])}
      </Text>
      <Text size="1.8em" weight="bold" color="#000000" margin={{ top: "5px", bottom: "20px" }}>
        {t(texts[1])}
      </Text>
      <Text size="0.9em" weight="bold" color="#999999">
        {t(texts[2])}
      </Text>
    </Box>
  )
}
