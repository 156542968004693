import { useApolloClient } from "@apollo/client"
import { Box, Button, Text } from "grommet"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ItemPartsFragment, ItemPartsFragmentDoc } from "../../../generated-types"
import { useDinoContext } from "../components/DinoContext"
import { NavigationControl } from "../components/NavigationControl"
import { QuestionLabel } from "../components/QuestionLabel"

const ButtonStylePreset = [
  { size: "27px", label: "label.hmi.pt2" },
  { size: "27px", label: "label.hmi.pt1" },
  { size: "27px", label: "label.hmi.pt0" },
  { size: "27px", label: "label.hmi.pt1" },
  { size: "27px", label: "label.hmi.pt2" },
]

const COLOR_PRESET = ["#e3e3e3", "#FD343E"]

const SlideButton = ({ label, size, selected, disabled, onClick }) => {
  // const selected = 0
  const { t } = useTranslation()
  const statusProps = [
    { background: "#fff", border: { color: COLOR_PRESET[0], size: "3px" } },
    { background: COLOR_PRESET[1], border: { color: COLOR_PRESET[1], size: "3px" } },
  ]
  return (
    <Box height="30px" justify="center" align="center">
      <Button plain focusIndicator={false} disabled={disabled} onClick={onClick} style={{ display: "relative" }}>
        <Box round="50%" width={size} height={size} {...statusProps[selected]}>
          <div style={{ display: "absolute", width: "200px", height: "15px", marginLeft: "32px" }}>
            <Text weight="bold" size="15px" color="#000">
              {t(label)}
            </Text>
          </div>
        </Box>
      </Button>
    </Box>
  )
}

export const SlidePick = () => {
  const { pgmId, contentId, itemId } = useParams<any>()
  const client = useApolloClient()
  const dino = useDinoContext()
  const { t } = useTranslation()
  const questionData = client.readFragment<ItemPartsFragment>({
    fragment: ItemPartsFragmentDoc,
    id: `DinoContentItem:${pgmId}:${contentId}:${itemId}`,
  })

  return (
    <>
      <QuestionLabel waiting={dino?.waiting} question={t(questionData?.texts[0])} height="60px" />
      <Box style={{ background: "linear-gradient(#eee, #eee) no-repeat center/1px 100%" }} align="center">
        <Box pad="15px" gap="15px">
          {questionData.values.map((vkey, i) => (
            <SlideButton
              key={i}
              selected={dino.response[vkey.key] ? 1 : 0}
              disabled={dino.waiting}
              onClick={() => dino.putResponse({ [vkey.key]: 1 })}
              {...ButtonStylePreset[i]}
            />
          ))}
        </Box>
      </Box>
      <QuestionLabel waiting={dino?.waiting} question={t(questionData?.texts[1])} height="60px" />
      {/* <Box fill="horizontal" gap="small" margin={{ top: "0.5em", bottom: "1em" }}>
        <Box direction="row" justify="between" height="50px" margin={{ bottom: "0.5em" }}>
          <Box justify="end">
            <Text size="medium" weight="bold">
              {questionData?.texts[0]}
            </Text>
          </Box>
          <Box justify="end">
            <Text size="medium" weight="bold" textAlign="end">
              {questionData?.texts[1]}
            </Text>
          </Box>
        </Box>

        <RadioButtonGroup
          name="radio"
          direction="row"
          justify="between"
          options={["0", "1", "2", "3", "4"]}
          disabled={dino.waiting}
          value={questionData.values.findIndex((v) => v.key === Object.keys(dino.response)[0]) + ""}
          onChange={(event) => {
            dino.putResponse({ [questionData.values[event.target.value].key]: 1 })
          }}
        >
          {(option, { checked }) => {
            const Icon = checked ? RadialSelected : Radial
            const preset = ButtonStylePreset[option]

            return (
              <Box gap="5px" align="center" basis="auto">
                <Box height="1.5em" justify="center">
                  <Icon size={preset.size} />
                </Box>
                <Box height="22px">
                  <Text size="xsmall">{t(preset.label)}</Text>
                </Box>
              </Box>
            )
          }}
        </RadioButtonGroup>
      </Box> */}
      <NavigationControl />
    </>
  )
}
