import React from "react"
import { Box, Meter, Text } from "grommet"

export type DiagProgressBarProps = {
  index: number
  total: number
}
export const DiagProgressBar: React.FC<DiagProgressBarProps> = ({ index, total }) => {
  return (
    <Box direction="row" align="center" justify="center" gap="small">
      <Meter
        values={[
          {
            color: "accent-4",
            value: index,
            label: "progress",
          },
        ]}
        round={true}
        thickness="xsmall"
        aria-label="meter"
        background="light-3"
        max={total}
      />
      <Text size="small" color="dark-6">
        {index}/{total}
      </Text>
    </Box>
  )
}
