import { Box, Button, Heading, Markdown, Text, Paragraph } from "grommet"
import React from "react"
import Copyright from "../../components/layout/Copyright"
import DiagLayout from "../../components/DiagLayout"
import { useTranslation } from "react-i18next"
import { Magic } from "grommet-icons"

type IntroPageProps = {
  title: string
  description: string
  onRequestNext: () => void
  id?: string
  onMagic?: (key: string) => void
}

export const IntroPage: React.FC<IntroPageProps> = ({ title, description, onRequestNext, onMagic, id }) => {
  const { t } = useTranslation()
  return (
    <DiagLayout>
      <Box width="large" align="center">
        <Heading level={3} margin={{ bottom: "small" }} textAlign="center">
          {title}
        </Heading>
      </Box>
      <Markdown
        style={{ wordBreak: "keep-all" }}
        components={{ p: (props) => <Paragraph {...props} fill margin={{ top: "0.5em" }} /> }}
      >
        {description}
      </Markdown>
      <Box fill="horizontal" height="50px" pad="middle" margin={{ top: "medium" }}>
        <Button primary label={t("button.start")} fill={true} onClick={() => onRequestNext()} />
      </Box>
      {/*{onMagic && <Button icon={<Magic />} onClick={() => onMagic(id)} />}*/}
      <Copyright />
    </DiagLayout>
  )
}

export default IntroPage
